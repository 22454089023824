.accordion-body{
    padding: 0 !important;
}

html[lang="ar"] .accordion-button::after{
    margin-right:auto !important ;
    margin-left:0 !important ;

}
html[lang="en"] .accordion-button::after{
    margin-right: 0 !important;
    margin-left:auto !important ;
}
.lessons__study__area .weeks {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  
}
.lessons__study__area .weeks li{
    border-bottom: 1px solid rgba(2, 3, 52, .078);
    color: #4b4c57;
    padding: 12px 0;
    overflow: hidden;
    width: 100%;
    padding: 1rem 0.8rem;
    
}
.lessons__study__area .weeks li:hover{
    
    background-color: #4b4c57;
    color: #dedede !important;
}
.lessons__study__area .weeks li svg{
    margin: 0.5rem;
    
}
html[lang="ar"] .lessons__study__area .weeks li em{
    float: left;
}
html[lang="en"] .lessons__study__area .weeks li em{
    float: right;
}
.lessons__study__area .weeks li em.questions{
    background-color: rgba(248, 69, 45, .102);
    color: #f8452d;
    padding: 6px 10px;
    line-height: 1;
    border-radius: 6px;
    font-weight: 500;
}