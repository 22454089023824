@import url('https://fonts.googleapis.com/css2?family=Cairo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ruwudu&display=swap');

:root {
    --custom-navLinks-color: #fff;
    --custom-white-color: #fff;
    --cutom-prime-color: #453773;
    --cutom-text-color: #eee;
    --cutom-bg-color: #fff;
    --cutom-links-color: #111;

    --custom-font-color :#111;

}

body[them-data='dark'] {
    --custom-navLinks-color: #453773;
    --custom-white-color: #fff;
    --cutom-prime-color: #453773;
    --cutom-text-color: #1b1f1f;
    --cutom-bg-color: #111;
    --cutom-links-color: #fff;

    --custom-font-color :#fff;
}

body{
    padding: none !important;
}


html[lang="ar"] h1, h2, h3, h4, h5, h6 {
    font-family: 'Cairo' !important;
}

html[lang="ar"] body {
    font-family: 'Cairo' !important;
}

.cairo-font {
    font-family: 'Cairo', sans-serif !important;
}

.clr-red {
    color: var(--cutom-prime-color) !important;
}

.bgclr-red {
    background-color: var(--cutom-prime-color) !important;
}
.col-cus-font{
    color: var(--custom-font-color) !important;  
}


html[lang="ar"] .owl-carousel,
.bx-wrapper {
    direction: ltr;
}

.owl-carousel .owl-item {
    direction: rtl;
}

html[lang="en"] .owl-carousel,
.bx-wrapper {
    direction: 'rtl';
}

.owl-carousel .owl-item {
    direction: 'ltr';
}

.bg-danger {
    background: var(--cutom-prime-color) !important;
}

.btn-danger {
    color: #fff;
    background-color: var(--cutom-prime-color) !important;
    border-color: var(--cutom-prime-color) !important;
}

/*===============================================
            Headers
==================================================*/
header {
    position: fixed !important;
    width: 100%;
    transition: all 0.2s ease-in-out;
}

/* .custom-home-header{
    background-color:#0000006c
} */


.react-header .navbar-brand .logo img {
    width: 8rem;
}

/* header with background */
header.react-header-four-top-header {
    background-color: #fff !important;
}

header.react-header-four-top-header .react-menus .nav-link, header.react-header-four-top-header .searchbar-part .custom-them-mode button {
    color: #111 !important;
}

html[lang="ar"] .react-header .searchbar-part .react-login {
    border: none;
    border-right: 1px solid #DFE2E5 !important;
}

html[lang="en"] .react-header .searchbar-part .react-login {
    border: none;
    border-left: 1px solid #DFE2E5 !important;
}

/* .react-header {
    background-color: #0000006c !important;
} */

.react-header .menu-part .menu-toggle {
    flex-basis: 100%;
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.react-header .searchbar-part .react-login a {
    color: #fff;
}

.react-header .searchbar-part .react-login a:hover {
    color: var(--cutom-prime-color);
    background-color: #fff !important;
}

.react-header .react-menus .nav-link {
    color: #fff !important;
    font-family: 'Cairo', sans-serif !important;
    padding: 1rem;

}

.react-header .react-menus .nav-link:hover {
    color: var(--cutom-prime-color) !important;
}

.searchbar-part .custom-them-mode button {
    color: #fff;
    padding: 1rem;
}

.custom-avatar-menu .user-img {
    width: 40px;
}

html[lang="ar"] .custom-avatar-menu .dropdown-menu {
    text-align: right !important;
}

html[lang='en'] .custom-avatar-menu .dropdown-menu {
    text-align: left !important;
}






/*===============================================
            Home Slider 
==================================================*/
.react-slider-part {
    background: center no-repeat #000;
    /* background-image: url("../../assets/images/home4/min-bg.jpg") !important; */
    background-image: url("../Images/home4/min-bg.jpg") !important;
    background-size: cover;
    background-position: center;
    /* -webkit-animation: slidein 100s;
    animation: slidein 100s;

    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;

    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;

    -webkit-animation-direction: alternate;
    animation-direction: alternate;  */
}

@-webkit-keyframes slidein {
    from {
        background-position: top;
        background-size: 3000px;
    }

    to {
        background-position: -100px 0px;
        background-size: 2750px;
    }
}

@keyframes slidein {
    from {
        background-position: top;
        background-size: 3000px;
    }

    to {
        background-position: -100px 0px;
        background-size: 2750px;
    }

}

.react-slider-part .home-sliders .single-slide {
    min-height: 80vh;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;


}

html[lang="ar"] .react-slider-part .home-sliders .content-part {

    text-align: right !important;

}

html[lang="en"] .react-slider-part .home-sliders .content-part {

    text-align: left !important;
    padding-left: 2rem;

}

.react-slider-part .home-sliders .single-slide .slider-pretitle {
    padding: 3px 15px 8px;
    font-size: 26px;
    color: #fff;
    margin-bottom: 22px;
    font-weight: 400;
    line-height: 32px;
    background: #453773;
}

.react-slider-part .home-sliders .single-slide .slider-title {
    font-size: 50px;
    margin-bottom: 15px;
    color: #fff;
    line-height: 74px;
    font-weight: 700;
    display: block;
    margin-top: 20px;
}

.home-sliders .slider-btn .react-btn-border {
    background: 0 0;
    color: #fff
}

.home-sliders .slider-btn .react-btn-border:hover {
    background: var(--cutom-prime-color);
    color: #fff;
    border-color: var(--cutom-prime-color)
}

.home-sliders .img-rotaion img {
    width: 70% !important;
    /* animation: objectRotation 5s linear infinite; */
    margin: auto;
}



@media screen and (max-width: 991px) {
    .home-sliders .slider-content {
        padding-right: 0px !important;
    }

    html[lang="ar"] .react-slider-part .home-sliders .content-part {
        text-align: center !important;
    }

    html[lang="en"] .react-slider-part .home-sliders .content-part {
        text-align: center !important;
    }

    html[lang="ar"] .react-slider-part .home-sliders .img-rotaion {
        order: 2;

    }

    html[lang="en"] .react-slider-part .home-sliders .img-rotaion {
        order: 2;
    }

    .home-four-sliders .slider-content .hero {
        margin-top: 50px !important;
    }

    .react-slider-part .home-sliders .single-slide {
        margin-top: 4rem !important;

    }

    .home-sliders .img-rotaion img {
        display: block;
        margin: auto !important;
    }


    .react-slider-part .home-sliders .single-slide .slider-title {
        font-size: 30px;
    }









}

/*===============================================
            Slider Hero
==================================================*/

html[lang="en"] .swiper-button-prev, html[lang="en"] .swiper-button-next {
    display: flex !important;
    margin: 0 !important;
    position: absolute;
    top: 0 !important;
    padding: 1.2rem !important;
    background-color: var(--cutom-prime-color) !important;
    border: 1px solid #e1e4ed;
    color: #fff !important;
    height: 100% !important;
    z-index: 100;
    border-radius: 1rem;


}

html[lang="en"] .swiper-button-next {
    right: 0 !important;

}

html[lang="en"] .swiper-button-prev {
    left: 0 !important;

}



html[lang="ar"] .swiper-button-prev,
html[lang="ar"] .swiper-button-next {
    display: flex !important;
    margin: 0 !important;
    position: absolute;
    top: 0 !important;
    padding: 1.2rem !important;
    background-color: var(--cutom-prime-color) !important;
    border: 1px solid #e1e4ed;
    color: #fff !important;
    height: 100% !important;
    z-index: 100;
    border-radius: 1rem;



}

html[lang="ar"] .swiper-button-prev {
    right: 0 !important;


}

html[lang="ar"] .swiper-button-next {
    /* background-color: #f8faff !important;
    color: var(--cutom-prime-color) !important; */
    left: 0 !important;

}



.swiper-button-next::after, .swiper-button-prev::after {
    font-size: 16px !important;
}

@media screen and (max-width: 991px) {
    html[lang="en"] .swiper-wrapper, html[lang="ar"] .swiper-wrapper {
        padding-top: 8rem;

    }


    html[lang="en"] .swiper-button-prev, html[lang="en"] .swiper-button-next,
    html[lang="ar"] .swiper-button-prev, html[lang="ar"] .swiper-button-next {
        display: flex !important;
        height: 3rem !important;
        border-radius: 1rem;
        padding: 2rem;


    }

}

@media screen and (max-width: 767px) {

    html[lang="en"] .swiper-button-prev, html[lang="en"] .swiper-button-next,
    html[lang="ar"] .swiper-button-prev, html[lang="ar"] .swiper-button-next {
        display: none !important;
        margin: 2rem !important;
    }

}

/*===============================================
           Instructor  Slider 
==================================================*/
.instructor__area .instructor__content-1 img {
    border-color: var(--cutom-prime-color) !important;
}


/*===============================================
            Hero Slider 
==================================================*/
.event__video-btn--play {
    display: flex !important;
    justify-content: center !important;

}

.event__video-btn--play img {
    width: 50% !important;
    animation: objectRotation 5s linear infinite;


}

.event__video-btn--play-btn {
    display: inline-block;
    width: 90px;
    height: 90px;
    line-height: 86px;
    text-align: center;
    font-size: 25px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background: #fff;
    color: #2a6df5;
    border-color: #fff
}





/*===============================================
            Login And Singup 
==================================================*/


.react-login-page, .react-login-page.react-signup-page {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../Images/home4/min-bg.jpg') !important;
    background-size: cover !important;
    background-position: center;

    /* background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../assets/images/login/SIGN-IN-BG.jpg') center top no-repeat !important;
    background-size: cover !important; */

}

.react-login-page .login-right-form .login-top .logo h2 {
    color: #111 !important;
}

.react-login-page .login-right-form .login-top a, .react-login-page .login-right-form form span.back-or a {
    color: var(--cutom-prime-color) !important;
}

.react-login-page .login-right-form .login-top a:hover, .react-login-page .login-right-form form span.back-or a:hover {
    color: #333 !important;
}

.react-login-page .login-right-form form [type=submit] {
    background-color: var(--cutom-prime-color) !important;
    border: 1px solid var(--cutom-prime-color) !important;

}

.react-login-page .login-right-form form [type=submit]:hover {
    background-color: #fff !important;
    color: #333 !important;
    border: 1px solid var(--cutom-prime-color) !important;
}


/*===============================================
           About
==================================================*/
/* html[lang='en'] .react__shape__ab{
   left: 35% !important;
    right: 54.4% !important;
 } */

html[lang='ar'] .react__shape__ab {
    position: absolute;
    left: 54.4% !important;
    top: 28%;
    -webkit-animation: slide-top 5000ms linear infinite alternate;
    animation: slide-top 5000ms linear infinite alternate
}

html[lang='en'] .react__shape__ab {
    position: absolute;
    right: 54.4% !important;
    top: 28%;
    -webkit-animation: slide-top 5000ms linear infinite alternate;
    animation: slide-top 5000ms linear infinite alternate
}

/* .about__area.about__area_one ul li.last-li {
    margin: 0 !important;
    padding: 0 !important;
} */

.about__area.about__area_one .about__content {
    padding-right: 0 !important;
}

/*===============================================
            Contact Us 
==================================================*/
.contact__invite--cards .card {
    border: none !important;
}

.contact__invite--cards .card .card-img-top {
    width: 10rem;
    margin: auto;
}

.contact__invite--cards .card .card-title {
    font-size: 1.5rem;
}

.react-contact-page .address-sec li .icon {
    background-color: #e1e4ed;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 50px;
    height: 50px;
}

.react-contact-page .address-sec li .icon svg {
    color: var(--cutom-prime-color);

}

html[lang='ar'] .react-contact-page h2.contact-title {
    text-align: right;
}

.react-contact-page .blog-form form button {
    background-color: var(--cutom-prime-color) !important;
    border: 1px solid var(--cutom-prime-color) !important;
}

.react-contact-page .blog-form form button:hover {
    color: var(--cutom-prime-color) !important;
    background-color: #fff !important;
    border: 1px solid var(--cutom-prime-color) !important;
}

@media screen and (max-width: 991px) {
    .react-contact-page .address-sec li .icon {
        margin: auto;
    }

    .react-contact-page .contact-title {
        text-align: center !important;
    }

}

/*===============================================
            Courses Page
==================================================*/
html[lang="ar"] .back__course__page_grid .shorting__course .all__icons .result-count {
    padding-left: 0 !important;
    padding-right: 10px !important;
}

.back__course__page_grid .shorting__course .all__icons svg {
    color: var(--cutom-prime-color);
}

.react-course-filter .single-studies .inner-course .case-img a {
    background-color: var(--cutom-prime-color);
}

.react-course-filter .single-studies .inner-course .case-content ul li button {
    color: #fff;
    padding: 0.5rem 2rem;
    border: 1px solid var(--cutom-prime-color);
}

.react-course-filter .single-studies .inner-course .case-content ul li button:hover {
    background-color: #fff !important;
    color: var(--cutom-prime-color);
    border: 1px solid var(--cutom-prime-color);
}



/*===============================================
            Single courses Page
==================================================*/

.course-single .react-breadcrumbs {
    /* background-image: url('../../assets/images/breadcrumbs/2.png'); */
    /* background-image: url('../Images/breadcrumbs/AIT.png'); */
    background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)),url('../Images/breadcrumbs/AIT.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;


}

.course-single .custom-breadcrumbs-inner .breadcrumbs-text .breadcrumbs-title {
    display: block !important;
    width: 80% !important;
    line-height: 1.5 !important;
    margin: auto !important;
    text-align: center;
    font-size: 60px;
    text-transform: capitalize;
    color: #fff;
    font-weight: 700;
}

.course-single .user-section {
    display: flex;
    align-items: center;
    padding-bottom: 50px
}

.course-single .user-section li {
    padding-left: 40px;
    font-size: 16px;
    color: #fff;
    font-weight: 400
}


html[lang='ar'] .course-single .user-section li.user span {
    padding-right: 0;
    padding-left: 12px
}

.course-single .user-section li.user span img {
    width: 36px;
    border-radius: 50%
}


html[lang='ar'] .react-courses__single-page .course-single-tab #back-tab-content .single-week .course__title li em {
    float: left !important;
}

html[lang='ar'] .react-courses__single-page .course-single-tab #back-tab-content .single-week .course__title li em.questions {
    margin-left: 0 !important;
    margin-right: 10px !important;
}

html[lang='ar'] .react-courses__single-page .course-single-tab #back-tab-content .single-week .course__title li em svg {
    margin-left: 3px !important;
}

.react-courses__single-page .course-single-tab #back-tab-content .skillbar-style2 .skillbars .skillbar-bar {
    background: var(--cutom-prime-color);

}

html[lang='ar'] .react-sidebar .widget.get-back-course .price__course li b {
    float: left !important;
}


@media screen and (max-width: 991px) {
    .course-single .custom-breadcrumbs-inner .breadcrumbs-text .breadcrumbs-title {

        width: 100% !important;
        font-size: 30px !important;
    }

}

@media screen and (max-width: 600px) {
    .course-single .user-section {
        flex-direction: column !important;
        gap: 0.5rem !important;
    }
}




/*===============================================
----------------Lesson---------------------------
==================================================*/
#lessonSide{
   margin:  0;
   padding: 1rem 0;
    height: 100vh;
    overflow-y: auto; 
}
#lessonSide .accordion{
   width: 100%;
}
#videoSide{
    height: 100vh;
    overflow: hidden;
}
#videoSide .videos{
  width: 100%;
}

@media screen and (max-width: 991px) {
    #videoSide{
       order: 1;
    }
    #lessonSide{
        order: 2;
        height: 50vh;
        overflow-y: scroll;
     }

}









/*===============================================
            Footer
==================================================*/
.react-footer {
    background-color: #e8e8e8 !important;
    color: #333 !important;
}

.react-footer .footer-top .footer-widget .footer-address li svg {
    color: var(--cutom-prime-color) !important;
}

.react-footer .footer-top .footer-subtitle,
.react-footer .footer-top .footer-widget .footer-address li,
.react-footer .footer-top .footer-widget .footer-address li a, .react-footer .footer-top .footer-widget .footer-title, .react-footer .copyright .react-copy-left, .react-footer .copyright .follow {
    color: #333 !important;
}

.react-footer .footer-top .footer-menu li a {
    color: #000 !important;
}

.react-footer .footer-top .footer3__form-1 {
    background-color: var(--cutom-prime-color) !important;
}

.react-footer .copyright .react-copy-right ul li a {
    color: var(--cutom-prime-color) !important;
}

.react-footer .copyright .react-copy-right ul li a:hover {
    color: #333 !important;
}

/************************************************
        Dark Team Edition
***************************************************/
.popular__course__area, .instructor__area {
    background-color: var(--cutom-bg-color) !important;
}

.course__item, .instructor__content {
    background-color: var(--cutom-text-color) !important;
    border-radius: 10px;
    overflow: hidden;
}
/* .course__item{
    max-height: 350px !important;
    height: 350px !important;
} */

.popular__course__area .course__item .react-course-title a,
.instructor__area .instructor__content-2 h4 a {
    color: var(--cutom-links-color) !important;
}

.popular__course__area .course__item .course__card-icon .react__user {
    color: var(--cutom-prime-color);
}

.popular__course__area .course__item .course__card-icon .course__card-icon--1 svg {
    color: var(--cutom-prime-color);
}

.popular__course__area .course__item .course__card-icon .course__card-icon--1 span {
    color: var(--cutom-links-color);
}

.popular__course__area .view-courses {
    background-color: var(--cutom-prime-color);
    color: #fff;
}

.popular__course__area .view-courses:hover {

    background-color:  var(--cutom-bg-color);
    color: var(--cutom-links-color);
    border-color: var(--cutom-prime-color);
}

.swiper-button-prev, .swiper-button-next {
    border-color: var(--cutom-prime-color) !important;
}

/*sWIPPPPPPPPPPPPPPPPPER*/

#homeSliderSwiper .swiper-button-prev,
#homeSliderSwiper .swiper-button-next {
height:100% !important ;
color: var(--cutom-prime-color) !important;
background-color: transparent !important;
 border:none ; 
 margin: 0 2rem !important;
}
#homeSliderSwiper .swiper-button-prev:hover,
#homeSliderSwiper .swiper-button-next:hover {
    color:var(--cutom-links-color) !important;

}










/*-------header-------*/
header .main-header .nav-link, header .main-header .custom-them-mode .btn svg {
    color: var(--custom-navLinks-color) !important;
}

header .main-header.cutomFixed .nav-link, header .main-header.cutomFixed .custom-them-mode .btn svg {
    color: var(--cutom-links-color) !important;
}


header .btn-check:focus+.btn, header .btn:focus {
    box-shadow: none
}

header.react-header-four-top-header {
    background-color: var(--cutom-text-color) !important;
}


header.react-header-four-top-header .navbar-light .navbar-nav .nav-link, header.react-header-four-top-header .searchbar-part .custom-them-mode button {
    color: var(--cutom-links-color) !important;
}


/*-------footer-------*/
.react-footer {
    background-color: var(--cutom-text-color) !important;
    color: var(--cutom-links-color) !important;
}

.react-footer .footer-top .footer-subtitle, .react-footer .footer-top .footer-widget .footer-address li, .react-footer .footer-top .footer-widget .footer-address li a, .react-footer .footer-top .footer-widget .footer-title, .react-footer .copyright .react-copy-left, .react-footer .copyright .follow {
    color: var(--cutom-links-color) !important;
}

.react-footer .footer-top .footer-menu li a {
    color: var(--cutom-links-color) !important;
}

/*-------About-------*/
.about__area {
    background-color: var(--cutom-bg-color);
}

.about__area.about__area_one .about__paragraph {
    color: var(--cutom-links-color);
}
.about__area .about__title{
    color: var(--cutom-prime-color);
}

/*-------Contact-------*/
.react-contact-page {
    background-color: var(--cutom-bg-color);
}

.contact__invite--cards .card {
    background-color: transparent !important;
    color: var(--cutom-links-color);
}

.react-contact-page h2.contact-title,
.react-contact-page .address-sec li .text, .react-contact-page .address-sec li .text em, .react-contact-page .address-sec li .text a {
    color: var(--cutom-links-color);
}

.react-contact-page .address-sec, .react-contact-page .blog-form form input, .react-contact-page .blog-form form textarea  {
    background: var(--cutom-text-color) !important;
    border-color: var(--cutom-text-color);
    color: var(--custom-font-color);
}

.footer3__form form input {
    background: var(--cutom-bg-color) !important;
    color: var(--custom-font-color);
}

/*-------Course-------*/
.react-course-filter {
    background-color: var(--cutom-bg-color);
}

.back__course__page_grid .shorting__course {
    background-color: var(--cutom-text-color);
}

.result-count {
    color: var(--cutom-links-color) !important;
}

.back__course__page_grid .shorting__course .from-control {
    background-color: var(--cutom-bg-color);
}

.back__course__page_grid .shorting__course .from-control {
    border-left-color: var(--cutom-bg-color) !important;
}

.react-course-filter .single-studies .inner-course {
    background-color: var(--cutom-text-color);
    border-radius: 10px;
    overflow: hidden;
}

.react-course-filter .single-studies .inner-course .case-content .case-title a, .react-course-filter .single-studies .inner-course .case-content .react-ratings li.price {
    color: var(--cutom-links-color);
}

.back-pagination li a {
    background-color: var(--cutom-text-color);
    color: var(--cutom-links-color);
}

.back-pagination li a:hover {
    background-color: var(--cutom-prime-color);
}

/*-------Single Courses-------*/
.react-courses__single-page {
    background-color: var(--cutom-bg-color);
}

.react-sidebar, .widget.get-back-course, .react-sidebar .widget.get-back-course, .react-courses__single-page .course-single-tab .nav.nav-tabs, .react-courses__single-page .course-single-tab #back-tab-content .single-week, .react-courses__single-page .course-single-tab #back-tab-content .blog-form form input, .react-courses__single-page .course-single-tab #back-tab-content .blog-form form textarea {
    background: var(--cutom-text-color) !important;
}

.react-courses__single-page .course-single-tab #back-tab-content .blog-form form input, .react-courses__single-page .course-single-tab #back-tab-content .blog-form form textarea {
    border-color: var(--cutom-text-color);
}

.react-sidebar .widget.get-back-course .price__course li, .react-sidebar .widget.get-back-course .price__course li i, .react-sidebar .widget.get-back-course .price__course li b, .react-courses__single-page .course-single-tab #back-tab-content h3, .react-courses__single-page .course-single-tab #back-tab-content .five__number em, .react-courses__single-page .course-single-tab #back-tab-content .five__number h6 {
    color: var(--cutom-links-color);
}

.react-sidebar .widget {
    border-color: var(--cutom-text-color);
}

.react-courses__single-page .course-single-tab #back-tab-content .single-week .week__top, .react-courses__single-page .course-single-tab #back-tab-content .single-week .course__title li b, .react-courses__single-page .course-single-tab #back-tab-content .post-author .info .name {
    color: var(--cutom-prime-color) !important;
}

.react-courses__single-page .course-single-tab #back-tab-content .post-author {
    border-color: var(--cutom-prime-color) !important;
}

.react-courses__single-page .course-single-tab #back-tab-content p,.react-courses__single-page .course-single-tab #back-tab-content .single-week .course__title li{
    color: var( --custom-font-color);
}










/*-------Lesson-------*/
.lessons__study__area .accordion-item, .lessons__study__area .accordion-button:not(.collapsed), .lessons__study__area .accordion-button {
    background-color: var(--cutom-text-color);
}

.lessons__study__area .video-title,.lessons__study__area .weeks li{
    color: var(--custom-font-color) !important;
}
.lessons__study__area .weeks li svg{
    color: var(--cutom-prime-color) !important;
}


 .lessons__study__area .accordion-button:not(.collapsed), .lessons__study__area .accordion-button, .lessons__study__area .lesson-quize .quiz-question .card-title h3 {
    color: var(--cutom-prime-color);
}

.lessons__study__area .lesson-quize .quiz-question {
    background-color: var(--cutom-text-color);
}

.lessons__study__area .lesson-quize .quiz-question hr,
.lessons__study__area .lesson-quize .quiz-question .card-title,
.lessons__study__area .lesson-quize .quiz-question .card-body label {
    color: var(--cutom-links-color);
}



/*-------Cart-------*/
.cart__user__area {
    background-color: var(--cutom-bg-color);
}

.cart__user__area .card {
    background-color: var(--cutom-text-color);
}

.cart__user__area .card-title {
    color: var(--cutom-prime-color);
}

.cart__user__area .card-text {
    color: var(--custom-font-color);
}
.cart__user__area .price-info h5,.cart__user__area .price-info h3 {
    color: var(--custom-font-color) !important;
}



/*-------Profile-------*/
.profile-top {
    background-color: var(--cutom-bg-color);
}

.profile-top h3, .profile-top h2 {
    color: var(--cutom-prime-color);
}

.profile-top .react-course-filter.related__course .single-studies .inner-course {
    background-color: var(--cutom-text-color);
}

.profile-top .user-section, .profile-top p {
    color: var(--cutom-links-color);
}


.profile-top .teacher__course{
    color: var(--cutom-prime-color);
}  

/*-------Login-------*/
.react-login-page .login-right-form {
    background-color: var(--cutom-text-color) !important;
}

.react-login-page .login-right-form .login-top .logo h2, .react-login-page .login-right-form form p label, .react-login-page .login-right-form form .login-top h3, .login-right-form {
    color: var(--cutom-links-color) !important;
}

.react-login-page .login-right-form form input {
    background-color: var(--cutom-bg-color);
    color: var(--custom-font-color);
}





@media screen and (max-width: 991px) {
    header .main-header .nav-link {
        color: var(--cutom-prime-color) !important;
    }

    .react-header #responsive-navbar-nav {
        transition: all 0.1s ease;
        position: absolute;
    }

    /* 
    .react-header #responsive-navbar-nav.show {
        background-color:var(--cutom-bg-color) !important;
        top: 6rem !important;
        right: 0 !important;
        height: 100vh !important;
        width: 100%;
        padding: 2rem !important;
    }
    .react-header #responsive-navbar-nav  a{
        padding: 1rem 0;
        border-bottom: 1px solid transparent;
    }
    .react-header #responsive-navbar-nav  a:hover{
        
        border-bottom: 1px solid var(--cutom-prime-color);
    }
    .react-header #responsive-navbar-nav .navbar-nav .searchbar-part {
       flex-direction: column;
       align-items: flex-start;
    }
    .react-header #responsive-navbar-nav .navbar-nav .searchbar-part .react-login {
        margin: 1rem 0;
       padding: 0 !important;
       border: none;
    }
    .react-header #responsive-navbar-nav .navbar-nav .searchbar-part .react-login  a{
       padding: 1rem 2rem !important;
    }
    .react-header #responsive-navbar-nav .navbar-nav .searchbar-part .react-login  a:hover{
       border-color:var(--cutom-prime-color) ;
    }
    .react-header #responsive-navbar-nav .navbar-nav .searchbar-part .custom-them-mode {
        flex-direction: column;
    }
    .react-header #responsive-navbar-nav .navbar-nav .searchbar-part .custom-them-mode button {
       
        color: var(--cutom-prime-color) !important;
        border-bottom: 1px solid transparent;
       
    }
    .react-header #responsive-navbar-nav .navbar-nav .searchbar-part .custom-them-mode button:hover {
        
        border-bottom: 1px solid var(--cutom-prime-color);
    }

    .react-header .react-menus .nav-link {
        color: #333 !important;
        padding: 1rem;
        border: 1px solid transparent;

    }

    .react-header .react-menus .nav-item {
        padding: 1rem;
        margin: 0;
    }

    .react-header .react-menus>a {
        font-family: 'Source Sans Pro', sans-serif
    }


    .react-header .react-menus .nav-link:hover {
        color: var(--cutom-prime-color) !important;
        border: 1px solid #333;
    }

    .react-header .searchbar-part {
        display: flex !important;
    }

     */
    .react-header #responsive-navbar-nav.show {
        background-color: var(--cutom-bg-color) !important;
        top: 6rem !important;
        right: 0 !important;
        min-height: 80vh !important;
        height: 100vh !important;
        width: 100%;
        padding: 2rem !important;
        text-align: center;
    }

    .react-header #responsive-navbar-nav a {
        padding: 0.5rem 0;
        border-bottom: 1px solid #fff;
    }

    .react-header #responsive-navbar-nav a:last-child {
        padding: 1rem 0;
        border-bottom: 1px solid transparent;
    }

    .react-header #responsive-navbar-nav a:hover {
        border-bottom: 1px solid var(--cutom-prime-color);
    }

    .react-header #responsive-navbar-nav a:last-child:hover {
        border-bottom: 1px solid transparent;
    }


    .react-header .searchbar-part {
        display: block !important;
        border-bottom: 1px solid transparent;
    }

    .react-header .custom-them-mode {
        width: 100%;
        display: flex;
        gap: 1rem;

    }

    .react-header .custom-them-mode button {
        color: var(--cutom-prime-color) !important;

    }

    .react-header .custom-them-mode button:last-child {
        padding: 1rem;
    }


    .react-header .searchbar-part .custom-avatar-menu #dropdownMenuButton2 {
        border: none;

    }

    .react-header .searchbar-part .custom-avatar-menu .dropdown-menu li .dropdown-item {
        padding: 1rem !important;
    }

    .react-header .searchbar-part .react-login {
        border: none;
        padding: 1rem !important;
    }

    .react-header .searchbar-part .react-login a {
        width: 100%;

    }

    .react-header .searchbar-part .react-login a:hover {

        border: 1px solid #453773 !important;
    }


    /* 
    
  
    
    .react-header #responsive-navbar-nav .navbar-nav .searchbar-part .react-login  a{
       padding: 1rem 2rem !important;
    }
    .react-header #responsive-navbar-nav .navbar-nav .searchbar-part .react-login  a:hover{
       border-color:var(--cutom-prime-color) ;
    }
    .react-header #responsive-navbar-nav .navbar-nav .searchbar-part .custom-them-mode {
        flex-direction: column;
    }
    .react-header #responsive-navbar-nav .navbar-nav .searchbar-part .custom-them-mode button {
       
        color: var(--cutom-prime-color) !important;
        border-bottom: 1px solid transparent;
       
    }
    .react-header #responsive-navbar-nav .navbar-nav .searchbar-part .custom-them-mode button:hover {
        
        border-bottom: 1px solid var(--cutom-prime-color);
    }
    .react-header .react-menus .nav-link {
        color: #333 !important;
        padding: 1rem;
        border: 1px solid transparent;

    }
    .react-header .react-menus .nav-item {
        padding: 1rem;
        margin: 0;
    }
    .react-header .react-menus>a {
        font-family: 'Source Sans Pro', sans-serif
    }
    .react-header .react-menus .nav-link:hover {
        color: var(--cutom-prime-color) !important;
        border: 1px solid #333;
    }
    .react-header .searchbar-part {
        display: flex !important;
    } */








}



.react-footer.home-main .social-links li a:hover {

    background-color: var(--cutom-prime-color) !important;
    border-color: var(--cutom-prime-color);
}

.react-footer .footer-top .footer-menu li a:after {
    background-color: var(--cutom-prime-color) !important;
}

.react-footer.home-main .copyright .react-copy-left a, .react-footer.home-main .footer-top .footer-menu li a:hover, .react-footer.home-main .footer-top .footer-widget .footer-address li a:hover {
    color: var(--cutom-prime-color);
}



/* ------------- Error Page ---------------**/
.authincation {
    background: var(--cutom-bg-color);
    display: flex;
    min-height: 80vh;
    justify-content: center;
    align-items: center;
}


