@charset "utf-8";

@font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: url(http://fonts.gstatic.com/s/sourcesanspro/v21/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZYokRdr.ttf) format('truetype')
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url(http://fonts.gstatic.com/s/sourcesanspro/v21/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZMkhdr.ttf) format('truetype')
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(http://fonts.gstatic.com/s/sourcesanspro/v21/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPa7g.ttf) format('truetype')
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url(http://fonts.gstatic.com/s/sourcesanspro/v21/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZY4lBdr.ttf) format('truetype')
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url(http://fonts.gstatic.com/s/sourcesanspro/v21/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZclRdr.ttf) format('truetype')
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url(http://fonts.gstatic.com/s/sourcesanspro/v21/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZklxdr.ttf) format('truetype')
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(http://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3i94_AkA.ttf) format('truetype')
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(http://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zAkA.ttf) format('truetype')
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(http://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3aPw.ttf) format('truetype')
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(http://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rAkA.ttf) format('truetype')
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(http://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vAkA.ttf) format('truetype')
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(http://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3iu4nAkA.ttf) format('truetype')
}

body, html {
    font-size: 16px;
    color: #252525;
    font-family: 'Source Sans Pro', sans-serif;
    vertical-align: baseline;
    font-weight: 400;
    overflow-x: hidden;
    line-height: 1.7
}

img {
    max-width: 100%;
    height: auto
}

p {
    margin: 0 0 26px
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #00306e;
    margin: 0 0 26px;
    font-weight: 500;
    line-height: 1.2
}

h1 {
    font-size: 60px
}

h2 {
    font-size: 42px
}

h3 {
    font-size: 30px
}

h4 {
    font-size: 24px
}

h5 {
    font-size: 18px
}

h6 {
    font-size: 14px
}

a {
    color: #3270fc;
    transition: all .5s ease 0s;
    text-decoration: none !important;
    outline: 0 !important
}

a:active, a:hover {
    text-decoration: none;
    outline: 0;
    color: #3270fc
}

ul {
    list-style: none none;
    margin: 0;
    padding: 0
}

.pinkColor {
    color: #ee3688
}

.section-bg {
    background: #f5f5f5
}

.owl-carousel {
    direction: ltr !important
}

.owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    direction: ltr !important
}

.text-center {
    text-align: center
}

.text-right {
    text-align: right
}

.text-left {
    text-align: left
}

.react-absolute {
    position: absolute
}

.primary-color {
    color: #3270fc !important
}

.secondary-color {
    color: #00306e !important
}

.white-color {
    color: #fff !important
}

.normal {
    font-weight: 400
}

.z-index-1 {
    position: relative;
    z-index: 1
}

.no-padding {
    padding: 0
}

.mb---50 {
    margin-bottom: 50px
}

.mb---55 {
    margin-bottom: 55px
}

.mb---70 {
    margin-bottom: 70px
}

.mb---80 {
    margin-bottom: 80px
}

.mb---90 {
    margin-bottom: 90px
}

.react__title__section {
    padding-bottom: 30px
}

.react__title__section h2 {
    font-size: 50px;
    font-weight: 700;
    color: #00306e;
    margin: 0;
    line-height: 54px
}

.react__title__section a {
    background: #3270FC;
    border-radius: 6px;
    padding: 10px 26px;
    line-height: 20px;
    display: inline-block;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 12px
}

.react__title__section a:hover {
    opacity: .9
}

.react__title__section a svg {
    rotate: 180deg
}

.react__title__section.react__title__section2 h2 {
    font-size: 44px;
    color: #0F1216
}

.align-v {
    align-items: end
}

.w-btn {
    cursor: pointer;
    font-size: 15px;
    color: #fff;
    background: #2a6df5;
    border: 2px solid #ebebf1;
    text-align: center;
    padding: 0 30px;
    display: inline-block;
    font-weight: 600;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

.w-btn:hover {
    background-color: #fff;
    color: #00306e
}

.w-btn-2 {
    font-size: 15px;
    padding: 12px 37px;
    border: 2px solid #2a6df5
}

.w-btn-2:hover {
    border: 2px solid rgba(15, 22, 41, .078);
    background-color: #fff;
    color: #00306e
}

.w-btn-3 {
    padding: 7px 28px;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    border-radius: 22px;
    color: #fff;
    background: #2a6df5;
    border: 2px solid #2a6df5
}

.w-btn-3:hover {
    border: 2px solid rgba(15, 22, 41, .078);
    background-color: #fff;
    color: #00306e
}

.w-btn-4 {
    padding: 8px 41px;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    border-radius: 22px;
    background: #fff;
    color: #00306e;
    border: 2px solid rgba(15, 22, 41, .078)
}

.w-btn-4:hover {
    border-color: #2a6df5;
    color: #fff;
    background: #2a6df5
}

.w-btn-5 {
    padding: 8px 31px;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    border-radius: 22px;
    background: #fff;
    color: #00306e;
    border: 2px solid rgba(15, 22, 41, .078)
}

.w-btn-5:hover {
    border-color: #2a6df5;
    color: #fff;
    background: #2a6df5
}

.w-btn-8 {
    color: #00306e;
    background: #fff;
    padding: 3px 31px
}

.w-btn-8:hover {
    border-color: #2a6df5;
    color: #fff;
    background: #2a6df5
}

.w-btn-9 {
    padding: 7px 40px;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    border-radius: 22px;
    color: #fff;
    background: #2a6df5;
    border: 2px solid #2a6df5
}

.w-btn-9:hover {
    border: 2px solid rgba(15, 22, 41, .078);
    background-color: #fff;
    color: #00306e
}

.w-btn-11 {
    height: 40px;
    line-height: 36px;
    padding: 0 17px;
    background: #2a6df5;
    color: #fff;
    border-radius: 6px;
    border: 2px solid #2a6df5
}

.w-btn-11:hover {
    border: 2px solid #ebebf1;
    background-color: transparent;
    color: #00306e
}

.w-btn-12 {
    height: 40px;
    line-height: 36px;
    padding: 0 17px;
    background: #fd4680;
    color: #fff;
    border-radius: 0;
    border: 2px solid #fd4680
}

.w-btn-12:hover {
    border: 2px solid #4917bf;
    background-color: transparent;
    color: #00306e
}

.w-btn-16 {
    padding: 9px 31px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    background-color: #fd4680;
    color: #fff !important;
    border: 2px solid #fd4680
}

.w-btn-16:hover {
    border: 2px solid #fd4680;
    color: #00306e !important;
    background-color: transparent
}

.w-btn-blue {
    padding: 10px 20px;
    border: 2px solid rgba(255, 255, 255, .2);
    background: #fff;
    color: #00306e
}

.w-btn-blue-header {
    height: 40px;
    padding: 0 20px;
    line-height: 40px;
    background: #fff;
    color: #00306e;
    border: rgba(15, 22, 41, .078)
}

.w-btn-blue:hover {
    background-color: #2a6df5
}

.w-btn-blue-2 {
    padding: 10px 20px;
    background: #fff;
    color: #00306e;
    border-color: rgba(15, 22, 41, .078)
}

.w-btn-blue-2:hover {
    border-color: rgba(15, 22, 41, .078);
    color: #fff
}

.react-btn {
    background: #3270fc;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    border-radius: 30px;
    display: inline-block;
    padding: 10px 38px;
    border: 2px solid #3270fc;
    transition: all .5s ease 0s
}

.react-btn:hover {
    background: 0 0;
    color: #3270fc
}

.react-btn-border {
    background: #fff;
    border: 2px solid rgba(255, 255, 255, .3);
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
    display: inline-block;
    padding: 15px 35px;
    transition: all .5s ease 0s;
    color: #00306e;
    line-height: 20.11px
}

.react-btn-border:hover {
    background: #3270fc;
    color: #fff;
    border-color: #3270fc
}

.react-header {
    /* background: #fff;
    position: relative; */
    z-index: 9999
}

.react-header .react-inner-menus {
    display: flex
}

@media (min-width:992px) {
    .react-header .react-menus {
        display: flex
    }
}

@media (max-width:991px) {
    .react-header .react-menus .has-sub .arrow {
        left: 0;
        right: auto !important;
        border-right: 1px solid #f5f3f3
    }
}

.react-header .react-menus>li {
    float: none
}

.react-header .react-menus>li>a {
    font-family: 'Source Sans Pro', sans-serif
}

.react-header .react-menus li a {
    color: #00306e
}

.react-header .react-menus li a.react-current-page, .react-header .react-menus li a:hover {
    color: #D2093C !important
}

.react-header .react-main-menu {
    position: relative
}

.react-header .container {
    max-width: 1340px
}

.react-header .logo {
    padding: 14px 0
}

.react-header.react-header-two {
    box-shadow: 0 4px 10px rgba(0, 14, 32, .06)
}

.react-header.react-header-two .searchbar-part .phone-part {
    border-left: 1px solid #DFE2E5;
    padding-left: 25px
}

.react-header.react-header-two .searchbar-part .phone-part a {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    color: #0F1216
}

.react-header.react-header-two .searchbar-part .phone-part a svg, .react-header.react-header-two .searchbar-part .phone-part a:hover {
    color: #3270FC
}

.react-header.react-header-two .react-logins {
    border: none;
    padding-left: 20px
}

.react-header.react-header-two .react-logins a {
    background: #3270FC;
    border-radius: 6px;
    padding: 10px 26px;
    line-height: 20px;
    display: inline-block;
    color: #fff;
    font-weight: 600;
    font-size: 16px
}

.react-header.react-header-two .react-logins a svg {
    width: 18px;
    rotate: 180deg
}

.react-header.react-header-two .react-logins a:hover {
    opacity: .9
}

.react-header.react-header-three .cate-part {
    display: flex;
    align-items: center
}

.react-header.react-header-three .cate-part svg {
    margin-right: -16px;
    margin-top: 4px
}

.react-header.react-header-three .cate-part .react-category-menu {
    margin-left: 25px
}

.react-header.react-header-three .cate-part .react-category-menu li {
    position: relative;
    cursor: pointer;
    font-size: 15px;
    font-weight: 600
}

.react-header.react-header-three .cate-part .react-category-menu li a {
    color: #091524
}

.react-header.react-header-three .cate-part .react-category-menu li a:hover {
    color: #3270FC
}

.react-header.react-header-three .cate-part .react-category-menu li a i {
    font-size: 22px;
    position: relative;
    top: 4px
}

.react-header.react-header-three .cate-part .react-category-menu li ul {
    position: absolute;
    left: -20px;
    top: 100%;
    width: 172px;
    transform: scaleY(0);
    transition: all .4s ease-in-out 0s;
    transform-origin: 0 0 0;
    opacity: 0;
    visibility: hidden;
    padding: 20px 20px 23px;
    background: #fff;
    border-radius: 0 0 4px 4px
}

.react-header.react-header-three .cate-part .react-category-menu li ul li {
    padding: 3px 0
}

.react-header.react-header-three .cate-part .react-category-menu li:hover ul {
    transform: scaleY(1);
    opacity: 1;
    visibility: visible
}

.react-header.react-header-three .cate-part .react-category-menu li:hover ul li {
    font-size: 15px
}

.react-header.react-header-three .cate-part:hover, .react-header.react-header-three .cate-part:hover .react-category-menu li {
    color: #3270FC
}

.react-header.react-header-three .cate-part:hover .react-category-menu li ul {
    transform: scaleY(1);
    opacity: 1;
    visibility: visible
}

.react-header.react-header-three .cate-part:hover .react-category-menu li ul li {
    font-size: 15px
}
/* 
.react-header.react-header-four {
    width: 100%;
    background: 0 0; 
    position: absolute;
    top: 0;
    left: 0
}

*/

.react-header.react-header-four.react-sticky {
    background: #000
}

.react-header.react-header-four .react-inner-menus li {
    background: 0 0 !important
}

.react-header.react-header-four .react-inner-menus ul li a {
    color: #fff
}

.react-header.react-header-four .react-inner-menus ul li a.react-current-page {
    color: #3270fc !important
}

.react-header.react-header-four .react-login svg {
    stroke: #fff
}

.react-header.react-header-four .search-form {
    background: 0 0 !important;
    width: inherit;
    height: inherit;
    padding: inherit !important
}

.react-header.react-header-four .search-form .search-btn svg {
    stroke: #fff;
    width: 18px;
    aspect-ratio: 1;
    transform: translateY(-4px);
    cursor: pointer
}

.react-header.react-header-four .search-form .search-input {
    position: absolute;
    right: 0;
    top: 70px;
    background: #434852 !important;
    color: #fff !important;
    margin-left: 10px;
    width: 250px;
    height: 0;
    padding: 0 10px;
    transition: 500ms
}

.react-header.react-header-four .search-form .search-input.show {
    padding: 5px 10px;
    height: 40px
}

.react-header.react-header-four .react-menus li a:hover {
    color: #3270fc !important
}

.react-header.react-header-four .react-menus li ul {
    background: #000
}

.react-header.react-header-four .react-menus li ul li a:hover {
    color: #3270fc !important
}

.react-header.react-header-five.react-sticky .menu-part {
    background: #fff;
    box-shadow: 10px 0 20px rgba(8, 0, 41, .08)
}

.react-header.react-header-five.react-sticky .search-form {
    background: #f3f4fa !important
}

.react-header.react-header-five .cate-part {
    display: flex;
    align-items: center
}

.react-header.react-header-five .cate-part svg {
    margin-right: -16px;
    margin-top: 4px
}

.react-header.react-header-five .cate-part .react-category-menu {
    margin-left: 25px
}

.react-header.react-header-five .cate-part .react-category-menu li {
    position: relative;
    cursor: pointer;
    font-size: 15px;
    font-weight: 600
}

.react-header.react-header-five .cate-part .react-category-menu li a {
    color: #091524
}

.react-header.react-header-five .cate-part .react-category-menu li a:hover {
    color: #3270FC
}

.react-header.react-header-five .cate-part .react-category-menu li a i {
    font-size: 22px;
    position: relative;
    top: 4px
}

.react-header.react-header-five .cate-part .react-category-menu li ul {
    position: absolute;
    left: -20px;
    top: 100%;
    width: 172px;
    transform: scaleY(0);
    transition: all .4s ease-in-out 0s;
    transform-origin: 0 0 0;
    opacity: 0;
    visibility: hidden;
    padding: 20px 20px 23px;
    background: #fff;
    border-radius: 0 0 4px 4px
}

.react-header.react-header-five .cate-part .react-category-menu li ul li {
    padding: 3px 0
}

.react-header.react-header-five .cate-part .react-category-menu li:hover ul {
    transform: scaleY(1);
    opacity: 1;
    visibility: visible
}

.react-header.react-header-five .cate-part .react-category-menu li:hover ul li {
    font-size: 15px
}

.react-header.react-header-five .cate-part:hover, .react-header.react-header-five .cate-part:hover .react-category-menu li {
    color: #3270FC
}

.react-header.react-header-five .cate-part:hover .react-category-menu li ul {
    transform: scaleY(1);
    opacity: 1;
    visibility: visible
}

.react-header.react-header-five .cate-part:hover .react-category-menu li ul li {
    font-size: 15px
}

.react-header .react-menus.home-four li {
    background: #000
}

.react-header .react-header-five .cate-part {
    display: flex;
    align-items: center
}

.react-header .react-header-five .cate-part.react-sticky {
    background: #fff !important
}

.react-header .react-header-five .cate-part svg {
    margin-right: -16px;
    margin-top: 4px
}

.react-header .react-header-five .cate-part .react-category-menu {
    margin-left: 25px
}

.react-header .react-header-five .cate-part .react-category-menu li {
    position: relative;
    cursor: pointer;
    font-size: 15px;
    font-weight: 600
}

.react-header .react-header-five .cate-part .react-category-menu li a {
    color: #091524
}

.react-header .react-header-five .cate-part .react-category-menu li a:hover {
    color: #3270FC
}

.react-header .react-header-five .cate-part .react-category-menu li a i {
    font-size: 22px;
    position: relative;
    top: 4px
}

.react-header .react-header-five .cate-part .react-category-menu li ul {
    position: absolute;
    left: -20px;
    top: 100%;
    width: 172px;
    transform: scaleY(0);
    transition: all .4s ease-in-out 0s;
    transform-origin: 0 0 0;
    opacity: 0;
    visibility: hidden;
    padding: 20px 20px 23px;
    background: #fff;
    border-radius: 0 0 4px 4px
}

.react-header .react-header-five .cate-part .react-category-menu li ul li {
    padding: 3px 0
}

.react-header .react-header-five .cate-part .react-category-menu li:hover ul {
    transform: scaleY(1);
    opacity: 1;
    visibility: visible
}

.react-header .react-header-five .cate-part .react-category-menu li:hover ul li {
    font-size: 15px
}

.react-header .react-header-five .cate-part:hover, .react-header .react-header-five .cate-part:hover .react-category-menu li {
    color: #3270FC
}

.react-header .react-header-five .cate-part:hover .react-category-menu li ul {
    transform: scaleY(1);
    opacity: 1;
    visibility: visible
}

.react-header .react-header-five .cate-part:hover .react-category-menu li ul li {
    font-size: 15px
}

.react-header.react-sticky {
    background: #fff;
    position: fixed !important;
    width: 100%;
    -webkit-animation: stickyanimations .8s cubic-bezier(.25, .46, .45, .94) both;
    animation: stickyanimations .8s cubic-bezier(.25, .46, .45, .94) both;
    box-shadow: 10px 0 20px rgba(8, 0, 41, .08);
    top: 0;
    left: 0;
    right: 0;
    z-index: 999
}

.react-header.react-sticky .toolbar-area {
    display: none
}

.react-header .menu-title {
    font-size: 18px;
    font-weight: 700;
    color: #00306e
}

.react-header .searchbar-part {
    display: flex;
    align-items: center
}

.react-header .searchbar-part .search-form {
    background: #F4F5F5;
    border-radius: 6px;
    padding: 7px 18px 9px 25px
}

.react-header .searchbar-part .search-form [type=text] {
    border: none;
    color: #4b5b70;
    font-size: 14px;
    outline: 0;
    background: 0 0
}

.react-header .searchbar-part .search-form button {
    border: none;
    background: 0 0;
    padding: 0
}

.react-header .searchbar-part .react-login {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    border-right: 1px solid #DFE2E5;
    padding: 0 30px
}

.react-header .searchbar-part .react-login a {
    color: #00306e
}

.react-header .searchbar-part .react-login a:hover {
    color: #3270fc
}

.react-header .searchbar-part .react-login svg {
    color: #00306e;
    margin-left: 3px;
    position: relative;
    top: -3px;
    width: 19px
}

.react-header .topbar-area.style1 {
    background: #00306e;
    color: #fff;
    padding: 5px 0;
    font-size: 14px
}

.react-header .topbar-area.style1 .topbar-contact ul {
    display: flex
}

.react-header .topbar-area.style1 .topbar-contact ul li {
    margin-right: 30px
}

.react-header .topbar-area.style1 .topbar-contact ul li svg {
    width: 18px;
    padding-right: 2px
}

.react-header .topbar-area.style1 .topbar-contact ul li:first-child {
    margin-right: 0
}

.react-header .topbar-area.style1 li a {
    color: #fff
}

.react-header .topbar-area.style1 li a:hover {
    color: #b5b2b2
}

.react-header .topbar-area.style1 ul.social-links li {
    margin-right: 15px
}

.react-header .topbar-area.style1 ul.social-links li a {
    width: unset;
    height: unset;
    line-height: unset;
    border: 0
}

.react-header .topbar-area.style1 ul.social-links li a:hover {
    background: 0 0;
    color: #b5b2b2
}

.react-sub-shadow li ul.sub-menu {
    box-shadow: 0 30px 30px 0 rgba(10, 0, 45, .1)
}

.topbar-area.style7 {
    background: #316ffb;
    color: #fff;
    padding: 5px 0;
    font-size: 14px
}

.topbar-area.style7 .topbar-contact ul {
    display: flex;
    justify-content: flex-end
}

.topbar-area.style7 .topbar-contact ul li {
    margin-right: 50px
}

.topbar-area.style7 .topbar-contact ul li svg {
    width: 17px;
    margin-left: 10px
}

.topbar-area.style7 .topbar-contact ul li:first-child {
    margin-left: 0
}

.topbar-area.style7 li a {
    color: #fff
}

.topbar-area.style7 li a:hover {
    color: #b5b2b2
}

.topbar-area.style7 ul.social-links li {
    margin-right: 25px
}

.topbar-area.style7 ul.social-links li a {
    width: unset;
    height: unset;
    line-height: unset;
    border: 0
}

.topbar-area.style7 ul.social-links li a:hover {
    background: 0 0;
    color: #b5b2b2
}

@-webkit-keyframes stickyanimations {
    0% {
        -webkit-transform: translateY(-100px);
        transform: translateY(-100px)
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@keyframes stickyanimations {
    0% {
        -webkit-transform: translateY(-100px);
        transform: translateY(-100px)
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

.event__video-btn--play {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%)
}

.event__video-btn--play-btn {
    display: inline-block;
    width: 90px;
    height: 90px;
    line-height: 86px;
    text-align: center;
    font-size: 25px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background: #fff;
    color: #2a6df5;
    border-color: #fff
}

.event__video-btn {
    position: relative
}

.event__video-btn--play-btn::before {
    content: "";
    height: 120px;
    width: 120px;
    position: absolute;
    left: -15px;
    top: -15px;
    border: 1px solid rgba(255, 255, 255, .302);
    border-radius: 50%;
    animation: videoBtnAnim 3s linear infinite;
    display: block
}

.event__video-btn--play-btn::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #fff;
    top: 0;
    left: 0;
    border-radius: 50%;
    transition: all .3s ease-in-out
}

.event__video-btn--play-btn i {
    z-index: 2;
    position: relative;
    top: 9px;
    font-size: 35px;
    rotate: 180deg
}

@-webkit-keyframes videoBtnAnim {
    0% {
        -webkit-transform: scale(.8);
        transform: scale(.8)
    }

    25% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    50% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2)
    }

    75% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    to {
        -webkit-transform: scale(.8);
        transform: scale(.8)
    }
}

@keyframes videoBtnAnim {
    0% {
        -webkit-transform: scale(.8);
        transform: scale(.8)
    }

    25% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    50% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2)
    }

    75% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    to {
        -webkit-transform: scale(.8);
        transform: scale(.8)
    }
}

@keyframes videoBtnAnim6 {
    0% {
        -webkit-transform: scale(.6);
        transform: scale(.6)
    }

    25% {
        -webkit-transform: scale(.7);
        transform: scale(.7)
    }

    50% {
        -webkit-transform: scale(.8);
        transform: scale(.8)
    }

    75% {
        -webkit-transform: scale(.7);
        transform: scale(.7)
    }

    to {
        -webkit-transform: scale(.6);
        transform: scale(.6)
    }
}

@keyframes videoBtnAnimSix2 {
    0% {
        -webkit-transform: scale(.4);
        transform: scale(.4)
    }

    25% {
        -webkit-transform: scale(.5);
        transform: scale(.5)
    }

    50% {
        -webkit-transform: scale(.6);
        transform: scale(.6)
    }

    75% {
        -webkit-transform: scale(.5);
        transform: scale(.5)
    }

    to {
        -webkit-transform: scale(.4);
        transform: scale(.4)
    }
}

.react__shape__abd {
    position: absolute;
    left: 71%;
    top: 45%
}

/* .react__shape__ab {
    position: absolute;
    left: 54.4%;
    top: 28%;
    -webkit-animation: slide-top 5000ms linear infinite alternate;
    animation: slide-top 5000ms linear infinite alternate
} */

.react__shape__abc {
    position: absolute;
    right: 39%;
    top: 80%;
    -webkit-animation: slide-top 5000ms linear infinite alternate;
    animation: slide-top 5000ms linear infinite alternate
}

.home-banner-part {
    position: relative
}

.home-banner-part .container {
    max-width: 1430px
}

.home-banner-part .react__shape__1 {
    position: absolute;
    left: 100px;
    top: 80px;
    -webkit-animation: slide-top 5000ms linear infinite alternate;
    animation: slide-top 5000ms linear infinite alternate
}

.home-banner-part .react__shape__2 {
    position: absolute;
    left: -240px;
    bottom: 80px;
    -webkit-animation: slide-top 5500ms linear infinite alternate-reverse;
    animation: slide-top 5500ms linear infinite alternate-reverse
}

.home-banner-part .react__shape__3 {
    position: absolute;
    right: -35px;
    top: 80px;
    -webkit-animation: slide-top 5500ms linear infinite alternate-reverse;
    animation: slide-top 5500ms linear infinite alternate-reverse
}

.home-banner-part .react__shape__4 {
    position: absolute;
    right: 250px;
    bottom: 190px;
    -webkit-animation: rotate-center 6600ms ease-in-out infinite alternate both;
    animation: rotate-center 6600ms ease-in-out infinite alternate both
}

.home-banner-part .react__shape__5 {
    position: absolute;
    right: 460px;
    top: 160px
}

.home-banner-part .banner-content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%)
}

.home-banner-part .banner-content .banner-title {
    font-size: 54px;
    margin-bottom: 15px;
    color: #091524;
    line-height: 64px;
    font-weight: 800;
    letter-spacing: -.9px
}

.home-banner-part .banner-content .banner-desc {
    font-size: 18px;
    color: #3e3e51
}

.home-banner-part .banner-content .react-btn {
    padding: 12px 38px
}

.home-banner-part .react__hero__card {
    position: absolute;
    top: 280px;
    right: 172px;
    width: 310px;
    background-color: #fff;
    border: 1px solid #ebebf1;
    box-shadow: 0 20px 60px 0 rgba(0, 15, 56, .1);
    border-radius: 10px;
    -webkit-animation: slide-top 5500ms linear infinite alternate;
    animation: slide-top 5500ms linear infinite alternate
}

.home-banner-part .react__hero__card .react__thumb {
    position: relative
}

.home-banner-part .react__hero__card .react__thumb img {
    border-radius: 12px 12px 0 0
}

.home-banner-part .react__hero__card .react__thumb .react__price {
    border-radius: 50%;
    background-color: #3270fc;
    width: 50px;
    height: 50px;
    position: absolute;
    right: 29px;
    bottom: -23px;
    display: inline-block;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    line-height: 50px;
    text-align: center
}

.home-banner-part .react__hero__card .hero__card-content {
    padding: 30px
}

.home-banner-part .react__hero__card .hero__card-content .react-category {
    font-size: 14px;
    line-height: 28px;
    color: #f11e78;
    background-color: rgba(241, 30, 120, .059);
    border-radius: 12px;
    padding: 4px 14px
}

.home-banner-part .react__hero__card .hero__card-content .react-course-title {
    font-size: 20px;
    line-height: 1.5;
    margin: 14px 0 16px;
    font-weight: 800
}

.home-banner-part .react__hero__card .hero__card-content .react-course-title a {
    color: #0f1629
}

.home-banner-part .react__hero__card .hero__card-content .react-course-title a:hover {
    color: #3270fc
}

.home-banner-part .react__hero__card .hero__card-content .hero__card-icon {
    justify-content: space-between
}

.home-banner-part .react__hero__card .hero__card-content .hero__card-icon .hero__card-icon--1 svg {
    border-radius: 50%;
    background-color: #f2f6fe;
    padding: 7px;
    width: 32px;
    height: 32px;
    color: #3270fc
}

.home-banner-part .react__hero__card .hero__card-content .hero__card-icon .hero__card-icon--1 span {
    font-weight: 500;
    font-size: 15px;
    color: #4b4c57;
    padding-left: 7px
}

.home-banner-part .react__hero__card .hero__card-content .hero__card-icon .hero__card-icon--2 svg {
    border-radius: 50%;
    background-color: #fff9f1;
    padding: 7px;
    width: 32px;
    height: 32px;
    color: #ff9415
}

.home-banner-part .react__hero__card .hero__card-content .hero__card-icon .hero__card-icon--2 span {
    font-weight: 500;
    font-size: 15px;
    color: #4b4c57;
    padding-left: 7px
}

.home-banner-part .react__hero__card .hero__card-content .hero__card-icon .react__user img {
    width: 35px;
    border-radius: 50%
}

.home-five.searchbar-part .search-form {
    background: #fff !important
}

.home-five.menu-part {
    width: 100%;
    position: absolute
}

.home-five.section-title-area .section-pretitle {
    color: #252525;
    font-size: 20px;
    font-weight: 500;
    line-height: 15px !important;
    margin-bottom: 20px
}

.home-five.section-title-area .section-title {
    color: #252525;
    font-size: 50px;
    font-weight: 600;
    line-height: 60px
}

@media (max-width:1200px) {
    .home-five.section-title-area .section-pretitle {
        font-size: 20px
    }

    .home-five.section-title-area .section-title {
        font-size: 40px
    }
}

@media (max-width:576px) {
    .home-five.section-title-area .section-pretitle {
        font-size: 16px;
        line-height: 0 !important
    }

    .home-five.section-title-area .section-title {
        font-size: 30px;
        line-height: 40px
    }
}

.home-five.banner {
    position: relative;
    background: url(../Images/home5/banner-bg.png) center no-repeat #f3f4fa;
    background-size: cover;
    display: flex;
    justify-content: space-between;
    align-items: center
}

.home-five.banner .contents {
    padding: 300px 0
}

.home-five.banner .contents .banner-pretitle {
    color: #252525;
    font-size: 20px;
    font-weight: 500
}

.home-five.banner .contents .banner-title {
    color: #252525;
    font-size: 60px;
    font-weight: 700
}

.home-five.banner .contents p {
    color: #252525;
    font-size: 20px;
    max-width: 420px;
    font-weight: 200px;
    margin-bottom: 35px
}

.home-five.banner .contents .started-btn {
    padding: 20px 30px;
    border-radius: 5px;
    border: none;
    background: #3270fc;
    font-size: 20px;
    transition: background 300ms
}

.home-five.banner .contents .started-btn a {
    color: #fff
}

.home-five.banner .contents .started-btn:hover {
    background: #5387ff
}

.home-five.banner .hero {
    max-width: 626px;
    position: absolute;
    bottom: 0;
    left: 16%
}

.home-five.banner .hero img {
    max-width: 100%
}

@media (max-width:1600px) {
    .home-five.banner .hero {
        left: 5%
    }
}

@media (max-width:1200px) {
    .home-five.banner .contents {
        padding-bottom: 80px
    }

    .home-five.banner .hero {
        position: relative;
        bottom: inherit;
        left: inherit
    }
}

@media (max-width:1200px) {
    .home-five.banner .contents {
        padding: 150px 0 40px;
        max-width: 600px
    }
}

@media (max-width:576px) {
    .home-five.banner .contents .banner-pretitle {
        font-size: 16px
    }

    .home-five.banner .contents .banner-title {
        font-size: 40px;
        max-width: 400px
    }
}

.home-five.services-section .service-top-contents {
    margin-bottom: 100px
}

.home-five.services-section .service-top-contents .details {
    display: flex;
    flex-direction: column;
    padding-top: 50px
}

.home-five.services-section .service-top-contents .details .top-detail {
    font-size: 19px;
    margin-bottom: 30px;
    color: #6b6b6b
}

.home-five.services-section .service-top-contents .details .top-detail .hightlight {
    color: #252525;
    font-weight: 500
}

.home-five.services-section .service-top-contents .details .bottom-detail {
    font-size: 16px;
    color: #a5a5a5
}

.home-five.services-section .service-bottom-contents .service-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 20px
}

.home-five.services-section .service-bottom-contents .service-item .service-item-icon {
    margin-bottom: 30px
}

.home-five.services-section .service-bottom-contents .service-item .service-item-title {
    color: #252525;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 10px
}

.home-five.services-section .service-bottom-contents .service-item p {
    margin-bottom: 30px;
    color: #a5a5a5
}

.home-five.services-section .service-bottom-contents .service-item .learn-more a {
    color: #a5a5a5
}

.home-five.services-section .service-bottom-contents .service-item .learn-more i {
    color: #a5a5a5;
    margin-right: 10px;
    font-size: 14px;
    visibility: hidden;
    transform: translateX(-5px);
    transition: transform 500ms;
    rotate: 180deg
}

.home-five.services-section .service-bottom-contents .service-item .learn-more:hover a, .home-five.services-section .service-bottom-contents .service-item .learn-more:hover i {
    color: #3270fc
}

.home-five.services-section .service-bottom-contents .service-item:hover .service-item-icon {
    animation: shake-that .5s
}

.home-five.services-section .service-bottom-contents .service-item:hover .learn-more i {
    visibility: visible !important;
    transform: translateX(0)
}

@media (max-width:1200px) {
    .home-five.services-section .service-bottom-contents {
        margin-bottom: -40px
    }

    .home-five.services-section .service-bottom-contents .service-item p {
        margin-bottom: 15px
    }

    .home-five.services-section .service-bottom-contents .learn-more {
        margin-bottom: 40px
    }
}

@media (max-width:1200px) {
    .home-five.services-section .service-item {
        margin-bottom: 50px
    }

    .home-five.services-section .service-item:last-child {
        margin-bottom: 0
    }
}

@media (max-width:769px) {
    .home-five.services-section .service-top-contents {
        margin-bottom: 40px
    }

    .home-five.services-section .service-item p {
        max-width: 300px
    }
}

.home-five.about-section {
    background: #f4f5fb
}

.home-five.about-section .section-title-area {
    margin-bottom: 65px
}

.home-five.about-section .about-cards .about-card-item {
    background: #fff;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 50px;
    border-radius: 5px;
    box-shadow: 5px 5px 19px -6px rgba(0, 0, 0, .05);
    margin-bottom: 30px !important
}

.home-five.about-section .about-cards .about-card-item:last-child {
    margin-bottom: 0
}

.home-five.about-section .about-cards .about-card-item .card-item-icon {
    width: 60px;
    margin-left: 30px
}

.home-five.about-section .about-cards .about-card-item .card-item-icon img {
    max-width: 100%
}

.home-five.about-section .about-cards .about-card-item .card-title {
    color: #252525;
    font-size: 22px;
    font-weight: 600
}

.home-five.about-section .about-cards .about-card-item p {
    margin-top: 10px;
    margin-bottom: 0
}

@media (max-width:1200px) {
    .home-five.about-section .about-hero {
        margin-bottom: 40px
    }
}

@media (max-width:576px) {
    .home-five.about-section .about-card-item {
        padding: 25px !important;
        flex-direction: column;
        align-items: unset !important
    }

    .home-five.about-section .about-card-item .card-item-icon {
        margin-bottom: 20px
    }
}

.home-five.coaching-section {
    position: relative
}

.home-five.coaching-section .container {
    margin-left: inherit
}

.home-five.coaching-section .coaching-hero {
    margin-left: 50px
}

.home-five.coaching-section .coaching-hero img {
    max-width: 100%
}

.home-five.coaching-section .coaching-contents {
    max-width: 700px
}

.home-five.coaching-section .coaching-contents p {
    margin: 20px 0 40px
}

.home-five.coaching-section .coaching-counter {
    display: flex;
    justify-content: start;
    align-items: center
}

.home-five.coaching-section .coaching-counter .counter-item {
    margin-right: 80px
}

.home-five.coaching-section .coaching-counter .counter {
    color: #252525;
    font-size: 50px;
    font-weight: 600
}

.home-five.coaching-section .coaching-counter .sub-span {
    color: #252525;
    font-size: 50px;
    font-weight: 700px
}

.home-five.coaching-section .coaching-counter p {
    color: #777;
    font-size: 20px;
    font-weight: 500;
    margin: 0
}

@media (max-width:1200px) {
    .home-five.coaching-section {
        max-width: 100%
    }

    .home-five.coaching-section .coaching-hero {
        width: 100%;
        margin-bottom: 40px
    }

    .home-five.coaching-section .coaching-hero img {
        position: relative;
        width: 100%
    }
}

@media (max-width:576px) {
    .home-five.coaching-section {
        max-width: 100%
    }

    .home-five.coaching-section .coaching-counter .sub-span, .home-five.coaching-section .coaching-counter span {
        font-size: 35px !important
    }

    .home-five.coaching-section .coaching-counter p {
        font-size: 15px !important
    }
}

@media (max-width:480px) {
    .home-five.coaching-section .coaching-counter {
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap
    }

    .home-five.coaching-section .coaching-counter .counter-item {
        margin: 0 0 0 15px !important
    }
}

.home-five.journey-section .journey-card {
    border-radius: 10px;
    margin-bottom: 50px;
    position: relative
}

.home-five.journey-section .journey-card:hover .journey-card-picture img {
    transform: scale(110%)
}

.home-five.journey-section .journey-card:hover .journey-contents {
    bottom: 20px
}

.home-five.journey-section .journey-card:hover .journey-contents .card-title a {
    color: #3270fc
}

.home-five.journey-section .journey-card .journey-card-picture {
    max-width: 100%;
    overflow: hidden;
    border-radius: 10px
}

.home-five.journey-section .journey-card .journey-card-picture img {
    width: 100%;
    transition: transform 500ms
}

.home-five.journey-section .journey-card .journey-contents {
    width: 85%;
    padding: 40px;
    position: absolute;
    bottom: -50px;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    border-radius: 10px;
    box-shadow: 5px 5px 19px -6px rgba(0, 0, 0, .05);
    transition: 500ms
}

.home-five.journey-section .journey-card .journey-contents .lessons {
    color: #777
}

.home-five.journey-section .journey-card .journey-contents .card-title {
    font-size: 21px;
    font-weight: 600;
    margin-bottom: 0 !important
}

.home-five.journey-section .journey-card .journey-contents .card-title a {
    color: #252525
}

.home-five.journey-section .journey-card .journey-contents .card-title a:hover {
    color: #84a7f8 !important
}

@media (max-width:1400px) {
    .home-five.journey-section .journey-contents {
        padding: 20px !important
    }

    .home-five.journey-section .journey-contents .card-title {
        font-size: 18px !important
    }
}

@media (max-width:1200px) {
    .home-five.journey-section {
        margin-bottom: -50px
    }

    .home-five.journey-section .journey-card {
        margin-bottom: 100px
    }
}

.home-five.pricing-section {
    position: relative
}

.home-five.pricing-section .pricing-section-header-bg {
    z-index: -1;
    position: absolute;
    top: 0;
    width: 100%;
    height: 400px;
    background-image: url(../Images/home5/pricing-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center
}

.home-five.pricing-section .section-title-area {
    margin-bottom: 70px !important
}

.home-five.pricing-section .section-title-area .section-pretitle, .home-five.pricing-section .section-title-area .section-title {
    color: #fff
}

.home-five.pricing-section .price-card {
    background: #fff;
    box-shadow: 0 0 19px 0 rgba(0, 0, 0, .075);
    padding: 50px 0;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
}

.home-five.pricing-section .price-card .price-plan {
    position: relative;
    width: 100%;
    padding-bottom: 60px;
    border-bottom: 2px solid #f3f4fa;
    text-align: center
}

.home-five.pricing-section .price-card .price-plan .contents {
    padding: 0 40px
}

.home-five.pricing-section .price-card .price-plan .plan-type {
    color: #252525;
    font-size: 20px;
    font-weight: 500
}

.home-five.pricing-section .price-card .price-plan .price {
    color: #252525;
    font-size: 70px;
    font-weight: 600;
    line-height: 70px
}

.home-five.pricing-section .price-card .price-plan .validity {
    color: #777
}

.home-five.pricing-section .price-card .price-plan .popular-plan {
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
    background: #3270fc;
    width: 75%;
    padding: 5px 0;
    border-radius: 50px;
    display: grid;
    place-items: center;
    color: #fff;
    font-size: 22px;
    font-weight: 500
}

.home-five.pricing-section .price-card .price-details {
    padding: 75px 50px 0;
    width: 100%
}

.home-five.pricing-section .price-card .price-details .item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px
}

.home-five.pricing-section .price-card .price-details .item .course {
    color: #252525;
    font-size: 20px;
    font-weight: 500
}

.home-five.pricing-section .price-card .price-details .item .value {
    color: #777;
    font-size: 20px
}

.home-five.pricing-section .price-card .price-details .price-btn {
    width: 100%;
    padding: 10px 0;
    border-radius: 50px;
    border: none;
    background: #f3f4fa;
    font-size: 20px;
    margin-top: 15px;
    transition: background 300ms
}

.home-five.pricing-section .price-card .price-details .price-btn a {
    color: #252525
}

.home-five.pricing-section .price-card .price-details .price-btn:hover {
    background: #3270fc
}

.home-five.pricing-section .price-card .price-details .price-btn:hover a {
    color: #fff
}

@media (max-width:1200px) {
    .home-five.pricing-section {
        margin-top: 60px !important;
        margin-bottom: -30px
    }

    .home-five.pricing-section .price-card {
        margin-bottom: 30px
    }
}

.home-five.trending-section-five .trending-card {
    position: relative
}

.home-five.trending-section-five .trending-card .card-image {
    overflow: hidden
}

.home-five.trending-section-five .trending-card .card-image:hover img {
    transform: scale(110%)
}

.home-five.trending-section-five .trending-card .card-image img {
    width: 100%;
    transition: transform 300ms
}

.home-five.trending-section-five .trending-card .price-tag {
    position: absolute;
    top: 200px;
    left: 30px;
    background: #3270fc;
    width: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1;
    padding: 7px;
    border-radius: 5px
}

.home-five.trending-section-five .trending-card .price-tag .dollars {
    color: #fff;
    font-size: 25px;
    font-weight: 500
}

.home-five.trending-section-five .trending-card .price-tag .cents {
    color: #fff;
    font-size: 16px
}

.home-five.trending-section-five .trending-card .contents {
    padding: 30px 30px 50px
}

.home-five.trending-section-five .trending-card .contents .status {
    margin-bottom: 10px
}

.home-five.trending-section-five .trending-card .contents .status .students {
    margin-right: 40px;
    color: #777;
    font-size: 16px
}

.home-five.trending-section-five .trending-card .contents .status .students i {
    color: #777;
    font-size: 14px;
    margin-right: 7px
}

.home-five.trending-section-five .trending-card .contents .status .lessons {
    color: #777;
    font-size: 16px
}

.home-five.trending-section-five .trending-card .contents .status .lessons i {
    color: #777;
    font-size: 14px;
    margin-right: 7px
}

.home-five.trending-section-five .trending-card .contents .card-title {
    font-size: 22px;
    font-weight: 600
}

.home-five.trending-section-five .trending-card .contents .card-title a {
    color: #252525
}

.home-five.trending-section-five .trending-card .contents .card-title a:hover {
    color: #3270fc
}

.home-five.trending-section-five .btn-area {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center
}

.home-five.trending-section-five .btn-area .all-course-btn {
    background: #3270fc;
    transition: background 300ms;
    padding: 15px 45px;
    border-radius: 5px;
    border: none
}

.home-five.trending-section-five .btn-area .all-course-btn a {
    color: #fff !important;
    font-size: 20px;
    font-weight: 500
}

.home-five.trending-section-five .btn-area .all-course-btn i {
    color: #fff;
    margin-right: 5px;
    font-size: 20px;
    rotate: 180deg
}

.home-five.trending-section-five .btn-area .all-course-btn:hover {
    background: #4d82ff
}

@media (max-width:1400px) {
    .home-five.trending-section-five .trending-card .price-tag {
        top: 150px
    }

    .home-five.trending-section-five .trending-card .card-title {
        font-size: 20px !important
    }
}

@media (max-width:1200px) {
    .home-five.trending-section-five .trending-card .contents {
        padding: 20px 10px
    }

    .home-five.trending-section-five .trending-card .price-tag {
        top: 140px !important;
        width: 50px;
        padding: 5px 30px !important
    }

    .home-five.trending-section-five .trending-card .price-tag .dollars {
        font-size: 18px;
        margin-bottom: -6px
    }

    .home-five.trending-section-five .trending-card .price-tag .cents {
        font-size: 14px
    }

    .home-five.trending-section-five .trending-card .card-title {
        font-size: 16px !important
    }
}

@media (max-width:1400px) {
    .home-five.trending-section-five .trending-card .price-tag {
        top: 150px
    }
}

.home-five.instructor-section {
    position: relative
}

.home-five.instructor-section .instructor-side {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateY(20px) !important
}

.home-five.instructor-section .instructor-side .instructor-dp {
    max-width: 180px
}

.home-five.instructor-section .instructor-side .instructor-dp img {
    width: 100%
}

.home-five.instructor-section .instructor-side.left-side {
    transform: translateX(-100px)
}

.home-five.instructor-section .instructor-side.right-side {
    transform: translateX(100px)
}

.home-five.instructor-section .instructor-side .instructor-image-2 {
    transform: translateY(-30px) translateX(100px)
}

.home-five.instructor-section .instructor-side .instructor-image-4 {
    transform: translateY(-30px) translateX(-100px)
}

.home-five.instructor-section .contents {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
}

.home-five.instructor-section .contents .section-title-area {
    max-width: 500px;
    margin-bottom: 40px
}

.home-five.instructor-section .contents p {
    max-width: 450px;
    margin-bottom: 40px
}

.home-five.instructor-section .contents .free-lesson-btn {
    background: #3270fc;
    transition: background 300ms;
    padding: 15px 45px;
    border-radius: 5px;
    border: none
}

.home-five.instructor-section .contents .free-lesson-btn a {
    color: #fff !important;
    font-size: 20px;
    font-weight: 500
}

.home-five.instructor-section .contents .free-lesson-btn i {
    color: #fff;
    margin-right: 5px;
    font-size: 20px;
    rotate: 180deg
}

.home-five.instructor-section .contents .free-lesson-btn:hover {
    background: #4d82ff
}

@media (max-width:1200px) {
    .home-five.instructor-section .left-side {
        margin-bottom: 30px
    }

    .home-five.instructor-section .left-side .instructor-image-1 {
        transform: translateX(-50px)
    }

    .home-five.instructor-section .right-side {
        display: none
    }
}

@media (max-width:430px) {
    .home-five.instructor-section .instructor-side .instructor-dp {
        max-width: 120px !important
    }

    .home-five.instructor-section .left-side {
        margin-bottom: 20px
    }

    .home-five.instructor-section .left-side .instructor-image-2 {
        transform: translateY(-34px) translateX(50px) !important
    }
}

.home-five.testimonials-section {
    position: relative;
    background: url(../Images/home5/banner-bg.png) center no-repeat;
    background-size: cover
}

.home-five.testimonials-section .testimonials-card {
    background: #fff;
    max-width: 100%;
    padding: 0 150px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center
}

.home-five.testimonials-section .testimonials-card .quote-image {
    position: absolute;
    right: 50px;
    top: 50px;
    width: 100px
}

.home-five.testimonials-section .testimonials-card .quote-image img {
    max-width: 100%
}

.home-five.testimonials-section .testimonials-card .contents {
    padding-right: 50px
}

.home-five.testimonials-section .testimonials-card .contents .identity {
    display: flex;
    flex-direction: column
}

.home-five.testimonials-section .testimonials-card .contents .name {
    color: #252525;
    font-size: 22px;
    font-weight: 600;
    padding-bottom: 10px
}

.home-five.testimonials-section .testimonials-card .contents .status {
    color: #252525;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px
}

.home-five.testimonials-section .testimonials-card .contents p {
    color: #777
}

.home-five.testimonials-section .testimonials-card .card-image, .home-five.testimonials-section .testimonials-card .card-image img {
    width: 100%
}

.home-five.testimonials-section .testimonials-card .testimonials-rating i {
    color: #252525
}

.home-five.testimonials-section .testimonials-card .testimonials-rating .blank-star {
    color: #e5e5e5
}

.home-five.testimonials-section .testimonials-slider-navigation {
    width: 90%;
    position: absolute;
    top: 60%;
    left: 50%;
    display: flex;
    justify-content: space-between;
    transform: translate(-50%);
    z-index: 1
}

.home-five.testimonials-section .testimonials-slider-btn {
    width: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
    display: grid;
    place-items: center;
    border: 1px solid #000;
    position: relative
}

.home-five.testimonials-section .testimonials-slider-btn i {
    rotate: 180deg
}

.home-five.testimonials-section .testimonials-slider-btn:hover i {
    color: #3270fc !important
}

@media (max-width:1660px) {
    .home-five.testimonials-section .testimonials-slider-navigation {
        top: auto;
        bottom: 120px;
        width: 150px
    }
}

@media (max-width:576px) {
    .home-five.testimonials-section .testimonials-slider-navigation {
        bottom: 60px !important
    }
}

@media (max-width:1660px) {
    .home-five.testimonials-section {
        padding-bottom: 200px !important
    }

    .home-five.testimonials-section .testimonials-slider-navigation {
        top: auto;
        bottom: 120px;
        width: 150px
    }
}

@media (max-width:1400px) {
    .home-five.testimonials-section .card-image {
        width: 100%
    }

    .home-five.testimonials-section .card-image img {
        width: inherit
    }
}

@media (max-width:1200px) {
    .home-five.testimonials-section .card-image {
        width: 100%
    }

    .home-five.testimonials-section .card-image img {
        width: unset !important;
        max-width: unset
    }
}

@media (max-width:992px) {
    .home-five.testimonials-section .testimonials-card {
        flex-direction: column !important;
        padding-left: 0
    }

    .home-five.testimonials-section .testimonials-card .quote-image {
        display: none
    }

    .home-five.testimonials-section .testimonials-card .contents {
        padding: 20px
    }

    .home-five.testimonials-section .testimonials-card .card-image img {
        width: 100% !important
    }
}

@media (max-width:576px) {
    .home-five.testimonials-section {
        padding-bottom: 140px !important
    }

    .home-five.testimonials-section .testimonials-slider-navigation {
        bottom: 60px !important
    }
}

.home-five.blog-section {
    position: relative;
    margin-bottom: 120px
}

.home-five.blog-section .blog-top-area {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.home-five.blog-section .blog-card {
    position: relative;
    overflow: hidden !important
}

.home-five.blog-section .blog-card .card-image {
    overflow: hidden
}

.home-five.blog-section .blog-card .card-image:hover img {
    transform: scale(110%)
}

.home-five.blog-section .blog-card .card-image img {
    width: 100%;
    transition: transform 300ms
}

.home-five.blog-section .blog-card .time-tag {
    position: absolute;
    top: 260px;
    right: 30px;
    background: #3270fc;
    width: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1;
    padding: 7px;
    border-radius: 5px
}

.home-five.blog-section .blog-card .time-tag .day {
    color: #fff;
    font-size: 25px;
    font-weight: 600;
    line-height: 20px
}

.home-five.blog-section .blog-card .time-tag .month {
    color: #fff;
    font-size: 15px
}

.home-five.blog-section .blog-card .contents {
    padding-top: 30px
}

.home-five.blog-section .blog-card .contents .author {
    margin-bottom: 10px;
    color: #777;
    font-size: 16px
}

.home-five.blog-section .blog-card .contents .author i {
    color: #777;
    font-size: 14px;
    margin-right: 7px
}

.home-five.blog-section .blog-card .contents .card-title {
    font-size: 22px;
    font-weight: 600
}

.home-five.blog-section .blog-card .contents .card-title a {
    color: #252525
}

.home-five.blog-section .blog-slider-navigation {
    display: flex
}

.home-five.blog-section .blog-slider-navigation .blog-slider-btn {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    border: 1px solid #000;
    position: relative
}

.home-five.blog-section .blog-slider-navigation .blog-slider-btn i {
    rotate: 180deg
}

.home-five.blog-section .blog-slider-navigation .blog-slider-btn:hover i {
    color: #3270fc !important
}

.home-five.blog-section .blog-slider-navigation .blog-slider-next-btn {
    margin-right: 20px
}

@media (max-width:992px) {
    .home-five.blog-section {
        margin-bottom: 0 !important
    }

    .home-five.blog-section .contents {
        padding: 30px 0 0 !important
    }

    .home-five.blog-section .time-tag .day {
        font-size: 18px !important;
        line-height: 0
    }

    .home-five.blog-section .time-tag .month {
        font-size: 16px !important
    }
}

@media (max-width:460px) {
    .home-five.blog-section .blog-top-area {
        margin-bottom: 30px;
        flex-direction: column !important
    }

    .home-five.blog-section .blog-top-area .section-title-area {
        margin-bottom: 30px
    }

    .home-five.blog-section .time-tag .day {
        font-size: 18px !important;
        line-height: 0
    }

    .home-five.blog-section .time-tag .month {
        font-size: 16px !important
    }
}

@media (max-width:576px) {
    .home-five button {
        padding: 10px 20px !important;
        font-size: 16px !important
    }

    .home-five button i {
        font-size: 14px !important
    }
}

.home-five.footer-top-cta {
    background: #3270fc !important;
    padding: 75px 100px !important
}

.home-five.footer-top-cta h4 {
    font-weight: 500 !important
}

.home-five.footer-top-cta h3 {
    font-weight: 600 !important
}

.home-five.footer-top-cta a {
    color: #fff;
    border: 1px solid #fff !important
}

@media (max-width:1400px) {
    .home-five.footer-top-cta {
        padding: 50px 80px !important
    }
}

@media (max-width:769px) {
    .home-five.footer-top-cta {
        padding: 50px 30px !important
    }
}

@media (max-width:400px) {
    .home-five.footer-top-cta {
        padding: 50px 20px !important
    }
}

.react-menus-six li a {
    color: #4d5765 !important
}

.section-title-area-six .section-pretitle {
    color: #00306e;
    font-size: 20px;
    font-weight: 600;
    line-height: 15px !important;
    margin-bottom: 20px
}

.section-title-area-six .section-title {
    color: #00306e;
    font-size: 50px;
    font-weight: 700;
    line-height: 60px
}

@media (max-width:1200px) {
    .section-title-area-six .section-pretitle {
        font-size: 20px
    }

    .section-title-area-six .section-title {
        font-size: 40px
    }
}

@media (max-width:576px) {
    .section-title-area-six .section-pretitle {
        font-size: 16px;
        line-height: 0 !important
    }

    .section-title-area-six .section-title {
        font-size: 30px;
        line-height: 40px
    }
}

.home-six-btn {
    position: relative;
    background: #fff;
    border: none;
    padding: 15px 45px;
    border-radius: 0;
    overflow: hidden;
    z-index: 1
}

.home-six-btn a {
    color: #4d5765;
    position: relative;
    z-index: 1
}

.home-six-btn::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 8px;
    height: 102%;
    background: #d20936;
    transition: width 500ms;
    z-index: 0
}

.home-six-btn:hover {
    background: #fff
}

.home-six-btn:hover a, .home-six-btn:hover i {
    color: #fff
}

.home-six-btn:hover::before {
    width: 100%
}

.react-slider-part-six .slider-content .content-part .slider-pretitle {
    background: 0 0;
    padding: 0
}

.react-slider-part-six .slider-content .content-part .slider-title {
    margin-bottom: 50px
}

.react-slider-part-six .slider-content .content-part .event__video-btn--play-six {
    left: 55%;
    top: 93%
}

.react-slider-part-six .slider-content .content-part .event__video-btn--play-six .event__video-btn--play-btn-six {
    width: 60px;
    height: 60px;
    line-height: 0;
    position: relative !important
}

.react-slider-part-six .slider-content .content-part .event__video-btn--play-six .event__video-btn--play-btn-six .video-icon {
    width: 20px;
    position: absolute;
    bottom: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    rotate: 180deg
}

.react-slider-part-six .slider-content .content-part .event__video-btn--play-six .event__video-btn--play-btn-six .video-icon img {
    max-width: 100%
}

.react-slider-part-six .slider-content .content-part .event__video-btn--play-six .event__video-btn--play-btn-six em {
    margin-left: 55px !important;
    font-weight: 500 !important
}

.react-slider-part-six .slider-content .content-part .event__video-btn--play-six .event__video-btn--play-btn-six::before {
    top: -50% !important;
    left: -50% !important;
    transform: translate(-50%, -50%);
    animation: videoBtnAnim6 3s linear infinite !important
}

.react-slider-part-six .owl-nav [class*=owl-]::before {
    font-size: 70px !important;
    font-weight: 100 !important
}

.react-slider-part-six .owl-nav [class*=owl-]::before:hover::before {
    color: red !important
}

@media (max-width:1300px) {
    .event__video-btn--play-six {
        right: 50% !important
    }
}

@media (max-width:1200px) {
    .event__video-btn--play-six {
        right: 35% !important
    }
}

@media (max-width:992px) {
    .event__video-btn--play-six {
        display: block !important;
        right: 50% !important
    }
}

@media (max-width:588px) {
    .event__video-btn--play-six {
        right: unset;
        left: 45% !important
    }

    .event__video-btn--play-six em {
        display: none !important
    }
}

@media (max-width:588px) {
    .event__video-btn--play-six {
        left: 50% !important
    }
}

@media (max-width:396px) {
    .event__video-btn--play-six {
        left: 55% !important
    }
}

.about-section-six .fat-paragraph {
    color: #4d5765;
    font-weight: 600
}

.about-section-six .plain-paragraph {
    color: #4d5765;
    margin-bottom: 60px
}

.about-section-six .home-six-btn {
    border: 1px solid #f5f5f5
}

.about-section-six .home-six-btn:hover::before {
    width: 100%
}

@media (max-width:1200px) {
    .about-section-six .section-title-area-six {
        margin-bottom: 40px
    }
}

.about-section-six .about-features-wrapper {
    position: relative;
    height: 160px;
    width: 100%
}

.about-section-six .about-features-wrapper .about-features-six {
    position: absolute;
    top: -10px;
    left: 0;
    max-width: 100%;
    background: #fff;
    z-index: 20;
    padding: 40px 60px;
    box-shadow: 0 3px 30px rgba(0, 0, 0, .055);
    transform: translateY(140px)
}

.about-section-six .about-features-wrapper .about-features-six .about-feature-item .feature-icon {
    margin-bottom: 25px;
    transition: transform 500ms
}

.about-section-six .about-features-wrapper .about-features-six .about-feature-item .feature-title {
    color: #00306e;
    font-size: 22px;
    font-weight: 600
}

.about-section-six .about-features-wrapper .about-features-six .about-feature-item:hover .feature-icon {
    transform: translateY(-10px)
}

@media (max-width:1200px) {
    .about-section-six .about-features-wrapper {
        margin-top: 132px;
        height: unset !important;
        padding-bottom: -60px !important
    }

    .about-section-six .about-features-wrapper .about-features-six {
        position: relative !important;
        transform: translate(0) !important
    }

    .about-section-six .about-features-wrapper .about-feature-item {
        padding-bottom: 60px !important
    }
}

@media (max-width:576px) {
    .about-section-six .about-features-wrapper {
        margin-top: 82px
    }
}

.video_tour-section-six {
    background-image: url(../Images/home6/video-bg.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 270px 0;
    position: relative
}

.video_tour-section-six .tour__video-btn--play-six {
    position: relative;
    left: unset;
    transform: unset !important;
    margin-bottom: 40px
}

.video_tour-section-six .tour__video-btn--play-six img {
    position: relative;
    z-index: 1;
    rotate: 180deg
}

.video_tour-section-six .tour__video-btn--play-six a::before {
    background: #9f9891;
    border: none;
    z-index: -1
}

.video_tour-section-six::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(44, 44, 44, .425)
}

.video_tour-section-six .contents {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 2;
    position: relative
}

.video_tour-section-six .contents .video-play {
    transform: translateX(0);
    position: relative;
    left: 0;
    right: 0;
    margin-bottom: 40px
}

.video_tour-section-six .contents .video-play a::after {
    display: none !important
}

.video_tour-section-six .contents .video-title {
    color: #fff;
    font-size: 50px;
    font-weight: 600
}

.video_tour-section-six .contents p {
    color: #fff;
    font-size: 22px;
    max-width: 620px
}

@media (max-width:992px) {
    .video_tour-section-six .video-title {
        font-size: 30px !important
    }

    .video_tour-section-six p {
        font-size: 16px !important
    }
}

.academics-section-six .view-all {
    color: #999
}

.academics-section-six .view-all i {
    font-size: 14px;
    margin-right: 15px;
    rotate: 180deg
}

.academics-section-six .view-all:hover, .academics-section-six .view-all:hover i {
    color: #d20936
}

.academics-section-six .academics-cards-area {
    padding-bottom: 70px
}

.academics-section-six .academics-card .card-image {
    position: relative;
    margin-bottom: 25px;
    overflow: hidden
}

.academics-section-six .academics-card .card-image img {
    width: 100%;
    transition: transform 500ms
}

.academics-section-six .academics-card .card-image::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .712);
    opacity: 0;
    transition: 500ms
}

.academics-section-six .academics-card .card-title {
    transition: transform 500ms
}

.academics-section-six .academics-card .card-title a {
    color: #00306e;
    font-size: 26px;
    font-weight: 600
}

.academics-section-six .academics-card .card-title a:hover {
    color: #d20936
}

.academics-section-six .academics-card .learn-more {
    color: #4d5765;
    font-size: 14px;
    transition: transform 500ms
}

.academics-section-six .academics-card .learn-more i {
    margin-right: 10px;
    rotate: 180deg
}

.academics-section-six .academics-card .learn-more:hover, .academics-section-six .academics-card .learn-more:hover i {
    color: #d20936
}

.academics-section-six .academics-card:hover .card-image img {
    transform: scale(110%)
}

.academics-section-six .academics-card-2 {
    padding: 50px;
    border: 1px solid #f5f5f5
}

.academics-section-six .academics-card-2 .card-icon {
    margin-bottom: 40px
}

.academics-section-six .academics-card-2 .card-title a {
    color: #00306e;
    font-size: 22px;
    font-weight: 600
}

.academics-section-six .academics-card-2 .card-title a:hover {
    color: #d20936
}

.academics-section-six .academics-card-2 p {
    margin-bottom: 30px
}

.academics-section-six .academics-card-2 .learn-more {
    color: #4d5765
}

.academics-section-six .academics-card-2 .learn-more i {
    margin-right: 10px;
    rotate: 180deg
}

.academics-section-six .academics-card-2 .learn-more:hover, .academics-section-six .academics-card-2 .learn-more:hover i {
    color: #d20936
}

.academics-section-six .academics-card-2:hover .card-icon {
    animation: flip 1s
}

@media (max-width:1200px) {
    .academics-section-six {
        margin-bottom: -25px
    }

    .academics-section-six .academics-card {
        margin-bottom: 40px
    }

    .academics-section-six .academics-top-contents {
        margin-bottom: 40px !important
    }

    .academics-section-six .academics-card-2 {
        margin-bottom: 25px;
        padding: 30px;
        text-align: center
    }
}

@media (max-width:769px) {
    .academics-section-six .academics-cards-area {
        margin-bottom: -60px
    }

    .academics-section-six .academics-card {
        margin-bottom: 60px
    }
}

@media (max-width:576px) {
    .academics-section-six .academics-top-contents {
        margin-bottom: 40px !important
    }

    .academics-section-six .academics-card-2 {
        padding: 30px
    }
}

.campus-section-six .campus-picture {
    width: 100%;
    height: 100%
}

.campus-section-six .campus-picture img {
    width: 100%
}

.campus-section-six .contents {
    width: 100%;
    height: 36.6vw;
    overflow: hidden;
    background: #00306e;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 95px;
    padding-right: 30px
}

.campus-section-six .contents .campus-title {
    color: #fff;
    font-size: 50px;
    font-weight: 600
}

.campus-section-six .contents p {
    color: #f5f5f5;
    font-weight: 300;
    max-width: 550px
}

.campus-section-six .contents .campus-dropdown .campus-dropdown-item .dropdown-icon {
    width: 40px;
    aspect-ratio: 1;
    display: grid;
    place-items: center;
    border: 2px solid #fff;
    border-radius: 50%;
    margin-left: 20px;
    cursor: pointer;
    transition: 300ms
}

.campus-section-six .contents .campus-dropdown .campus-dropdown-item .dropdown-icon i {
    color: #fff;
    transition: transform 300ms;
    rotate: 180deg
}

.campus-section-six .contents .campus-dropdown .campus-dropdown-item .dropdown-icon:hover {
    background: #d20936;
    border: 2px solid #d20936
}

.campus-section-six .contents .campus-dropdown .campus-dropdown-item .showed {
    margin-bottom: 15px
}

.campus-section-six .contents .campus-dropdown .campus-dropdown-item .title {
    color: #fff;
    font-size: 16px;
    cursor: pointer
}

.campus-section-six .contents .campus-dropdown .campus-dropdown-item .hidden-content {
    height: 0;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-10px);
    overflow: hidden;
    transition: 300ms;
    margin-bottom: 20px
}

.campus-section-six .contents .campus-dropdown .campus-dropdown-item .hidden-content p {
    max-width: 450px
}

.campus-section-six .contents .campus-dropdown .campus-dropdown-item.active .dropdown-icon {
    background: #d20936;
    border: 2px solid #d20936
}

.campus-section-six .contents .campus-dropdown .campus-dropdown-item.active .dropdown-icon i {
    transform: rotate(90deg)
}

.campus-section-six .contents .campus-dropdown .campus-dropdown-item.active .hidden-content {
    height: 100px;
    visibility: visible;
    opacity: 1;
    transform: translateY(0)
}

.campus-section-six .contents .home-six-btn {
    max-width: 200px
}

.campus-section-six .contents .campus-btn {
    max-width: 170px
}

.campus-section-six .contents .campus-btn:hover {
    color: #fff
}

@media (max-width:1200px) {
    .campus-section-six .contents {
        height: unset;
        padding-top: 50px;
        padding-bottom: 50px
    }
}

.courses-section-six .coursesSlider-6 {
    padding: 20px
}

.courses-section-six .slider-area {
    padding: -20px
}

.courses-section-six .section-title-area-six {
    margin-bottom: 50px
}

.courses-section-six .course-card {
    position: relative;
    box-shadow: 0 5px 20px rgba(5, 5, 5, .05)
}

.courses-section-six .course-card .card-image img {
    width: 100%
}

.courses-section-six .course-card .price-tag {
    position: absolute;
    top: 200px;
    left: 20px;
    background: #d20936;
    color: #fff;
    font-weight: 600;
    width: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
    display: grid;
    place-items: center
}

.courses-section-six .course-card .card-inner {
    padding: 0 40px
}

.courses-section-six .course-card .top-info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 20px
}

.courses-section-six .course-card .top-info .lessons, .courses-section-six .course-card .top-info .subject {
    color: #4d5765
}

.courses-section-six .course-card .card-title a {
    color: #00306e;
    font-size: 20px;
    font-weight: 600
}

.courses-section-six .course-card .card-title:hover a, .courses-section-six .course-card .card-title:hover i {
    color: #d20936
}

.courses-section-six .course-card .status {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid #d9d9d9
}

.courses-section-six .course-card .status .hours, .courses-section-six .course-card .status .students {
    color: #4d5765
}

.courses-section-six .course-card .status .hours i, .courses-section-six .course-card .status .students i {
    margin-left: 15px
}

.courses-section-six .course-card .bottom-content {
    display: flex;
    justify-content: space-between
}

.courses-section-six .course-card .courses-rating-six {
    padding: 30px 0
}

.courses-section-six .courses-rating-six i {
    color: #ff912c
}

.courses-section-six .courses-rating-six .blank-star {
    color: #e5e5e5
}

.courses-section-six .learn-details a, .courses-section-six .learn-details i {
    color: #4d5765
}

.courses-section-six .learn-details i {
    margin-right: 5px;
    font-size: 13px;
    rotate: 180deg
}

.courses-section-six .learn-details:hover a, .courses-section-six .learn-details:hover i {
    color: #d20936
}

.courses-section-six .btn-area {
    width: 100%;
    text-align: center;
    margin-top: 50px
}

.courses-section-six .btn-area .browse-course-btn {
    border: 1px solid #f5f5f5
}

.courses-section-six .btn-area .browse-course-btn i {
    margin-right: 10px;
    font-size: 13px;
    color: #4d5765;
    rotate: 180deg
}

.courses-section-six .btn-area .browse-course-btn:hover::before {
    width: 100%
}

.courses-section-six .btn-area .browse-course-btn:hover i {
    position: relative;
    color: #fff;
    z-index: 1
}

@media (max-width:769px) {
    .courses-section-six .btn-area {
        margin-top: 20px
    }
}

.events-section-six {
    background-image: url(../Images/home6/events/events-bg.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat
}

.events-section-six .section-title-area-six .section-title {
    color: #fff
}

.events-section-six .event-card .event-card-picture {
    transition: transform 300ms;
    overflow: hidden
}

.events-section-six .event-card .event-card-picture img {
    width: 100% !important
}

.events-section-six .event-card:hover .event-card-picture {
    transform: scale(110%)
}

.events-section-six .contents .top-content {
    margin-top: 25px;
    display: flex;
    align-items: center
}

.events-section-six .contents .top-content .date {
    display: flex;
    flex-direction: column;
    margin-left: 30px
}

.events-section-six .contents .top-content .date .day {
    color: #fff;
    font-size: 30px;
    font-weight: 600;
    line-height: 25px
}

.events-section-six .contents .top-content .card-title a, .events-section-six .contents .top-content .date .month {
    color: #fff;
    font-size: 18px;
    font-weight: 500
}

.events-section-six .contents .top-content .card-title a:hover {
    color: #d20936
}

.events-section-six .contents .bottom-content {
    margin-top: 15px;
    position: relative;
    display: flex;
    align-items: center
}

.events-section-six .contents .bottom-content::before {
    content: '';
    position: absolute;
    top: 11px;
    right: 0;
    width: 30px;
    height: 4px;
    background: #d20936
}

.events-section-six .contents .bottom-content .event-location, .events-section-six .contents .bottom-content .event-time {
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    direction: ltr
}

.events-section-six .contents .bottom-content .event-location i, .events-section-six .contents .bottom-content .event-time i {
    margin-right: 5px
}

.events-section-six .contents .bottom-content .event-time {
    margin-left: 45px;
    padding-right: 60px
}

@media (max-width:1200px) {
    .events-section-six {
        padding-bottom: 60px !important
    }

    .events-section-six .event-card {
        padding-bottom: 60px
    }
}

@media (max-width:992px) {
    .events-section-six {
        padding-bottom: 30px !important
    }

    .events-section-six .event-card {
        padding-bottom: 40px
    }
}

@media (max-width:392px) {
    .events-section-six .bottom-content .event-location, .events-section-six .bottom-content .event-time {
        font-size: 11px !important
    }
}

.counter-section-six .counter-item {
    display: flex;
    flex-direction: column;
    align-items: center
}

.counter-section-six .counter-item .counter-icon {
    margin-bottom: 15px
}

.counter-section-six .counter-item .count span {
    font-size: 40px;
    line-height: 50px;
    font-weight: 600;
    color: #00306e;
    float: right
}

.counter-section-six .counter-item p {
    font-size: 14px;
    color: #888f98
}

@media (max-width:1200px) {
    .counter-section-six {
        margin-bottom: -60px
    }

    .counter-section-six .counter-item {
        margin-bottom: 60px
    }
}

@media (max-width:769px) {
    .counter-section-six {
        margin-bottom: -60px
    }

    .counter-section-six .counter-item {
        margin-bottom: 40px
    }
}

.blog-section-six .all-news {
    color: #999
}

.blog-section-six .all-news i {
    margin-right: 10px;
    font-size: 14px;
    rotate: 180deg
}

.blog-section-six .all-news:hover {
    color: #00306e
}

.blog-section-six .maximized-part .blog-picture {
    margin-bottom: 20px;
    overflow: hidden
}

.blog-section-six .maximized-part .blog-picture img {
    width: 100%;
    transition: transform 300ms
}

.blog-section-six .maximized-part .blog-picture img:hover {
    transform: scale(110%)
}

.blog-section-six .maximized-part .blog-date {
    color: #00306e;
    font-size: 16px;
    margin-bottom: 7px
}

.blog-section-six .maximized-part .blog-title {
    margin-bottom: 15px;
    max-width: 550px
}

.blog-section-six .maximized-part .blog-title a {
    color: #00306e;
    font-size: 22px;
    font-weight: 600;
    line-height: 40px
}

.blog-section-six .maximized-part .blog-title a:hover {
    color: #d20936
}

.blog-section-six .maximized-part .read-more {
    color: #4d5765;
    font-size: 16px
}

.blog-section-six .maximized-part .read-more i {
    font-size: 12px;
    margin-right: 5px;
    rotate: 180deg
}

.blog-section-six .maximized-part .read-more:hover, .blog-section-six .maximized-part .read-more:hover i {
    color: #d20936
}

.blog-section-six .minimized-part .blog-item {
    display: flex;
    justify-content: start;
    margin-bottom: 20px
}

.blog-section-six .minimized-part .blog-item .blog-picture {
    width: 200px;
    margin-left: 30px;
    overflow: hidden
}

.blog-section-six .minimized-part .blog-item .blog-picture img {
    max-width: 100%;
    transition: transform 300ms
}

.blog-section-six .minimized-part .blog-item .blog-picture img:hover {
    transform: scale(110%)
}

.blog-section-six .minimized-part .blog-item .content .blog-date {
    color: #00306e;
    font-size: 16px;
    margin-bottom: 10px
}

.blog-section-six .minimized-part .blog-item .content .blog-title {
    margin-bottom: 10px;
    max-width: 450px
}

.blog-section-six .minimized-part .blog-item .content .blog-title a {
    color: #00306e;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px
}

.blog-section-six .minimized-part .blog-item .content .blog-title a:hover {
    color: #d20936
}

.blog-section-six .minimized-part .blog-item .content .read-more {
    color: #4d5765;
    font-size: 16px;
    margin-bottom: 0 !important
}

.blog-section-six .minimized-part .blog-item .content .read-more i {
    font-size: 12px;
    margin-left: 5px
}

.blog-section-six .section-title {
    width: 100%
}

@media (max-width:769px) {
    .blog-section-six .maximized-part {
        margin-bottom: 40px
    }

    .blog-section-six .maximized-part .blog-picture {
        margin-bottom: 20px
    }

    .blog-section-six .maximized-part .blog-picture img {
        max-width: 100%
    }

    .blog-section-six .maximized-part .blog-title {
        margin-bottom: 20px
    }

    .blog-section-six .maximized-part .blog-title a {
        font-size: 23px;
        min-width: 320px
    }

    .blog-section-six .maximized-part .blog-date {
        margin-bottom: 20px
    }

    .blog-section-six .maximized-part .read-more {
        font-size: 16px
    }

    .blog-section-six .minimized-part .content .blog-picture {
        margin-bottom: 20px
    }

    .blog-section-six .minimized-part .content .blog-picture img {
        max-width: 100%
    }

    .blog-section-six .minimized-part .content .blog-title a {
        font-size: 17px !important
    }

    .blog-section-six .minimized-part .content .blog-date {
        margin-bottom: 20px
    }

    .blog-section-six .minimized-part .content .read-more {
        font-size: 16px
    }
}

@media (max-width:358px) {
    .blog-section-six .section-title {
        font-size: 30px
    }
}

.blog-section-six .news-form-area {
    background: #f5f5f5;
    padding: 50px 45px
}

.blog-section-six .news-form .heading {
    color: #00306e;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 35px
}

.blog-section-six .news-form .input-area {
    display: flex;
    flex-direction: column
}

.blog-section-six .news-form .input-area input {
    margin-bottom: 30px;
    padding: 10px;
    font-size: 14px;
    background: #fff;
    border: none;
    outline: #00306e
}

.blog-section-six .news-form .input-area input::placeholder {
    color: #999
}

.blog-section-six .news-form .news-signup-btn {
    color: #fff;
    font-size: 16px;
    padding: 10px 0;
    width: 100%;
    background: #00306e;
    border: none;
    margin: 0 auto;
    transition: background 300ms
}

.blog-section-six .news-form .news-signup-btn:hover {
    background: #1451a1
}

@media (max-width:1200px) {
    .blog-section-six .blog-top-contents {
        margin-bottom: 60px !important
    }

    .blog-section-six .news-form-area {
        margin-top: 50px
    }
}

@media (max-width:769px) {
    .blog-section-six .blog-top-contents {
        margin-bottom: 30px !important
    }

    .blog-section-six .news-form-area {
        margin-top: 30px
    }
}

@media (max-width:480px) {
    .blog-section-six .news-form-area {
        padding: 30px 15px !important
    }
}

.testimonials-section-six {
    position: relative
}

.testimonials-section-six .testimonialsSlider6 .testimonials-card {
    background: 0 0 !important;
    border: 1px solid #ececec !important;
    transition: background 500ms
}

.testimonials-section-six .testimonialsSlider6 .testimonials-card .name {
    color: #00306e !important
}

.testimonials-section-six .testimonials-card:hover, .testimonials-section-six .testimonialsSlider6 .swiper-slide-active .testimonials-card {
    background: #fff !important;
    border: 1px solid transparent !important
}

.testimonials-section-six .testimonials-slider-navigation {
    width: 83%;
    position: absolute;
    top: 60%;
    left: 50%;
    display: flex;
    justify-content: space-between;
    transform: translate(-50%);
    z-index: 1
}

.testimonials-section-six .testimonials-slider-btn {
    position: relative
}

.testimonials-section-six .testimonials-slider-btn i {
    font-size: 35px;
    rotate: 180deg
}

.testimonials-section-six .testimonials-slider-btn:hover i {
    color: #3270fc !important
}

.testimonials-section-six .testimonials-slider-navigation i:hover {
    color: #d20936 !important
}

@media (max-width:1660px) {
    .testimonials-section-six .testimonials-slider-navigation {
        top: auto;
        bottom: 114px;
        width: 150px
    }
}

@media (max-width:768px) {
    .testimonials-section-six .testimonials-card {
        max-width: 100% !important
    }
}

@media (max-width:576px) {
    .testimonials-section-six {
        padding-bottom: 80px !important
    }

    .testimonials-section-six .testimonials-slider-navigation {
        width: 100px;
        bottom: 54px !important
    }

    .testimonials-section-six .testimonials-slider-navigation i {
        font-size: 20px
    }
}

.mfp-fade.mfp-bg {
    opacity: 0;
    -webkit-transition: all .15s ease-out;
    -moz-transition: all .15s ease-out;
    transition: all .15s ease-out
}

.mfp-fade.mfp-bg.mfp-ready {
    opacity: .8
}

.mfp-fade.mfp-bg.mfp-removing {
    opacity: 0
}

.mfp-fade.mfp-wrap .mfp-content {
    opacity: 0;
    -webkit-transition: all .15s ease-out;
    -moz-transition: all .15s ease-out;
    transition: all .15s ease-out
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
    opacity: 1
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
    opacity: 0
}

.gallery-secton-six .item img {
    width: 100%
}

.gallery-secton-six .owl-nav {
    display: none
}

.mfp-image-holder {
    background: rgba(0, 0, 0, .308);
    backdrop-filter: blur(5px)
}

.hidden {
    overflow: hidden;
    display: none;
    visibility: hidden
}

.btn-gallery img {
    z-index: 10;
    height: auto;
    cursor: zoom-in
}

.btn-gallery li {
    position: relative
}

.btn-gallery a i {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    color: #333;
    font-size: 20px
}

.btn-gallery a::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    height: 80%;
    background: rgba(221, 221, 221, .719);
    backdrop-filter: blur(2px);
    visibility: hidden;
    transform: translate(-50%, -50%) scale(80%);
    opacity: 0;
    transition: 400ms
}

.btn-gallery a:hover i {
    display: block
}

.btn-gallery a:hover::before {
    visibility: visible;
    opacity: 1;
    transform: translate(-50%, -50%) scale(100%)
}

.overview-section-six .overview-navigation {
    position: relative;
    background: #f5f5f5;
    padding: 50px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center
}

.overview-section-six .overview-navigation .navigation-btn {
    cursor: pointer;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #d8d8d6;
    color: #00306e;
    font-size: 22px;
    font-weight: 600
}

.overview-section-six .overview-navigation .navigation-btn:last-child {
    border: none;
    padding-bottom: 0;
    margin-bottom: 0
}

.overview-section-six .overview-navigation .navigation-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: -65px;
    transform: rotate(45deg);
    width: 35px;
    height: 35px;
    background: #f5f5f5;
    display: none
}

.overview-section-six .overview-navigation .navigation-btn.active {
    position: relative;
    color: #d20936
}

.overview-section-six .overview-navigation .navigation-btn.active::before {
    display: block
}

.overview-section-six .item {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
    transition: 500ms
}

.overview-section-six .hide {
    visibility: hidden;
    opacity: 0;
    transform: translateX(-100px);
    position: absolute;
    transition: none
}

.overview-section-six .hide a {
    display: none !important
}

.overview-section-six .content-picture {
    max-width: 410px;
    margin-left: 40px;
    display: flex;
    justify-content: center
}

.overview-section-six .content-picture img {
    width: 100%
}

.overview-section-six .content .title {
    color: #00306e;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 30px
}

.overview-section-six .content p {
    max-width: 425px;
    color: #4d5765
}

.overview-section-six .content p.oneline {
    margin-top: 40px;
    margin-bottom: 50px
}

.overview-section-six .content .read-more {
    color: #4d5765
}

.overview-section-six .content .read-more i {
    color: #4d5765;
    font-size: 13px;
    margin-right: 5px;
    rotate: 180deg
}

.overview-section-six .content .read-more:hover, .overview-section-six .content .read-more:hover i {
    color: #00306e
}

@media (max-width:1400px) {
    .overview-section-six .overview-navigation .navigation-btn {
        font-size: 18px
    }

    .overview-section-six .overview-navigation .navigation-btn::before {
        width: 25px;
        height: 25px;
        right: -60px
    }
}

@media (max-width:1200px) {
    .overview-section-six .overview-navigation {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 10px 30px !important;
        margin-bottom: 40px !important
    }

    .overview-section-six .overview-navigation .navigation-btn {
        margin: 0;
        padding: 0 50px 0 0;
        border-bottom: none;
        border-right: 1px solid #d8d8d6;
        font-size: 18px
    }

    .overview-section-six .overview-navigation .navigation-btn::before {
        width: 25px;
        height: 25px;
        top: 45px;
        right: unset;
        left: 30%
    }

    .overview-section-six .overview-contents {
        margin-top: 40px
    }

    .overview-section-six .overview-contents .item {
        justify-content: unset !important
    }
}

@media (max-width:992px) {
    .overview-section-six .overview-navigation {
        padding: 15px !important
    }

    .overview-section-six .overview-navigation .navigation-btn {
        border: none;
        font-size: 14px
    }
}

@media (max-width:769px) {
    .overview-section-six .overview-navigation {
        flex-wrap: wrap;
        justify-content: center
    }

    .overview-section-six .overview-navigation .navigation-btn {
        font-size: 16px;
        padding: 0 20px
    }

    .overview-section-six .overview-navigation .navigation-btn::before {
        display: none !important
    }

    .overview-section-six .overview-contents .item {
        display: flex;
        flex-direction: column
    }

    .overview-section-six .overview-contents .item .content-picture {
        margin-bottom: 30px;
        width: 100%;
        max-width: 100%;
        margin-right: 0
    }
}

@media (max-width:992px) {
    .topbar-area-seven {
        display: none
    }
}

.react-header-seven .react-main-menu {
    padding: 25px 0
}

.react-header-seven .react-menus-seven li a {
    color: #252525
}

.react-header-seven .react-menus-seven li a:hover {
    color: #316ffb !important
}

@media (max-width:1250px) {
    .react-header-seven .react-menus-seven li a {
        padding: 32px 30px
    }
}

@media (max-width:1090px) {
    .react-header-seven .react-menus-seven li a {
        padding: 32px 17px
    }
}

.react-header-seven .searchbar-part .search-form {
    background: 0 0;
    border: 1px solid #e5e5e5;
    border-radius: 50px
}

.react-header-seven .searchbar-part .search-form svg {
    stroke: #9b9b9b
}

.react-header-seven .searchbar-part .react-login {
    margin-right: 30px;
    border: none
}

.react-header-seven .searchbar-part .react-login a {
    color: #252525
}

.react-header-seven .searchbar-part .react-login a svg {
    margin-right: 20px
}

.react-header-seven .searchbar-part .react-login:hover a {
    color: #316ffb
}

@media (max-width:1200px) {
    .react-header-seven .searchbar-part .react-login {
        padding: 0
    }

    .react-header-seven .searchbar-part .react-login a {
        font-size: 13px
    }

    .react-header-seven .searchbar-part .react-login a svg {
        margin-right: 5px
    }
}

@media (max-width:1150px) {
    .react-header-seven .searchbar-part .react-login {
        padding: 0
    }
}

.react-header-seven .react-logins {
    border: none;
    padding-left: 20px
}

.react-header-seven .react-logins a {
    background: #3270FC;
    border-radius: 6px;
    padding: 10px 26px;
    line-height: 20px;
    display: inline-block;
    color: #fff;
    font-weight: 600;
    font-size: 16px
}

.react-header-seven .react-logins a svg {
    width: 18px
}

.react-header-seven .react-logins a:hover {
    opacity: .9
}

.home-seven-btn {
    background: #316ffb;
    border: none;
    padding: 15px 40px;
    border-radius: 5px;
    transition: background 300ms
}

.home-seven-btn a {
    color: #fff;
    font-size: 20px
}

.home-seven-btn:hover {
    background: #4a80ff !important
}

.home-sliders.home7 {
    margin-bottom: 105px
}

@media (max-width:576px) {
    .home-sliders.home7 {
        margin-bottom: 50px
    }
}

.home-sliders.home7 .single-slide {
    position: relative;
    padding: 20px 0
}

.home-sliders.home7 .single-slide .slider-content {
    position: relative;
    transform: none;
    z-index: 1;
    left: 215%
}

.home-sliders.home7 .single-slide .slider-content .content-part .slider-title {
    font-size: 80px;
    width: 700px;
    color: #252525
}

.home-sliders.home7 .single-slide .slider-content .content-part .slider-title::before {
    content: '';
    position: absolute;
    top: 0;
    left: 65px;
    width: 100%;
    height: 90px;
    background: #fff;
    z-index: -1
}

.home-sliders.home7 .single-slide .slider-content .content-part .slider-title::after {
    content: '';
    position: absolute;
    top: 70px;
    left: 170px;
    width: 70%;
    height: 100px;
    background: #fff;
    z-index: -1
}

.home-sliders.home7 .single-slide .slider-content .content-part .slider-btn {
    right: 40% !important
}

.home-sliders.home7 .single-slide .slider-content .content-part .slider-btn .home-seven-btn {
    background: #316ffb;
    border: none;
    padding: 20px 40px;
    border-radius: 50px
}

.home-sliders.home7 .single-slide .slider-content .content-part .slider-btn .home-seven-btn a {
    color: #fff;
    font-size: 20px
}

.home-sliders.home7 .single-slide .slider-content .content-part .event__video-btn--play-six {
    top: 55%;
    right: 55%
}

.home-sliders.home7 .single-slide .slider-content .content-part .event__video-btn--play-btn {
    background: 0 0;
    border: 1px solid #e2e2e2
}

.home-sliders.home7 .single-slide .slider-content .content-part .event__video-btn--play-btn::before {
    background: #f1f1f1;
    top: -53% !important;
    right: -53% !important
}

.home-sliders.home7 .single-slide .slider-content .content-part .event__video-btn--play-btn .video-icon {
    max-width: 15px
}

.home-sliders.home7 .single-slide .slider-content .content-part .event__video-btn--play-btn em {
    color: #999;
    font-size: 20px;
    font-weight: 700;
    margin-top: 15px;
    margin-right: 55px
}

.home-sliders.home7 .single-slide .slider-content .slide-info {
    margin-top: 100px;
    font-size: 40px;
    color: #252525;
    font-weight: 300;
    position: relative;
    text-align: right;
    width: 200px
}

.home-sliders.home7 .single-slide .slider-content .slide-info::before {
    content: '';
    position: absolute;
    top: 35px;
    left: -8%;
    width: 80px;
    height: 1px;
    background: #252525
}

.home-sliders.home7 .single-slide .slider-hero {
    padding: 370px 0
}

.home-sliders.home7 .single-slide .slider-hero .hero-picture {
    position: absolute;
    top: 0;
    left: 0
}

.home-sliders.home7 .owl-nav {
    width: 150px;
    top: 72%;
    right: unset !important;
    left: 63%;
    display: flex;
    justify-content: space-between;
    align-items: center
}

.home-sliders.home7 .owl-nav button {
    position: relative;
    width: 50px;
    height: 50px;
    left: unset !important;
    right: unset !important;
    display: grid;
    place-items: center;
    aspect-ratio: 1;
    background: #fff !important;
    border-radius: 50%
}

.home-sliders.home7 .owl-nav button::before {
    color: #252525;
    font-size: 40px !important;
    font-weight: 100 !important
}

.home-sliders.home7 .owl-prev::before {
    transform: rotate(180deg) !important;
    left: 15% !important
}

.home-sliders.home7 .owl-prev::after {
    content: '';
    position: absolute;
    top: 12px;
    right: 146%;
    width: 2px;
    height: 30px;
    background: #252525
}

.home-sliders.home7 .owl-next::before {
    transform: rotate(180deg) !important;
    left: 15% !important
}

@media (max-width:1666px) {
    .home-sliders.home7 .event__video-btn--play-btn em {
        display: none
    }

    .home-sliders.home7 .owl-nav button {
        box-shadow: 5px 5px 12px -6px rgba(0, 0, 0, .29)
    }
}

@media (max-width:1400px) {
    .home-sliders.home7 .event__video-btn--play-six {
        top: 90% !important;
        right: 0 !important
    }

    .home-sliders.home7 .owl-nav {
        top: 75%;
        left: 50% !important;
        right: unset !important;
        transform: translateX(-50%) !important
    }

    .home-sliders.home7 .slide-info {
        display: none
    }
}

@media (max-width:1200px) {
    .home-sliders.home7 .content-part {
        padding: 20px;
        background: #fff
    }

    .home-sliders.home7 .content-part .slider-title {
        line-height: 73px
    }

    .home-sliders.home7 .content-part .slider-title::before {
        right: 50px !important
    }

    .home-sliders.home7 .content-part .slider-title::after {
        right: 150px !important
    }

    .home-sliders.home7 .slider-content {
        position: absolute !important;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important
    }

    .home-sliders.home7 .event__video-btn--play-six {
        top: 83% !important
    }

    .home-sliders.home7 .content-part .slider-title::after, .home-sliders.home7 .content-part .slider-title::before {
        display: none
    }
}

@media (max-width:992px) {
    .home-sliders.home7 .react-slider-row-seven {
        flex-direction: column-reverse
    }

    .home-sliders.home7 .slider-content {
        position: relative !important;
        top: inherit !important;
        left: inherit !important;
        transform: translate(0) !important
    }

    .home-sliders.home7 .slider-content .content-part {
        padding: 0
    }

    .home-sliders.home7 .owl-nav {
        top: auto;
        bottom: 20px
    }

    .home-sliders.home7 .slider-hero {
        padding: 0 !important;
        margin-bottom: 40px
    }

    .home-sliders.home7 .slider-hero .hero-picture {
        position: relative !important
    }

    .home-sliders.home7 .navigation-spacer {
        width: 100%;
        height: 100px
    }

    .home-sliders.home7 .owl-prev::after {
        left: 151%
    }
}

@media (max-width:768px) {
    .home-sliders.home7 .content-part .slider-title {
        font-size: 50px !important;
        line-height: 1.2;
        max-width: 400px
    }

    .home-sliders.home7 .slider-hero {
        margin-bottom: 25px
    }
}

@media (max-width:588px) {
    .home-sliders.home7 .event__video-btn--play-six {
        top: 83% !important;
        left: unset !important
    }

    .home-sliders.home7 .content-part .slider-title {
        font-size: 40px !important;
        max-width: 320px
    }
}

@media (max-width:350px) {
    .home-sliders.home7 .content-part .slider-title {
        font-size: 35px !important;
        max-width: 320px
    }
}

.section-title-area-seven .section-pretitle {
    color: #252525;
    font-size: 20px;
    font-weight: 600;
    line-height: 15px !important;
    position: relative;
    padding-right: 60px;
    margin-bottom: 40px
}

.section-title-area-seven .section-pretitle::before {
    content: '';
    position: absolute;
    top: 7px;
    right: 0;
    width: 40px;
    height: 2px;
    background: #316ffb
}

.section-title-area-seven .section-title {
    color: #252525;
    font-size: 50px;
    font-weight: 700;
    line-height: 60px;
    transform: translateY(-10px)
}

@media (max-width:1200px) {
    .section-title-area-seven .section-pretitle {
        font-size: 20px
    }

    .section-title-area-seven .section-title {
        font-size: 40px
    }
}

@media (max-width:576px) {
    .section-title-area-seven {
        margin-bottom: 30px !important
    }

    .section-title-area-seven .section-pretitle {
        font-size: 16px;
        line-height: 0 !important
    }

    .section-title-area-seven .section-title {
        font-size: 30px;
        line-height: 40px;
        transform: translateY(-20px)
    }
}

.about-section-seven {
    margin-bottom: -25px
}

.about-section-seven .section-title-area-seven {
    margin-bottom: 80px
}

.about-section-seven .about-card {
    background: #fff;
    border: 1px solid #f0f0f0;
    border-radius: 10px;
    padding: 40px;
    display: flex;
    margin-bottom: 25px
}

.about-section-seven .about-card:hover .card-icon {
    animation: flip 1s ease
}

.about-section-seven .about-card .card-icon {
    width: 260px;
    aspect-ratio: 1;
    margin-left: 30px
}

.about-section-seven .about-card .card-icon img {
    width: 100%
}

.about-section-seven .about-card .content .card-title {
    color: #252525;
    font-size: 22px;
    font-weight: 600
}

.about-section-seven .about-card .content p {
    color: #777;
    margin-bottom: 0
}

@media (max-width:1200px) {
    .about-section-seven .about-card .card-icon {
        max-width: 100px !important;
        display: flex;
        align-items: center
    }
}

@media (max-width:576px) {
    .about-section-seven .about-card {
        flex-direction: column;
        align-items: center;
        text-align: center
    }

    .about-section-seven .about-card p {
        max-width: 400px
    }

    .about-section-seven .about-card .card-icon {
        margin-right: 0;
        margin-bottom: 20px
    }
}

.qna-section-seven .section-title-area-seven {
    margin-bottom: 40px
}

.qna-section-seven .section-title-area-seven .section-pretitle {
    position: relative;
    padding-right: 60px
}

.qna-section-seven .section-title-area-seven .section-pretitle::before {
    content: '';
    position: absolute;
    top: 7px;
    right: 0;
    width: 40px;
    height: 2px;
    background: #316ffb
}

.qna-section-seven .qna-dropdown-item {
    margin-bottom: 20px
}

.qna-section-seven .qna-dropdown-item .question {
    display: flex;
    align-items: center;
    margin-bottom: 20px
}

.qna-section-seven .qna-dropdown-item .question .question-title {
    color: #252525;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer
}

.qna-section-seven .qna-dropdown-item .question .dropdown-icon {
    background: #ffd6d6;
    width: 20px;
    height: 20px;
    display: grid;
    place-items: center;
    border-radius: 3px;
    margin-left: 30px
}

.qna-section-seven .qna-dropdown-item .question .dropdown-icon::before {
    content: "+";
    color: #f44;
    transform: translateY(-7px);
    font-size: 19px;
    font-weight: 600;
    cursor: pointer
}

.qna-section-seven .qna-dropdown-item .answer {
    height: 0;
    overflow: hidden;
    transition: height 300ms
}

.qna-section-seven .qna-dropdown-item .answer p {
    margin-bottom: 0;
    color: #777;
    max-width: 550px
}

.qna-section-seven .qna-dropdown-item.show .dropdown-icon {
    background: #e2f8f9
}

.qna-section-seven .qna-dropdown-item.show .dropdown-icon::before {
    content: "-";
    font-size: 30px;
    color: #3ccbde;
    transform: translateY(-18px)
}

.qna-section-seven .qna-dropdown-item.show .question-title {
    color: #316ffb
}

.qna-section-seven .qna-dropdown-item.show .answer {
    height: 60px
}

@media (max-width:1400px) {
    .qna-section-seven .qna-picture {
        margin-right: 40px
    }
}

@media (max-width:1200px) {
    .qna-section-seven .qna-picture {
        margin-right: 0;
        margin-bottom: 40px
    }

    .qna-section-seven .qna-picture img {
        width: 100%
    }
}

.courses-section-seven .filters {
    margin-bottom: 70px
}

.courses-section-seven .filter-group {
    width: 100%;
    display: flex;
    justify-content: center
}

.courses-section-seven .filter-group li {
    padding: 10px 25px;
    background: #fff;
    color: #777;
    border-radius: 4px;
    margin-right: 40px;
    cursor: pointer;
    transition: background 300ms
}

.courses-section-seven .filter-group li:last-child {
    margin-right: 0
}

.courses-section-seven .filter-group li.active {
    background: #316ffb;
    color: #fff
}

.courses-section-seven .content {
    margin-bottom: -40px
}

.courses-section-seven .single-content {
    width: 33.3333%;
    display: flex;
    justify-content: center
}

.courses-section-seven .courses-card {
    max-width: 400px;
    position: relative;
    background: #fff;
    border-radius: 10px;
    margin-bottom: 40px
}

.courses-section-seven .courses-card .card-image img {
    width: 100%
}

.courses-section-seven .courses-card .card-tag {
    position: absolute;
    background: #316ffb;
    color: #fff;
    padding: 5px 20px;
    border-radius: 5px;
    top: 55%;
    right: 30px
}

.courses-section-seven .courses-card .card-contents {
    padding: 30px
}

.courses-section-seven .courses-card .card-value {
    margin-bottom: 20px
}

.courses-section-seven .courses-card .courses-rating-seven .star span {
    margin-left: 20px;
    color: #777
}

.courses-section-seven .courses-card .price {
    color: #316ffb;
    font-weight: 600
}

.courses-section-seven .courses-card .card-title a {
    color: #252525;
    font-size: 22px;
    font-weight: 600
}

.courses-section-seven .courses-card .card-title a:hover {
    color: #316ffb
}

.courses-section-seven .courses-rating-seven i {
    color: #ff912c
}

.courses-section-seven .courses-rating-seven .blank-star {
    color: #e5e5e5
}

@media (max-width:1400px) {
    .courses-section-seven .courses-card {
        max-width: 350px;
        margin-bottom: 22px
    }

    .courses-section-seven .courses-card .card-tag {
        top: 45%
    }

    .courses-section-seven .courses-card .card-contents .card-title a {
        font-size: 20px
    }
}

@media (max-width:1200px) {
    .courses-section-seven .courses-card {
        max-width: 290px;
        margin-bottom: 23px
    }

    .courses-section-seven .courses-card .card-contents .card-title a {
        font-size: 16px
    }
}

@media (max-width:992px) {
    .courses-section-seven .single-content {
        width: 100% !important
    }

    .courses-section-seven .courses-card {
        max-width: 100%;
        width: 100%;
        margin-bottom: 40px
    }

    .courses-section-seven .courses-card .card-tag {
        top: unset;
        bottom: 20%
    }

    .courses-section-seven .courses-card .card-contents .card-title a {
        font-size: 16px
    }

    .courses-section-seven .filter-group li {
        font-size: 16px;
        padding: 7px 10px
    }
}

@media (max-width:769px) {
    .courses-section-seven .filters {
        margin-bottom: 40px
    }

    .courses-section-seven .filter-group {
        flex-wrap: wrap;
        margin-bottom: -15px
    }

    .courses-section-seven .filter-group li {
        font-size: 16px;
        padding: 7px 10px;
        margin-right: 15px;
        margin-bottom: 15px
    }

    .courses-section-seven .card-tag {
        bottom: 40% !important
    }
}

.counter-section-seven .counter-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center
}

.counter-section-seven .counter-item .count {
    border: 1px solid #f1f1f1;
    border-radius: 50%;
    width: 150px;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px
}

.counter-section-seven .counter-item span {
    color: #252525;
    font-size: 40px;
    font-weight: 600
}

.counter-section-seven .counter-item .counter-title {
    text-align: center;
    color: #777;
    font-size: 18px;
    font-weight: 500
}

@media (max-width:1200px) {
    .counter-section-seven {
        margin-bottom: -40px
    }

    .counter-section-seven .counter-item {
        margin-bottom: 40px
    }
}

.overview-section-seven {
    position: relative;
    margin-bottom: -40px
}

.overview-section-seven .contents {
    max-width: 550px
}

.overview-section-seven .contents .section-title {
    margin-bottom: 40px
}

.overview-section-seven .contents p {
    color: #777;
    margin-bottom: 70px
}

.overview-section-seven .overview-pictures .image-row {
    column-count: 2;
    column-gap: 40px
}

.overview-section-seven .overview-pictures .image-item {
    width: 100%;
    height: auto;
    margin-bottom: 40px
}

@media (max-width:1200px) {
    .overview-section-seven .contents {
        margin-bottom: 50px
    }

    .overview-section-seven .overview-pictures .image-item img {
        width: 100%
    }
}

@media (max-width:769px) {
    .overview-section-seven .overview-pictures .image-row {
        column-gap: 20px
    }

    .overview-section-seven .overview-pictures .image-item {
        margin-bottom: 20px
    }
}

@media (max-width:576px) {
    .overview-section-seven {
        margin-bottom: -20px !important
    }
}

.testimonials-section-six.home-seven .testimonials-slider-navigation i:hover {
    color: #316ffb !important
}

.blog-section-seven .blog-picture {
    margin-bottom: 20px;
    overflow: hidden
}

.blog-section-seven .blog-picture img {
    width: 100%;
    transition: transform 300ms
}

.blog-section-seven .blog-picture img:hover {
    transform: scale(110%)
}

.blog-section-seven .tag {
    position: absolute;
    top: 20px;
    right: 20px;
    background: #316ffb;
    padding: 5px 15px;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    border-radius: 3px
}

.blog-section-seven .blog-date {
    color: #00306e;
    font-size: 16px;
    margin-bottom: 7px;
    text-align: end;
    direction: ltr
}

.blog-section-seven .blog-title {
    margin-bottom: 15px;
    max-width: 550px
}

.blog-section-seven .blog-title a {
    color: #00306e;
    font-size: 22px;
    font-weight: 600;
    line-height: 40px
}

.blog-section-seven .blog-title a:hover {
    color: #316ffb
}

.blog-section-seven .read-more {
    color: #4d5765;
    font-size: 16px
}

.blog-section-seven .read-more i {
    font-size: 12px;
    margin-left: 5px
}

.blog-section-seven .read-more:hover, .blog-section-seven .read-more:hover i {
    color: #d20936
}

@keyframes flip {
    0% {
        transform: rotateX(0)
    }

    100% {
        transform: rotateX(360deg)
    }
}

@-webkit-keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@-webkit-keyframes slide-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    100% {
        -webkit-transform: translateY(-100px);
        transform: translateY(-100px)
    }
}

@keyframes shake-that {
    0% {
        transform: translate(0)
    }

    50% {
        transform: translateY(-10px)
    }

    100% {
        transform: translate(0)
    }
}

.hero4__area {
    overflow: hidden;
    position: relative;
    background: url(../Images/hero/01.png) center top no-repeat;
    background-size: cover
}

.hero4__area .container.hero4__width {
    max-width: 1200px
}

.hero4__area .container.hero4__width .row {
    align-items: center
}

.hero4__area .hero4__content span {
    font-size: 24px;
    font-weight: 700;
    color: #fd4680
}

.hero4__area .hero4__content h1 {
    font-size: 70px;
    line-height: 74px;
    font-weight: 700;
    color: #fff;
    padding-bottom: 0;
    margin: 0 0 10px
}

.hero4__area .event__video-btn--plays {
    position: relative;
    z-index: 1
}

.hero4__area .event__video-btn--plays .event__videos {
    position: relative;
    top: 7px
}

.hero4__area .event__video-btn--plays i {
    width: 50px;
    height: 50px;
    line-height: 50px;
    color: #fff;
    border: 2px solid rgba(255, 255, 255, .2);
    display: inline-block;
    text-align: center;
    border-radius: 50px;
    font-size: 28px;
    transition: all .5s ease;
    rotate: 180deg
}

.hero4__area .event__video-btn--plays i:hover {
    border-color: #3270fc
}

.hero4__area .event__video-btn--plays em {
    color: #fff;
    line-height: 25px;
    font-weight: 600;
    font-size: 16px;
    text-align: left;
    margin-right: 10px;
    font-style: normal;
    position: relative;
    top: -9px;
    transition: all .5s ease
}

.hero4__area .event__video-btn--plays .event__videos:hover {
    color: #3270fc
}

.hero4__area .event__video-btn--plays .event__videos:hover i {
    border-color: #3270fc;
    color: #3270fc
}

.hero4__area .event__video-btn--plays .event__videos:hover em {
    color: #3270fc
}

.hero4__area .hero4__content p {
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    color: #fff;
    padding-bottom: 16px
}

.hero4__area .hero4__content .hero4-btn {
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
    background: #fff;
    padding: 14px 35px;
    color: #0F1216;
    display: inline-block;
    margin-top: 0;
    line-height: 20px;
    margin-left: 25px
}

.hero4__area .hero4__content .hero4-btn:hover {
    background: #3270fc;
    color: #fff
}

.hero4__area .hero4__image {
    padding-top: 90px;
    padding-left: 56px
}

.hero4__area .hero4__image-1 {
    position: relative;
    z-index: 1
}

.hero4__area .hero4__image-2 {
    position: absolute;
    top: 50%;
    right: 17.4%;
    -webkit-animation: hero3-image 3s linear infinite alternate;
    -moz-animation: hero3-image 3s linear infinite alternate;
    -o-animation: hero3-image 3s linear infinite alternate;
    animation: hero3-image 3s linear infinite alternate
}

.hero4__area .hero4__image-course {
    border-radius: 50%;
    position: absolute;
    box-shadow: 0 30px 40px 0 rgba(122, 0, 39, .2);
    background-color: #fd467f;
    top: 69.5%;
    right: 71.5%;
    width: 150px;
    height: 150px;
    -webkit-animation: hero3-image 3s linear infinite alternate;
    -moz-animation: hero3-image 3s linear infinite alternate;
    -o-animation: hero3-image 3s linear infinite alternate;
    animation: hero3-image 3s linear infinite alternate
}

.hero4__area .hero4__shape img {
    position: absolute;
    z-index: 1
}

.hero4__area .hero4__shape-1 {
    bottom: 60%;
    right: 13%;
    -webkit-animation: hero-leaf 3s linear infinite alternate;
    -moz-animation: hero-leaf 3s linear infinite alternate;
    -o-animation: hero-leaf 3s linear infinite alternate;
    animation: hero-leaf 3s linear infinite alternate
}

.hero4__area .hero4__shape-2 {
    top: 50%;
    right: 0;
    -webkit-animation: hero-leaf-2 3s linear infinite alternate;
    -moz-animation: hero-leaf-2 3s linear infinite alternate;
    -o-animation: hero-leaf-2 3s linear infinite alternate;
    animation: hero-leaf-2 3s linear infinite alternate
}

.hero4__area .hero4__shape-3 {
    top: 53%;
    right: 54%;
    -webkit-animation: hero-leaf-2 3s linear infinite alternate;
    -moz-animation: hero-leaf-2 3s linear infinite alternate;
    -o-animation: hero-leaf-2 3s linear infinite alternate;
    animation: hero-leaf-2 3s linear infinite alternate
}

.hero4__area .hero4__shape-4 {
    top: 38%;
    left: 13%;
    -webkit-animation: hero-leaf-2 3s linear infinite alternate;
    -moz-animation: hero-leaf-2 3s linear infinite alternate;
    -o-animation: hero-leaf-2 3s linear infinite alternate;
    animation: hero-leaf-2 3s linear infinite alternate
}

.hero4__area .hero4__shape-5 {
    top: 76%;
    left: 12%;
    -webkit-animation: hero-leaf-2 3s linear infinite alternate;
    -moz-animation: hero-leaf-2 3s linear infinite alternate;
    -o-animation: hero-leaf-2 3s linear infinite alternate;
    animation: hero-leaf-2 3s linear infinite alternate
}

@keyframes hero-leaf {
    0% {
        transform: translateY(-50px)
    }

    100% {
        transform: translateY(0)
    }
}

@keyframes hero-leaf-2 {
    0% {
        transform: translateY(0)
    }

    100% {
        transform: translateY(-30px)
    }
}

@keyframes hero3-image {
    0% {
        transform: translateY(-20px)
    }

    100% {
        transform: translateY(0)
    }
}

@keyframes hero-leaf-4 {
    0% {
        transform: translateX(-30px)
    }

    100% {
        transform: translateX(0)
    }
}

@media (max-width:360px) {
    .hero4__area .hero4__title {
        font-size: 35px !important;
        line-height: 40px !important
    }

    .hero4__area .event__video-btn--plays em {
        display: none
    }
}

.react-course-filter {
    background: #F5F5F5
}

.react-course-filter.related__course {
    background: 0 0;
    padding-top: 90px
}

.react-course-filter.related__course h3 {
    font-weight: 700;
    color: #0F1216;
    font-size: 20px;
    padding-bottom: 10px
}

.react-course-filter.related__course .single-studies .inner-course {
    box-shadow: 0 1px 2px rgba(15, 18, 22, .1);
    border-radius: 6px;
    background: #fff
}

.react-course-filter .react-filter {
    padding-bottom: 65px
}

.react-course-filter .react-filter button {
    border: none;
    margin: 0 15px 0 0;
    color: #5B5F64;
    padding: 4px 30px 6px;
    border-radius: 6px;
    font-weight: 400;
    transition: all .5s ease 0s;
    background: #fff;
    box-shadow: 0 1px 2px rgba(0, 14, 32, .1)
}

.react-course-filter .react-filter button.active, .react-course-filter .react-filter button:hover {
    background: #3270FC;
    color: #fff
}

.react-course-filter .react__title__section h2 {
    font-size: 44px;
    line-height: 56px;
    color: #0F1216
}

.react-course-filter .react__title__section h6 {
    font-size: 18px;
    color: #5B5F64;
    line-height: 22px;
    font-weight: 400;
    margin-top: 15px;
    display: block
}

.react-course-filter .single-studies {
    margin-bottom: 25px
}

.react-course-filter .single-studies .inner-course {
    background: #fff;
    border-radius: 10px
}

.react-course-filter .single-studies .inner-course .case-img {
    position: relative
}

.react-course-filter .single-studies .inner-course .case-img a {
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    background: #3270fc;
    display: inline-block;
    padding: 4px 9px 7px;
    color: #fff;
    border-radius: 4px;
    position: absolute;
    left: 22px;
    top: 20px
}

.react-course-filter .single-studies .inner-course .case-content {
    padding: 30px 38px 20px 35px
}

.react-course-filter .single-studies .inner-course .case-content .case-title {
    margin: 6px 0 30px;
    font-size: 20px;
    font-weight: 600
}

.react-course-filter .single-studies .inner-course .case-content .case-title a {
    color: #0F1216
}

.react-course-filter .single-studies .inner-course .case-content .case-title a:hover {
    color: #3270FC
}

.react-course-filter .single-studies .inner-course .case-content .react__user {
    font-size: 16px;
    color: #5B5F64;
    font-weight: 400
}

.react-course-filter .single-studies .inner-course .case-content .react__user img {
    margin-left: 12px;
    height: 30px
}

.react-course-filter .single-studies .inner-course .case-content .react-ratings {
    border-top: 1px solid #eee;
    margin-top: 40px;
    padding-top: 14px;
    justify-content: space-between
}

.react-course-filter .single-studies .inner-course .case-content .react-ratings li:last-child {
    padding-right: 0
}

.react-course-filter .single-studies .inner-course .case-content .react-ratings li.price {
    color: #0F1216;
    font-size: 20px;
    font-weight: 600
}

.react-course-filter .single-studies .inner-course .case-content ul {
    display: flex
}

.react-course-filter .single-studies .inner-course .case-content ul li {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #5B5F64;
    font-weight: 400;
    padding-right: 0;
    padding-left: 30px
}
html[lang="en"] .react-course-filter .single-studies .inner-course .case-content ul li {
   padding-left: 0;
    padding-right: 30px

}

.react-course-filter .single-studies .inner-course .case-content ul li svg {
    margin-left: 10px;
    width: 20px
}

.react-course-filter .single-studies .inner-course .case-content ul li svg.feather-star {
    color: #FFA439
}

.owl-controls {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    width: 1170px
}

.owl-controls .owl-dots {
    position: absolute;
    bottom: 0;
    left: 0
}

.owl-controls .owl-dots .owl-dot {
    display: inline-block;
    margin-right: 10px
}

.owl-controls .owl-dots .owl-dot span {
    display: inline-block;
    height: 15px;
    width: 15px;
    background: 0 0;
    border: 2px solid rgba(255, 255, 255, .2);
    border-radius: 100%;
    transition: all .5s ease 0s
}

.owl-controls .owl-dots .owl-dot.active span {
    background: #fff
}

.owl-controls .owl-dots .owl-dot:last-child {
    border-right: none
}

.owl-controls .owl-nav {
    display: none
}

.home-sliders {
    position: relative
}

.home-sliders .slider-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%);
    direction: rtl
}

@media (max-width:1440px) and (min-width:1200px) {
    .home-sliders .slider-content {
        left: 42%;
        padding-right: 40px !important;
        padding-left: 0 !important
    }
}

@media (max-width:1440px) and (min-width:992px) {
    .home-sliders .slider-content {
        padding-right: 40px !important;
        padding-left: 0 !important;
        left: 40%
    }
}

@media (max-width:991px) {
    .home-sliders .slider-content {
        padding-right: 40px;
        left: 20%
    }
}

.home-sliders .slider-content .slider-title {
    font-size: 70px;
    margin-bottom: 15px;
    color: #fff;
    line-height: 74px;
    font-weight: 700;
    display: block;
    margin-top: 20px
}

.home-sliders .slider-content .slider-pretitle {
    padding: 3px 15px 8px;
    font-size: 26px;
    color: #fff;
    margin-bottom: 22px;
    font-weight: 400;
    line-height: 32px;
    background: #D2093C
}

.home-sliders .slider-content .slider-desc {
    font-size: 18px;
    color: #fff
}

.home-sliders .slider-content .slider-btn {
    margin-top: 32px
}

.home-sliders .slider-content .slider-btn .react-btn-border {
    background: 0 0;
    color: #fff
}

.home-sliders .slider-content .slider-btn .react-btn-border:hover {
    background: #D2093C;
    color: #fff;
    border-color: #D2093C
}

.home-sliders .event__video-btn--play-btn {
    color: #00306e
}

.home-sliders .owl-nav {
    position: absolute;
    display: block;
    top: 43%;
    right: unset;
    width: 100%;
    opacity: 1;
    transition: all .5s ease 0s
}

@media (max-width:991px) {
    .home-sliders .owl-nav {
        display: none
    }
}

.home-sliders .owl-nav [class*=owl-] {
    position: absolute;
    left: 20px;
    display: inline-block;
    font-size: 0;
    width: 70px;
    height: 70px;
    line-height: 70px;
    color: #fff;
    background: 0 0;
    border: 2px solid rgba(245, 245, 245, .08);
    border-radius: 100%;
    transition: all .5s ease 0s
}

.home-sliders .owl-nav [class*=owl-]:before {
    content: "\35";
    font-family: ElegantIcons;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 45px;
    font-weight: 400;
    color: rgba(255, 255, 255, .48);
    line-height: 28px
}

.home-sliders .owl-nav [class*=owl-].owl-next {
    right: auto;
    left: 70px;
    rotate: 180deg
}

.home-sliders .owl-nav [class*=owl-].owl-next span {
    font-size: 0
}

.home-sliders .owl-nav [class*=owl-].owl-prev {
    right: 70px;
    left: auto;
    rotate: 180deg
}

@media (max-width:1550px) {
    .home-sliders .owl-nav [class*=owl-].owl-prev {
        right: 15px;
        left: auto !important
    }
}

.home-sliders .owl-nav [class*=owl-].owl-prev:before {
    content: '\34'
}

.home-sliders .owl-nav [class*=owl-].owl-prev span {
    font-size: 0
}

.home-sliders .owl-nav [class*=owl-]:hover {
    color: #00306e;
    background: #fff;
    border-color: #fff
}

.home-sliders .owl-nav [class*=owl-]:hover:before {
    color: #3270fc
}

.home-sliders .event__video-btn--play {
    left: 28%;
    right: auto
}

@media only screen and (max-width:1299px) and (min-width:992px) {
    .home-sliders .event__video-btn--play {
        right: auto !important
    }
}

.home-sliders .event__video-btn--play .event__video-btn--play-btn {
    direction: rtl
}

.home-sliders .event__video-btn--play .event__video-btn--play-btn em {
    position: absolute;
    width: 250px;
    color: #fff;
    margin-right: 50px;
    line-height: 25px;
    font-weight: 700;
    font-size: 20px;
    text-align: right;
    margin-top: 20px;
    font-style: normal
}

.home-sliders .ellipse {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 100
}

.home-sliders .ellipse img {
    width: 100%
}

@media (max-width:360px) {
    .home-sliders .slider-title {
        font-size: 27px !important;
        line-height: 32px !important
    }
}

.home_four-section .section-title {
    position: absolute;
    top: 70px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 120px;
    color: #eef2f8;
    font-weight: 700
}

.home_four-section .sub-title {
    text-align: center;
    color: #252525;
    font-size: 43px;
    font-weight: 600;
    transform: translateY(-33px);
    margin-top: 70px;
    margin-bottom: 45px
}

@media (max-width:1200px) {
    .home_four-section .section-title {
        font-size: 80px
    }

    .home_four-section .sub-title {
        font-size: 30px;
        transform: translateY(-56px) !important
    }
}

@media (max-width:574px) {
    .home_four-section .section-title {
        font-size: 50px
    }

    .home_four-section .sub-title {
        font-size: 20px;
        transform: translateY(-33px) !important
    }
}

.social-links.home-four-social-links {
    position: absolute !important;
    top: 50%;
    right: -170px;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    z-index: 2
}

.social-links.home-four-social-links a {
    line-height: 47px
}

.social-links.home-four-social-links span {
    color: #a3a3a3;
    font-size: 14px
}

.social-links.home-four-social-links a {
    border: none !important
}

@media (max-width:1680px) {
    .social-links.home-four-social-links {
        left: 0;
        flex-direction: row !important;
        top: 400px;
        right: 0
    }
}

.home-four-slider-part {
    position: relative
}

.home-four-slider-part .slider-content {
    margin-top: 40px;
    left: auto
}

.home-four-slider-part .hero {
    margin-top: 100px
}
.home-four-slider-part .single-slide .slider-content .hero img {

animation: objectRotation 5s linear infinite;
}
@keyframes objectRotation {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
      }
}

.home-four-sliders .single-slide {
    position: relative;
    padding: 400px 0;
    /* background: center no-repeat #000;
    background-image: url("../../../Images/home4/backdrop2.png") !important;
    background-size: cover */
}

@media (max-width:1200px) {
    .home-four-sliders .single-slide {
        padding: 100px 0
    }
}

.home-four-sliders .slider-content {
    display: flex;
    align-items: center;
    justify-content: space-between
}

@media (max-width:1200px) {
    .home-four-sliders .slider-content {
        position: relative;
        flex-direction: column !important;
        transform: inherit;
        width: 100%
    }

    .home-four-sliders .slider-content .hero {
        margin-top: 150px !important
    }

    .home-four-sliders .slider-content .hero.hero2 {
        max-width: 600px;
        transform: translateY(0)
    }
}

.home-four-sliders .hero {
    max-width: 600px;
    bottom: 40px;
    right: 15%
}

.home-four-sliders .hero img {
    width: 100%
}

.home-four-sliders .hero.hero2 {
    max-width: 700px;
    transform: translateY(30px)
}

.home-four-sliders .ellipse {
    position: absolute;
    bottom: -1px;
    left: 50%;
    transform: translateX(-50%)
}

.home-four-sliders .ellipse i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    animation: upDown 2s infinite ease-in-out;
    cursor: pointer
}

.home-four-sliders .ellipse i::before {
    content: '\22' !important;
    font-size: 28px;
    color: #252525
}

.home-four-sliders .arrow_down .slider-home-four {
    background: #000 !important
}

.home-four-sliders .content-part {
    position: relative
}

.home-four-sliders .content-part .slider-pretitle {
    background: 0 0;
    font-weight: 200;
    font-size: 30px;
    letter-spacing: 10px;
    padding: 0 !important
}

.home-four-sliders .content-part .slider-title {
    font-weight: 600;
    margin-bottom: 30px
}

.home-four-sliders .content-part p {
    color: #f5f5f5 !important;
    font-size: 14px;
    max-width: 400px;
    line-height: 30px !important
}

.home-four-sliders .content-part .slider-btn {
    background: #3270fc;
    color: #fff;
    font-size: 16px;
    padding: 15px 40px;
    border: none;
    border-radius: 50px;
    transition: background 500ms
}

.home-four-sliders .content-part .slider-btn:hover {
    background: #4d82ff
}

.home-four-sliders .owl-nav {
    position: absolute;
    left: 0;
    width: 300px;
    transform: rotate(90deg)
}

.home-four-sliders .owl-nav .owl-prev {
    margin-right: -100px !important
}

.home-four-sliders .owl-nav .owl-prev::before {
    content: '\23' !important;
    font-size: 25px;
    color: #999
}

.home-four-sliders .owl-nav .owl-prev:hover {
    background: 0 0 !important
}

.home-four-sliders .owl-nav .owl-prev:hover::before {
    color: #999
}

.home-four-sliders .owl-nav .owl-next {
    margin-left: -100px !important
}

.home-four-sliders .owl-nav .owl-next::before {
    content: '\24' !important;
    font-size: 25px;
    color: #999
}

.home-four-sliders .owl-nav .owl-next:hover {
    background: 0 0 !important
}

.home-four-sliders .owl-nav .owl-next:hover::before {
    color: #999
}

.home-four-sliders .owl-nav::before {
    content: '';
    position: absolute;
    top: 33px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 1px;
    background: #999
}

@media (max-width:1630px) {
    .home-four-sliders .owl-nav {
        right: -50px
    }
}

@media (max-width:1490px) {
    .home-four-sliders .owl-nav {
        right: -100px
    }
}

@media (max-width:1250px) {
    .home-four-sliders .owl-nav {
        display: none
    }
}

@media (max-width:1400px) {
    .home-four-sliders .content-part .slider-title {
        font-size: 50px
    }

    .home-four-sliders .content-part .slider-pretitle {
        font-size: 20px !important
    }
}

@media (max-width:1200px) {
    .home-four-sliders .content-part .slider-title {
        font-size: 70px !important
    }
}

@media (max-width:991px) {
    .home-four-sliders .content-part .slider-title {
        font-size: 60px !important
    }
}

@media (max-width:769px) {
    .home-four-sliders .content-part .slider-title {
        font-size: 45px !important
    }

    .home-four-sliders .content-part .slider-pretitle {
        font-size: 17px !important
    }
}

@media (max-width:510px) {
    .home-four-sliders .content-part .slider-title {
        font-size: 30px !important
    }

    .home-four-sliders .content-part .slider-pretitle {
        font-size: 15px !important
    }
}

.home_four-section {
    position: relative
}

.languegeSlider {
    overflow: hidden !important
}

.langueges-section {
    background: #f5f5f5
}

.langueges-section .lang-item img {
    width: 1005
}

@media (max-width:991px) {
    .langueges-section {
        padding: 50px 0 !important
    }

    .langueges-section .lang-item {
        max-width: 60px
    }
}

@media (max-width:550px) {
    .langueges-section {
        padding: 40px 0 !important
    }

    .langueges-section .lang-item {
        max-width: 50px
    }
}

.catagories-section {
    position: relative
}

.catagories-section .catagory-item {
    position: relative;
    margin-bottom: 35px
}

.catagories-section .catagory-item .catagory-picture {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f5f5f5;
    padding: 45px 90px;
    margin-bottom: 10px;
    max-width: 100%;
    aspect-ratio: 1
}

.catagories-section .catagory-item .catagory-picture img {
    max-width: 100%;
    aspect-ratio: 1
}

.catagories-section .catagory-item .content {
    position: relative;
    display: flex;
    align-items: center
}

.catagories-section .catagory-item .content .item-title {
    position: relative;
    font-size: 21px;
    font-weight: 600;
    margin-left: 60px
}

.catagories-section .catagory-item .content .item-title a {
    color: #252525
}

.catagories-section .catagory-item .content .item-title a:hover {
    color: #3270fc !important
}

.catagories-section .catagory-item .content .item-title::before {
    content: '';
    position: absolute;
    top: 20px;
    left: -40px;
    width: 25px;
    height: 1px;
    background: #3772ff
}

.catagories-section .catagory-item .content .info {
    font-size: 13px;
    color: #999;
    line-height: 0 !important;
    transform: translateY(3px)
}

@media (max-width:769px) {
    .catagories-section .catagory-item {
        max-width: 100%
    }
}

.course-section {
    background: #262930
}

.course-section .course-inner {
    display: flex;
    justify-content: start;
    align-items: center
}

.course-section .course-inner .course-hero {
    position: relative
}

.course-section .course-inner .course-hero .tutor-picture {
    max-width: 600px;
    width: 100%
}

.course-section .course-inner .course-hero .tutor-picture img {
    width: 100%;
    height: auto
}

.course-section .course-inner .course-hero .phone-picture {
    max-width: 320px;
    position: absolute;
    top: 100px;
    left: -160px
}

.course-section .course-inner .course-hero .phone-picture img {
    width: 100%
}

.course-section .course-inner .course-contents {
    margin: 0 auto;
    padding: 100px 0
}

.course-section .course-inner .course-contents .course-contents-inner {
    display: flex;
    flex-direction: column;
    justify-content: start
}

.course-section .course-inner .course-contents .course-sub-title {
    color: #fff;
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 10px
}

.course-section .course-inner .course-contents .course-section-title {
    color: #fff;
    font-size: 45px;
    font-weight: 600;
    max-width: 600px;
    line-height: 60px;
    margin-bottom: 60px
}

.course-section .course-inner .course-contents .course-item {
    display: flex;
    align-items: center;
    margin-bottom: 40px
}

.course-section .course-inner .course-contents .course-item:last-child {
    margin-bottom: 0
}

.course-section .course-inner .course-contents .course-item .course-item-icon {
    width: 80px;
    margin-left: 40px
}

.course-section .course-inner .course-contents .course-item .course-item-icon img {
    max-width: 100%
}

.course-section .course-inner .course-contents .course-item .course-item-title {
    color: #fff;
    font-size: 20px
}

.course-section .course-inner .course-contents .course-item p {
    color: #999;
    font-size: 15px;
    max-width: 500px
}

@media (max-width:1650px) {
    .course-section .phone-picture {
        max-width: 300px !important;
        right: -40px !important
    }
}

@media (max-width:1420px) {
    .course-section .course-inner .course-hero .tutor-picture {
        max-width: 500px
    }

    .course-section .course-inner .course-hero .phone-picture {
        max-width: 260px !important;
        right: 0 !important
    }

    .course-section .course-inner .course-contents {
        padding: 50px !important
    }

    .course-section .course-inner .course-contents .section-title {
        font-size: 30px;
        max-width: 400px;
        line-height: inherit
    }

    .course-section .course-inner .course-contents .course-item-icon {
        max-width: 60px;
        margin-right: 25px !important
    }

    .course-section .course-inner .course-contents .course-item {
        margin-bottom: 25px
    }

    .course-section .course-inner .course-contents .course-item .course-item-title {
        font-size: 16px
    }
}

@media (max-width:1130px) {
    .course-section .course-inner .course-contents {
        padding: 20px !important
    }
}

@media (max-width:975px) {
    .course-section .course-inner {
        flex-direction: column
    }

    .course-section .course-inner .tutor-picture {
        max-width: 100vw !important;
        width: 100vw !important
    }

    .course-section .course-inner .course-contents {
        padding: 50px !important
    }
}

@media (max-width:450px) {
    .course-section .course-inner {
        flex-direction: column
    }

    .course-section .course-inner .tutor-picture {
        max-width: 100vw !important;
        width: 100vw !important
    }

    .course-section .course-inner .phone-picture {
        width: 170px !important
    }

    .course-section .course-inner .course-contents .section-title {
        font-size: 25px !important;
        margin-bottom: 50px;
        max-width: 320px !important
    }

    .course-section .course-inner .course-contents .course-item {
        flex-direction: column !important;
        align-items: flex-start
    }

    .course-section .course-inner .course-contents .course-item .course-item-icon {
        margin-bottom: 15px
    }
}

.trending-section {
    position: relative;
    margin-bottom: -139px !important
}

@media (max-width:1690px) {
    .trending-section {
        padding-bottom: 230px !important
    }
}

@media (max-width:1200px) {
    .trending-section {
        padding-bottom: 215px !important
    }
}

@media (max-width:580px) {
    .trending-section {
        padding-bottom: 232px !important
    }
}

.trending-section .slider-navigation {
    margin-top: 30px
}

.trending-section .trending-card {
    position: relative;
    box-shadow: 0 0 30px 0 rgba(50, 112, 252, .1);
    margin-bottom: 40px;
    overflow: hidden !important
}

.trending-section .trending-card .trending-card-picture {
    transition: 300ms;
    width: 100%
}

.trending-section .trending-card .trending-card-picture img {
    width: 100%
}

.trending-section .trending-card .time {
    position: absolute;
    bottom: 130px;
    left: 20px;
    background: #fff;
    box-shadow: 0 0 30px 0 rgba(50, 112, 252, .15);
    display: grid;
    place-items: center;
    width: 70px;
    aspect-ratio: 1;
    border-radius: 50%;
    font-weight: 600
}

.trending-section .trending-card .content {
    display: flex;
    flex-direction: column;
    padding: 40px
}

.trending-section .trending-card .content .card-title {
    font-size: 26px;
    font-weight: 600
}

.trending-section .trending-card .content .card-title a {
    color: #252525
}

.trending-section .trending-card .content .card-title a:hover {
    color: #3270fc
}

.trending-section .trending-card .content .card-info {
    color: #3270fc;
    font-size: 20px;
    font-weight: 600
}

.trending-section .trending-card:hover .trending-card-picture {
    transform: scale(110%)
}

@media (max-width:1200px) {
    .trending-section .trending-card .time {
        width: 50px;
        font-size: 14px
    }

    .trending-section .trending-card .content {
        padding: 40px
    }

    .trending-section .trending-card .content .card-title {
        font-size: 22px
    }

    .trending-section .trending-card .content .card-info {
        font-size: 16px
    }
}

@media (max-width:992px) {
    .trending-section .trending-card .time {
        width: 50px;
        font-size: 14px
    }

    .trending-section .trending-card .content {
        padding: 40px
    }

    .trending-section .trending-card .content .card-title {
        font-size: 22px
    }

    .trending-section .trending-card .content .card-info {
        font-size: 16px
    }
}

.instructor-section .instructor-inner {
    background: #f5f5f5;
    padding: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center
}

.instructor-section .instructor-inner .content .instructor-title {
    font-weight: 600;
    line-height: 1.161;
    color: #252525;
    font-size: 50px
}

.instructor-section .instructor-inner .content .instructor-info {
    color: #777;
    font-size: 16px;
    max-width: 600px
}

.instructor-section .instructor-inner .instructor-btn {
    background-color: #3270fc;
    padding: 15px 30px;
    border-radius: 100px;
    color: #fff;
    font-size: 18px;
    border: none;
    transition: background 500ms
}

.instructor-section .instructor-inner .instructor-btn:hover {
    background: #4d82ff
}

@media (max-width:1200px) {
    .instructor-section .instructor-inner .content .instructor-title {
        font-size: 35px
    }

    .instructor-section .instructor-inner .content .instructor-info {
        font-size: 15px;
        max-width: 500px
    }

    .instructor-section .instructor-inner .instructor-btn {
        padding: 10px 20px;
        font-size: 15px
    }
}

@media (max-width:992px) {
    .instructor-section .instructor-inner {
        flex-direction: column;
        align-items: flex-start !important
    }
}

@media (max-width:530px) {
    .instructor-section .instructor-inner {
        padding: 20px
    }

    .instructor-section .instructor-inner .instructor-btn {
        padding: 7px 14px;
        font-size: 13px
    }
}

.slider-prev-btn {
    position: absolute;
    top: 55%;
    right: 110px;
    color: #999;
    font-size: 16px;
    font-weight: 400;
    transform: rotate(270deg)
}

.slider-prev-btn i {
    color: #999;
    margin-right: 10px
}

.slider-prev-btn::before {
    content: '';
    position: absolute;
    right: -60px;
    top: 13px;
    width: 30px;
    height: 1px;
    background: #999
}

.slider-next-btn {
    position: absolute;
    top: 50%;
    left: 110px;
    color: #999;
    font-size: 16px;
    font-weight: 400;
    transform: rotate(270deg)
}

.slider-next-btn i {
    color: #999;
    margin-left: 10px
}

.slider-next-btn::before {
    content: '';
    position: absolute;
    left: -60px;
    top: 13px;
    width: 30px;
    height: 1px;
    background: #999
}

@media (max-width:1690px) {
    .slider-navigation {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 300px;
        display: flex;
        justify-content: space-between;
        align-items: center
    }

    .slider-next-btn, .slider-prev-btn {
        position: relative;
        transform: rotate(0deg)
    }

    .slider-next-btn {
        transform: translateX(100px)
    }

    .slider-prev-btn {
        transform: translateX(-100px)
    }
}

.students-section {
    background: #262930 !important
}

.students-section .students-inner {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.students-section .students-inner .detail, .students-section .students-inner h1, .students-section .students-inner p, .students-section .students-inner span {
    color: #fff
}

.students-section .students-inner .contents h1 {
    font-size: 56px;
    font-weight: 600;
    line-height: 50px
}

.students-section .students-inner .contents p {
    font-size: 22px;
    line-height: 20px
}

.students-section .students-inner .contents .detail {
    font-size: 14px;
    max-width: 400px;
    margin-bottom: 40px
}

.students-section .students-inner .contents .followers {
    display: flex
}

.students-section .students-inner .contents .followers .item {
    font-size: 50px;
    font-weight: 600
}

.students-section .students-inner .contents .followers .item .counter {
    float: right;
    direction: ltr
}

.students-section .students-inner .contents .followers .item p {
    font-size: 18px;
    font-weight: 400;
    max-width: 200px
}

.students-section .students-inner .contents .followers .joined {
    margin-left: 50px
}

@media (max-width:1200px) {
    .students-section .students-inner .contents h1 {
        font-size: 40px
    }

    .students-section .students-inner .contents p {
        font-size: 16px
    }

    .students-section .students-inner .contents .detail {
        font-size: 12px
    }

    .students-section .students-inner .contents .followers .item {
        font-size: 30px
    }

    .students-section .students-inner .contents .followers .item p {
        font-size: 13px
    }

    .students-section .map-image {
        max-width: 400px
    }
}

@media (max-width:769px) {
    .students-section .students-inner {
        flex-direction: column
    }

    .students-section .students-inner .contents {
        margin-bottom: 40px
    }
}

@media (max-width:530px) {
    .students-section .contents .detail {
        max-width: 300px !important
    }
}

@media (max-width:1690px) {
    .testimonials-style1 {
        padding-bottom: 240px !important
    }
}

.testimonials-style1 .slider-navigation {
    margin-top: 70px
}

.testimonials-style1 .testimonials-card {
    position: relative;
    max-width: 405px;
    padding: 50px;
    border: 1px solid #f5f5f5;
    background: #fff
}

.testimonials-style1 .testimonials-card .profile {
    display: flex;
    align-items: center;
    margin-bottom: 30px
}

.testimonials-style1 .testimonials-card .profile .dp {
    margin-left: 20px
}

.testimonials-style1 .testimonials-card .profile .identity .name {
    color: #252525;
    font-size: 24px;
    font-weight: 600;
    line-height: 35px
}

.testimonials-style1 .testimonials-card .profile .identity .status {
    color: #777;
    font-size: 16px
}

.testimonials-style1 .testimonials-card .contents p {
    color: #777;
    font-size: 16px;
    margin-bottom: 20px
}

.testimonials-style1 .testimonials-card .testimonials-rating i {
    color: #ff9000
}

.testimonials-style1 .testimonials-card .testimonials-rating i.blank-star {
    color: #e5e5e5
}

@media (max-width:1200px) {
    .testimonials-style1 .testimonials-card {
        padding: 30px
    }

    .testimonials-style1 .testimonials-card .profile {
        display: flex;
        align-items: center;
        margin-bottom: 20px
    }

    .testimonials-style1 .testimonials-card .profile .dp {
        margin-right: 20px
    }

    .testimonials-style1 .testimonials-card .profile .identity .name {
        color: #252525;
        font-size: 18px;
        font-weight: 600;
        line-height: 30px
    }

    .testimonials-style1 .testimonials-card .profile .identity .status {
        color: #777;
        font-size: 14px
    }

    .testimonials-style1 .testimonials-card .contents p {
        color: #777;
        font-size: 14px;
        margin-bottom: 20px
    }

    .testimonials-style1 .testimonials-card .testimonials-rating i {
        color: #ff9000;
        font-size: 14px
    }
}

@media (max-width:576px) {
    .testimonials-style1 {
        padding-bottom: 120px !important
    }
}

.events-section {
    background: #f5f5f5
}

.events-section .events-section-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 70px
}

.events-section .events-section-title .events-title {
    color: #252525;
    font-size: 56px;
    font-weight: 600
}

.events-section .events-section-title .events-view-all a {
    color: #777;
    font-size: 20px;
    font-weight: 500
}

.events-section .events-section-title .events-view-all i {
    color: #999;
    font-size: 10px;
    margin-right: 5px;
    rotate: 180deg
}

.events-section .event-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 50px;
    margin-bottom: 50px;
    border-bottom: 1px solid #e5e5e5
}

.events-section .event-item .date {
    display: flex;
    flex-direction: column
}

.events-section .event-item .date .day {
    color: #252525;
    font-size: 65px;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 30px
}

.events-section .event-item .date .date-span {
    color: #7f7f7f
}

.events-section .event-item .event-info .event-title {
    color: #252525;
    font-size: 26px;
    font-weight: 600
}

.events-section .event-item .event-info .event-location {
    color: #7f7f7f
}

.events-section .event-item .event-btn {
    background: #3270fc;
    padding: 10px 40px;
    font-size: 20px;
    border-radius: 50px;
    border: none;
    transition: background 500ms
}

.events-section .event-item .event-btn:hover {
    background: #4d82ff
}

.events-section .event-item .event-btn a {
    color: #fff
}

.events-section .event-item:last-child {
    margin-bottom: 0;
    border: none
}

@media (max-width:1200px) {
    .events-section .events-section-title .events-title {
        font-size: 35px
    }

    .events-section .date .day {
        font-size: 40px !important;
        margin-bottom: 20px !important
    }

    .events-section .event-title {
        font-size: 18px !important
    }

    .events-section .event-btn {
        padding: 5px 15px
    }
}

@media (max-width:768px) {
    .events-section .event-item {
        flex-direction: column
    }

    .events-section .date {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px
    }

    .events-section .date .day {
        font-size: 65px;
        line-height: 20px;
        margin-bottom: 10px !important
    }

    .events-section .date .date-span {
        color: #7f7f7f
    }

    .events-section .event-info .event-title {
        font-size: 26px;
        margin-bottom: 20px
    }

    .events-section .event-info .event-location {
        margin-bottom: 20px
    }

    .events-section .event-btn {
        background: #3270fc;
        color: #fff;
        padding: 10px 40px;
        font-size: 20px;
        border-radius: 50px;
        border: none
    }

    .events-section:last-child {
        margin-bottom: 0;
        border: none
    }
}

.blog-section .maximized-part .blog-picture {
    margin-bottom: 20px;
    overflow: hidden
}

.blog-section .maximized-part .blog-picture img {
    max-width: 100%;
    transition: transform 300ms
}

.blog-section .maximized-part .blog-picture img:hover {
    transform: scale(110%)
}

.blog-section .maximized-part .blog-date {
    color: #777;
    font-size: 16px;
    margin-bottom: 7px;
    direction: ltr;
    text-align: end
}

.blog-section .maximized-part .blog-title {
    margin-bottom: 15px;
    max-width: 550px
}

.blog-section .maximized-part .blog-title a {
    color: #252525;
    font-size: 30px;
    font-weight: 600;
    line-height: 40px
}

.blog-section .maximized-part .blog-title a:hover {
    color: #3270fc
}

.blog-section .maximized-part .read-more {
    color: #777;
    font-size: 16px
}

.blog-section .maximized-part .read-more i {
    font-size: 12px;
    margin-right: 5px;
    rotate: 180deg
}

.blog-section .minimized-part .blog-item {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 20px
}

.blog-section .minimized-part .blog-item .blog-picture {
    max-width: 230px;
    margin-left: 39px;
    overflow: hidden
}

.blog-section .minimized-part .blog-item .blog-picture img {
    max-width: 100%;
    transition: transform 300ms
}

.blog-section .minimized-part .blog-item .blog-picture img:hover {
    transform: scale(110%)
}

.blog-section .minimized-part .blog-item .content .blog-date {
    color: #777;
    font-size: 16px;
    margin-bottom: 10px
}

.blog-section .minimized-part .blog-item .content .blog-title {
    margin-bottom: 10px;
    max-width: 450px
}

.blog-section .minimized-part .blog-item .content .blog-title a {
    color: #252525;
    font-size: 24px;
    font-weight: 600;
    line-height: 35px
}

.blog-section .minimized-part .blog-item .content .blog-title a:hover {
    color: #3270fc
}

.blog-section .minimized-part .blog-item .content .read-more {
    color: #777;
    font-size: 16px;
    margin-bottom: 0 !important
}

.blog-section .minimized-part .blog-item .content .read-more i {
    font-size: 12px;
    margin-right: 5px;
    rotate: 180deg
}

@media (max-width:1725px) {
    .blog-section .section-title {
        font-size: 100px
    }
}

@media (max-width:1444px) {
    .blog-section .section-title {
        font-size: 80px
    }
}

@media (max-width:1200px) {
    .blog-section .section-title {
        font-size: 70px
    }
}

@media (max-width:992px) {
    .blog-section .section-title {
        font-size: 50px
    }
}

@media (max-width:769px) {
    .blog-section .maximized-part {
        margin-bottom: 40px
    }

    .blog-section .maximized-part .blog-picture {
        margin-bottom: 20px
    }

    .blog-section .maximized-part .blog-picture img {
        max-width: 100%
    }

    .blog-section .maximized-part .blog-title {
        margin-bottom: 20px
    }

    .blog-section .maximized-part .blog-title a {
        font-size: 23px;
        min-width: 320px
    }

    .blog-section .maximized-part .blog-date {
        margin-bottom: 20px
    }

    .blog-section .maximized-part .read-more {
        font-size: 16px
    }

    .blog-section .minimized-part .content .blog-picture {
        margin-bottom: 20px
    }

    .blog-section .minimized-part .content .blog-picture img {
        max-width: 100%
    }

    .blog-section .minimized-part .content .blog-title a {
        font-size: 17px !important
    }

    .blog-section .minimized-part .content .blog-date {
        margin-bottom: 20px
    }

    .blog-section .minimized-part .content .read-more {
        font-size: 16px
    }
}

@media (max-width:358px) {
    .blog-section .section-title {
        font-size: 30px
    }
}

.my-element {
    animation: bounce;
    animation-duration: 8s
}

.category__area {
    padding: 115px 0 125px
}

.category__area .container.category__width {
    max-width: 1030px;
    position: relative
}

.category__area .container.category__width:before {
    content: "";
    position: absolute;
    width: 300px;
    height: 300px;
    bottom: -21px;
    left: 0;
    right: 0;
    background: #eef4fe;
    border-radius: 50%;
    margin: 0 auto
}

.category__area .container.category__width .row {
    justify-content: center
}

.category__area .category__top-wrapper {
    padding-bottom: 38px
}

.category__area .category__top-wrapper-1 {
    font-size: 16px;
    font-weight: 600;
    color: #3563cc
}

.category__area .category__top-wrapper-2 {
    font-size: 40px;
    font-weight: 800;
    line-height: 50px;
    color: #020334
}

.category__area .category__wrapper {
    max-width: 100%;
    padding: 8px 22px;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0 30px 40px 0 rgba(0, 16, 35, .12);
    display: flex;
    align-items: center
}

.category__area .category__wrapper img {
    display: inline-block;
    width: 34px;
    height: 34px
}

.category__area .category__wrapper img:not(:last-child) {
    margin-right: 10px
}

.category__area .category__wrapper h4 {
    display: inline-block;
    font-size: 16px;
    font-weight: 800;
    line-height: 50px;
    vertical-align: sub;
    margin: 0
}

.category__area .category__wrapper h4 a {
    color: #00306e
}

.category__area .category__wrapper:hover a {
    color: #3270fc
}

.category__area .category__wrapper.category__wrapper2 img {
    height: 36px
}

.category__area .category__wrapper.category__wrapper6 img {
    height: 29px
}

.category__area .category__inner {
    position: relative;
    z-index: 1;
    transition: all .5s ease 0s;
    cursor: pointer;
    border-left: 3px solid #fff;
    border-radius: 6px
}

.category__area .category__inner:hover {
    transform: translateY(-2px);
    border-color: #3270fc
}

.react-logo-area.brand__area {
    padding: 0 0 120px
}

.react-logo-area.brand__area .brand__width {
    background: #f6f6fb;
    max-width: 1170px;
    margin: 0 auto;
    padding: 45px 0 70px;
    border-radius: 12px
}

.react-logo-area .brand__title {
    padding: 5px;
    display: inline-block;
    font-size: 24px;
    font-weight: 800;
    line-height: 50px;
    color: #020334
}

.react-logo-area .brand__slider {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center
}

.react-logo-area .brand__slider .brand__item {
    padding: 0 40px
}

.hero3__area {
    overflow: hidden;
    background: url(../Images/banner2/bg2.png) center top no-repeat;
    padding-bottom: 180px;
    padding-top: 160px;
    background-size: cover
}

.hero3__area .about__content {
    max-width: 640px;
    margin: 0 auto;
    padding-top: 30px
}

.hero3__area .about__content ul {
    margin: 0;
    padding: 0;
    display: flex
}

.hero3__area .about__content ul li {
    display: flex;
    align-items: center;
    border-top: 1px solid #EDEDED;
    padding: 30px 28px
}

.hero3__area .about__content ul li:first-child {
    border-top: none
}

.hero3__area .about__content ul li .icon {
    margin-left: 15px
}

.hero3__area .about__content ul li .icon img {
    margin-top: -50px
}

.hero3__area .about__content ul li .text {
    flex: 1
}

.hero3__area .about__content ul li .text h4 {
    margin: 0 0 14px;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    direction: ltr
}

.hero3__area .about__content ul li .text p {
    margin: 0;
    font-size: 16px;
    line-height: 22px;
    color: #5B5F64
}

.hero3__area .about__content ul li a {
    border-radius: 50%;
    width: 44px;
    height: 44px;
    border: 2px solid #EDEDED;
    text-align: center;
    line-height: 36px
}

.hero3__area .about__content ul li a svg {
    width: 18px;
    color: #00306E
}

.hero3__area .about__content ul li a:hover {
    background: #3270fc;
    color: #fff
}

.hero3__area .about__content ul li a:hover svg {
    color: #fff
}

.hero3__area .hero3__content {
    text-align: center
}

.hero3__area .hero3__content .hero3__title {
    font-size: 70px;
    line-height: 74px;
    font-weight: 600;
    color: #0F1216;
    margin: 0
}

.hero3__area .hero3__content .hero3__title em {
    color: #3270FC;
    font-style: normal
}

.hero3__area .hero3__content .search-form {
    background: #fff;
    border-radius: 0;
    padding: 14px 18px 16px 25px;
    box-shadow: 0 20px 20px rgba(0, 14, 32, .1);
    max-width: 570px;
    margin: 40px auto 25px;
    position: relative;
    border-right: 3px solid #3270FC
}

.hero3__area .hero3__content .search-form [type=text] {
    border: none;
    color: #4b5b70;
    font-size: 14px;
    outline: 0;
    background: 0 0;
    width: 100%
}

.hero3__area .hero3__content .search-form button {
    border: none;
    background: 0 0;
    padding: 0;
    position: absolute;
    left: 20px
}

.hero3__area .hero3__content .hero3__paragraph {
    font-size: 16px;
    line-height: 20.11px;
    color: #00306E
}

.hero3__area .hero3__content .hero3__paragraph a {
    color: #3270fc
}

.hero3__area .hero3__content .hero3__paragraph a svg {
    width: 18px;
    rotate: 180deg
}

.p-relative {
    position: relative
}

.hero3__image {
    margin-left: 0;
    position: absolute;
    left: -10%;
    top: 8%
}

.hero3__image .hero3__image-1 {
    border-radius: 50%
}

.hero3__image-2 {
    position: absolute;
    top: 56%;
    left: -22%;
    -webkit-animation: hero3-image 3s linear infinite alternate;
    -moz-animation: hero3-image 3s linear infinite alternate;
    -o-animation: hero3-image 3s linear infinite alternate;
    animation: hero3-image 3s linear infinite alternate
}

.hero3__shape img {
    position: absolute;
    z-index: 1
}

.hero3__shape-1 {
    top: 32%;
    right: 2%;
    -webkit-animation: hero-leaf 3s linear infinite alternate;
    -moz-animation: hero-leaf 3s linear infinite alternate;
    -o-animation: hero-leaf 3s linear infinite alternate;
    animation: hero-leaf 3s linear infinite alternate
}

.hero3__shape-2 {
    top: 44%;
    right: 4.5%;
    -webkit-animation: hero-leaf-2 3s linear infinite alternate;
    -moz-animation: hero-leaf-2 3s linear infinite alternate;
    -o-animation: hero-leaf-2 3s linear infinite alternate;
    animation: hero-leaf-2 3s linear infinite alternate
}

.hero3__shape-3 {
    top: 57%;
    right: 72%;
    -webkit-animation: hero-leaf-4 3s linear infinite alternate;
    -moz-animation: hero-leaf-4 3s linear infinite alternate;
    -o-animation: hero-leaf-4 3s linear infinite alternate;
    animation: hero-leaf-4 3s linear infinite alternate
}

.hero3__shape-4 {
    top: 14.5%;
    right: 19%;
    -webkit-animation: hero-leaf-4 3s linear infinite alternate;
    -moz-animation: hero-leaf-4 3s linear infinite alternate;
    -o-animation: hero-leaf-4 3s linear infinite alternate;
    animation: hero-leaf-4 3s linear infinite alternate
}

.hero3__shape-5 {
    top: 69%;
    right: 9%;
    -webkit-animation: hero-leaf 3s linear infinite alternate;
    -moz-animation: hero-leaf 3s linear infinite alternate;
    -o-animation: hero-leaf 3s linear infinite alternate;
    animation: hero-leaf 3s linear infinite alternate
}

.hero3__shape-6 {
    top: 30%;
    right: 0;
    -webkit-animation: hero-leaf-2 3s linear infinite alternate;
    -moz-animation: hero-leaf-2 3s linear infinite alternate;
    -o-animation: hero-leaf-2 3s linear infinite alternate;
    animation: hero-leaf-2 3s linear infinite alternate
}

@keyframes hero-leaf {
    0% {
        transform: translateY(-50px)
    }

    100% {
        transform: translateY(0)
    }
}

@keyframes hero-leaf-2 {
    0% {
        transform: translateY(0)
    }

    100% {
        transform: translateY(-30px)
    }
}

@keyframes hero3-image {
    0% {
        transform: translateY(-20px)
    }

    100% {
        transform: translateY(0)
    }
}

@keyframes hero-leaf-4 {
    0% {
        transform: translateX(-30px)
    }

    100% {
        transform: translateX(0)
    }
}

@keyframes upDown {
    0% {
        transform: translate(-50%, -50%)
    }

    50% {
        transform: translate(-50%, -30%)
    }

    100% {
        transform: translate(-50%, -50%)
    }
}

.react_populars_topics .container {
    max-width: 1200px
}

.react_populars_topics .item__inner {
    border: 2px solid rgba(15, 22, 41, .078);
    border-radius: 6px;
    padding: 40px 35px 35px;
    position: relative;
    transition: all .5s ease 0s;
    margin-bottom: 30px;
    text-align: center
}

.react_populars_topics .item__inner:before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: -3px;
    border-radius: 6px 6px 0 0;
    height: 8px;
    width: 100%;
    z-index: 1;
    border-top: 3px solid #3270FC;
    transition: all .5s ease 0s;
    opacity: 0
}

.react_populars_topics .item__inner h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    margin: 25px 0 0
}

.react_populars_topics .item__inner h3 a {
    color: #00306e
}

.react_populars_topics .item__inner h3 a:hover {
    color: #3270fc
}

.react_populars_topics .item__inner p {
    font-size: 16px;
    color: #5B5F64;
    margin: 0;
    line-height: 20px
}

.react_populars_topics .item__inner:hover {
    box-shadow: 0 30px 50px 0 rgba(0, 15, 56, .1), inset 0 3px 0 0 rgba(42, 109, 245, .004);
    border-color: #fff
}

.react_populars_topics .item__inner:hover:before {
    opacity: 1
}

.react_populars_topics.react_populars_topics .item__inner {
    text-align: right
}

.react_populars_topics.react_populars_topics2 .item__inner {
    border: none;
    border-radius: 10px;
    padding: 40px 35px 35px;
    position: relative;
    transition: all .5s ease 0s;
    margin-bottom: 30px;
    text-align: right;
    box-shadow: 0 30px 60px rgba(15, 18, 22, .1)
}

.react_populars_topics.react_populars_topics2 .item__inner:before {
    opacity: 0
}

.react_populars_topics.react_populars_topics2 .item__inner h3 {
    font-weight: 700;
    margin: 25px 0 14px
}

.react_populars_topics.react_populars_topics2 .item__inner h3 a {
    color: #0F1216;
    font-size: 20px;
    font-weight: 700
}

.react_populars_topics.react_populars_topics2 .item__inner h3 a:hover {
    color: #3270fc
}

.react_populars_topics.react_populars_topics2 .item__inner p {
    font-size: 16px;
    color: #5B5F64;
    margin: 0;
    line-height: 24px
}

.react_populars_topics.react_populars_topics2 .item__inner a {
    font-size: 14px;
    font-weight: 600;
    color: #0F1216;
    display: inline-block;
    margin-top: 16px
}

.react_populars_topics.react_populars_topics2 .item__inner a svg {
    width: 18px;
    rotate: 180deg
}

.react_populars_topics.react_populars_topics2 .item__inner a:hover {
    color: #3270fc
}

.react_populars_topics.react_populars_topics2 .item__inner:hover {
    box-shadow: 0 30px 50px 0 rgba(0, 15, 56, .1), inset 0 3px 0 0 rgba(42, 109, 245, .004);
    border-color: #fff
}

.react_populars_topics.react_populars_topics2 .item__inner:hover:before {
    opacity: 0
}

.react_populars_topics.react_populars_topics_about .react__title__section-all {
    background: #F2F4F9;
    max-width: 1350px;
    margin: 0 auto;
    padding-top: 100px;
    padding-bottom: 200px;
    border-radius: 10px
}

.react_populars_topics.react_populars_topics_about .container {
    margin-top: -200px
}

.react_populars_topics.react_populars_topics_about.react_populars_topics.react_populars_topics2 .item__inner {
    background: #fff
}

.react__title__section-all h2 {
    font-size: 44px;
    font-weight: 700;
    color: #0F1216;
    margin: 0 0 10px;
    line-height: 54px
}

.react__title__section-all h6 {
    font-size: 16px;
    line-height: 20px;
    color: #D2093C;
    font-weight: 600;
    margin: 0 0 12px
}

.react_popular_topics .item__inner {
    position: relative;
    transition: all .5s ease 0s;
    margin-bottom: 30px
}

.react_popular_topics .item__inner .icon img {
    width: 100%
}

.react_popular_topics .item__inner:before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    border-radius: 6px 6px 0 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    background: linear-gradient(180deg, rgba(15, 18, 22, 0) 46.67%, rgba(15, 18, 22, .9) 91%);
    transition: all .5s ease 0s;
    bottom: 0
}

.react_popular_topics .item__inner .react-content {
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 20px;
    z-index: 2
}

.react_popular_topics .item__inner .react-content h3 {
    font-size: 22px;
    color: #fff;
    line-height: 27.65px;
    margin: 0;
    font-weight: 700
}

.react_popular_topics .item__inner .react-content h3 a {
    color: #fff
}

.react_popular_topics .item__inner .react-content h3 a:hover {
    color: #b5b2b2
}

.react_popular_topics .item__inner a.r__link {
    font-size: 14px;
    color: #fff;
    line-height: 17.6px;
    margin: 0;
    font-weight: 700
}

.react_popular_topics .item__inner a.r__link svg {
    width: 18px;
    rotate: 180deg
}

.react_popular_topics .item__inner a.r__link:hover {
    color: #b5b2b2
}

.react_popular_topics .item__inner:hover:before {
    background: rgba(15, 18, 22, .8)
}

.react_popular_topics .item__inner:hover a.r__link {
    color: #b5b2b2
}

.about__area h2 {
    font-size: 50px;
    font-weight: 700;
    line-height: 54px
}

.about__area h2 em {
    color: #D2093C;
    font-style: normal
}

.about__area.about__area_one .about__content {
    padding-top: 60px;
    padding-right: 60px
}

.about__area.about__area_one .about__paragraph {
    font-size: 20px;
    color: #777;
    line-height: 30px
}

.about__area.about__area_one p.about__paragraph2 {
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 44px;
    margin-bottom: 38px
}

.about__area.about__area_one p {
    font-size: 16px;
    line-height: 26px;
    color: #777
}

.about__area.about__area_one p a {
    color: #D2093C;
    text-decoration: underline !important;
    font-weight: 700
}

.about__area.about__area_one p a svg {
    width: 18px
}

.about__area.about__area_one p a:hover {
    opacity: .9
}

.about__area.about__area_one ul {
    display: flex;
    margin-top: 40px
}

.about__area.about__area_one ul li .more-about {
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
    border: 2px solid rgba(0, 48, 110, .1);
    padding: 14px 35px;
    color: #00306E;
    display: inline-block;
    line-height: 20px
}

.about__area.about__area_one ul li .more-about svg {
    width: 18px;
    rotate: 180deg
}

.about__area.about__area_one ul li .more-about:hover {
    background: #D2093C;
    color: #fff;
    border-color: #D2093C
}

.about__area.about__area_one ul li.last-li {
    background: url(../Images/about/comment.png) right top no-repeat;
    padding-right: 72px;
    margin-right: 30px
}

.about__area.about__area_one ul li.last-li em {
    display: block;
    font-size: 14px;
    color: #999;
    line-height: 15px;
    margin-top: 5px;
    font-style: normal
}

.about__area.about__area_one ul li.last-li a {
    font-size: 18px;
    color: #00306e;
    font-weight: 700
}

.about__area.about__area_one ul li.last-li a:hover {
    color: #D2093C
}

.about2__area h2 {
    color: #0F1216;
    font-size: 44px;
    line-height: 56px
}

.about2__area h2 em {
    color: #3270FC
}

.about2__area .about__image {
    position: relative
}

.about2__area .react__shape__1 {
    position: absolute;
    right: 0;
    top: 57%;
    -webkit-animation: slide-top 5000ms linear infinite alternate;
    animation: slide-top 5000ms linear infinite alternate;
    z-index: 1
}

.about2__area .about__content {
    padding-left: 70px
}

.about2__area .about__content .about__paragraph {
    font-size: 18px;
    line-height: 28px;
    color: #5B5F64
}

.about2__area .react__shape__2 {
    position: absolute;
    left: 60px;
    bottom: -30px;
    z-index: -1
}

.about2__area .react__shape__11 {
    position: absolute;
    right: -22px;
    top: -23px;
    z-index: -1
}

.about2__area .react__shape__33 {
    position: absolute;
    left: -13px;
    top: 60px;
    -webkit-animation: slide-top 5000ms linear infinite alternate-reverse;
    animation: slide-top 5000ms linear infinite alternate-reverse;
    z-index: 2
}

.about2__area .about__btn a {
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
    border: 2px solid rgba(0, 48, 110, .1);
    padding: 12px 35px;
    color: #00306E;
    display: inline-block;
    margin-top: 30px;
    line-height: 20px
}

.about2__area .about__btn a svg {
    width: 18px;
    rotate: 180deg
}

.about2__area .about__btn a:hover {
    background: #3270FC;
    color: #fff
}

.about2__area ul {
    margin: 0;
    padding: 0;
    overflow: hidden
}

.about2__area ul li {
    font-size: 16px;
    color: #0F1216;
    font-weight: 600;
    padding-bottom: 15px
}

.about2__area ul li i {
    margin-left: 10px;
    position: relative;
    background: rgba(210, 9, 60, .1);
    padding: 8px;
    color: #3270fc;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px
}

.about2__area.about2__area .about__content {
    padding-left: 0
}

.about2__area.about2__area .about__content h6 {
    font-size: 16px;
    line-height: 20px;
    color: #D2093C;
    font-weight: 600;
    margin: 0 0 12px
}

.about2__area.about2__area .about__content .about__btn a {
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
    border: none;
    padding: 16px 35px;
    color: #fff;
    display: inline-block;
    margin-top: 30px;
    line-height: 20px;
    background: #3270FC
}

.about2__area.about2__area .about__content .about__btn a:hover {
    opacity: .9;
    color: #fff
}

.about2__area.about3__area .about__content {
    padding-top: 85px
}

.react-breadcrumbs .breadcrumbs-wrap {
    position: relative;
    overflow: hidden
}

.react-breadcrumbs .breadcrumbs-wrap .container {
    position: relative
}

.react-breadcrumbs .breadcrumbs-wrap .breadcrumbs-inner {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%)
}

.react-breadcrumbs .breadcrumbs-wrap .breadcrumbs-inner .breadcrumbs-text {
    text-align: center !important
}

.react-breadcrumbs .breadcrumbs-wrap .breadcrumbs-inner .breadcrumbs-text.blog-single {
    text-align: right !important
}

.react-breadcrumbs .breadcrumbs-wrap .breadcrumbs-inner .breadcrumbs-text .breadcrumbs-title {
    width: 100%;
    font-size: 60px;
    text-transform: capitalize;
    color: #fff;
    font-weight: 700;
    line-height: 1;
    margin: 0 0 15px;
    text-align: center
}

.react-breadcrumbs .breadcrumbs-wrap .breadcrumbs-inner .breadcrumbs-text .breadcrumbs-title.title {
    text-align: right
}

.react-breadcrumbs .breadcrumbs-wrap .breadcrumbs-inner .breadcrumbs-text .back-nav ul li {
    display: inline;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    opacity: .8
}

.react-breadcrumbs .breadcrumbs-wrap .breadcrumbs-inner .breadcrumbs-text .back-nav ul li a {
    color: #fff
}

.react-breadcrumbs .breadcrumbs-wrap .breadcrumbs-inner .breadcrumbs-text .back-nav ul li a:hover {
    opacity: .7
}

.react-breadcrumbs .breadcrumbs-wrap .breadcrumbs-inner .breadcrumbs-text .back-nav ul li:after {
    content: "";
    margin: 0 6px 0 12px;
    background: #fff;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    display: inline-block
}

.react-breadcrumbs .breadcrumbs-wrap .breadcrumbs-inner .breadcrumbs-text .back-nav ul li:last-child:after {
    content: unset;
    margin: 0
}

.react-breadcrumbs.react-breadcrumbs-single .container {
    max-width: 1200px
}

.react-breadcrumbs.react-breadcrumbs-single .container .single-cate {
    background: #fff;
    color: #00306e;
    display: inline-block;
    padding: 4px 18px;
    border-radius: 30px;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 20px
}

.react-breadcrumbs.react-breadcrumbs-single .react-breadcrumbs .breadcrumbs-wrap .breadcrumbs-inner {
    top: 61%
}

.react-breadcrumbs.react-breadcrumbs-single .breadcrumbs-wrap .breadcrumbs-inner .breadcrumbs-text {
    text-align: left
}

.react-breadcrumbs.react-breadcrumbs-blog-single .container {
    max-width: 1200px
}

.react-breadcrumbs.react-breadcrumbs-blog-single .breadcrumbs-wrap .breadcrumbs-inner .breadcrumbs-text {
    text-align: left
}

.react-breadcrumbs.react-breadcrumbs-blog-single .breadcrumbs-wrap .breadcrumbs-inner .breadcrumbs-text span {
    font-size: 16px;
    color: #fff;
    margin-bottom: 15px;
    display: block
}

.react-breadcrumbs.react-breadcrumbs-blog-single .breadcrumbs-wrap .breadcrumbs-inner .breadcrumbs-text .back-nav ul li {
    font-size: 14px;
    color: #fff;
    margin-right: 30px;
    opacity: 1
}

.react-breadcrumbs.react-breadcrumbs-blog-single .breadcrumbs-wrap .breadcrumbs-inner .breadcrumbs-text .back-nav ul li svg {
    width: 17px;
    height: 17px;
    margin-right: 4px;
    position: relative;
    top: -2px
}

.react-breadcrumbs.react-breadcrumbs-blog-single .breadcrumbs-wrap .breadcrumbs-inner .breadcrumbs-text .back-nav ul li:after {
    display: none
}

.react_featured_online {
    background: #F5F5F5
}

.react_featured_online .container {
    max-width: 1200px
}

.react_featured_online .container .border-btns {
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
    border: 2px solid rgba(0, 48, 110, .1);
    padding: 12px 35px;
    color: #00306E;
    display: inline-block;
    margin-top: 30px;
    line-height: 20px
}

.react_featured_online .container .border-btns svg {
    width: 18px;
    rotate: 180deg
}

.react_featured_online .container .border-btns:hover {
    background: #3270FC;
    color: #fff
}

.react_featured_online .item__inner {
    display: flex;
    background: #fff;
    box-shadow: 0 1px 2px rgba(15, 18, 22, .1);
    border-radius: 6px;
    padding: 35px 30px;
    align-items: center;
    margin-bottom: 23px
}

.react_featured_online .item__inner .icon {
    flex: 0 0 45%
}

.react_featured_online .item__inner .icon img {
    height: 130px
}

.react_featured_online .item__inner .react-content {
    flex: 0 0 55%
}

.react_featured_online .item__inner .react-content h5 {
    margin: 0;
    color: #D2093C;
    font-size: 20px;
    font-weight: 600
}

.react_featured_online .item__inner .react-content h3 {
    margin: 10px 0 18px;
    color: #D2093C;
    font-size: 20px;
    font-weight: 600
}

.react_featured_online .item__inner .react-content h3 a {
    color: #0F1216
}

.react_featured_online .item__inner .react-content h3 a:hover {
    color: #3270FC
}

.react_featured_online .item__inner .react-content ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center
}

.react_featured_online .item__inner .react-content ul li {
    color: #5B5F64;
    font-size: 16px;
    line-height: 20px;
    padding-left: 30px
}

.react_featured_online .item__inner .react-content ul li svg {
    width: 18px;
    position: relative;
    top: -2px;
    margin-left: 2px
}

.react_featured_online .item__inner .react-content ul li:last-child {
    padding-right: 0
}

.react_featured_online .item__inner:hover {
    box-shadow: 0 30px 30px rgba(15, 18, 22, .1)
}

.accordion__area {
    position: relative
}

.accordion__area .accordion__wrapper-1 h2 {
    font-size: 44px;
    font-weight: 700;
    color: #0F1216;
    margin: 0 0 10px;
    line-height: 54px
}

.accordion__area .accordion__wrapper-1 h6 {
    font-size: 16px;
    line-height: 20px;
    color: #D2093C;
    font-weight: 600;
    margin: 0
}

.accordion__area .accordion__wrapper-1 p {
    font-size: 16px;
    color: #5B5F64;
    line-height: 26px
}

.accordion__area .accordion__wrapper-1 a.border-btns {
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
    border: 2px solid rgba(0, 48, 110, .1);
    padding: 12px 35px;
    color: #00306E;
    display: inline-block;
    margin-top: 10px;
    line-height: 20px
}

.accordion__area .accordion__wrapper-1 a.border-btns svg {
    width: 18px;
    rotate: 180deg
}

.accordion__area .accordion__wrapper-1 a.border-btns:hover {
    background: #3270FC;
    color: #fff
}

.accordion__area .accordion-item {
    border: none;
    margin-bottom: 10px;
    box-shadow: 0 20px 40px rgba(15, 18, 22, .1);
    border-radius: 6px
}

.accordion__area .accordion-button {
    padding: 20px 37px;
    border-radius: 6px;
    border: none
}

.accordion__area .accordion .accordion-header .accordion-button {
    font-size: 18px;
    font-weight: 600;
    color: #020334;
    border-radius: 0;
    box-shadow: none
}

.accordion__area .accordion .accordion-header .accordion-button::after {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    left: 28px;
    top: 45%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-family: ElegantIcons;
    font-size: 22px;
    color: #091524;
    background-image: none;
    font-weight: 300;
    content: "\33"
}

.accordion__area .accordion .accordion-header .accordion-button:focus {
    box-shadow: none
}

.accordion__area .accordion .accordion-header .accordion-button.collapsed::after {
    content: "\32"
}

.accordion__area .accordion__shape img {
    position: absolute
}

.accordion__area .accordion__shape-1 {
    left: 39%;
    bottom: -14%;
    z-index: -1
}

.accordion__area .accordion__shape-1a {
    right: 7%;
    top: 72%;
    z-index: 1
}

.accordion__area.accordion__area .accordion-button:not(.collapsed) {
    background: 0 0;
    color: #3270FC;
    font-size: 20px
}

.accordion__area.accordion__area .accordion-button:not(.collapsed):after {
    color: #3270FC
}

.accordion__area.accordion__area .accordion-button:hover {
    background: 0 0;
    color: #3270FC;
    font-size: 20x
}

.accordion__area.accordion__area .accordion-button:hover:after {
    color: #3270FC
}

.accordion__area .accordion .accordion-body {
    padding-top: 0
}

.accordion__area .accordion .accordion-body p {
    font-size: 16px;
    font-weight: 400;
    color: #5B5F64;
    margin: 0;
    padding: 0 15px 24px
}

/* .popular__course__area .container , */
.instructor__area .container {
    max-width: 1590px
}

.instructor__area .container .instructors_lefts {
    text-align: right;
    padding-left: 120px
}

.instructor__area .container .instructors_lefts h2 {
    font-size: 44px;
    font-weight: 700;
    color: #0F1216;
    margin: 0 0 10px;
    line-height: 54px
}

.instructor__area .container .instructors_lefts h6 {
    font-size: 16px;
    line-height: 20px;
    color: #D2093C;
    font-weight: 600;
    margin: 0
}

.instructor__area .container .instructor__content-one {
    box-shadow: none !important
}

.instructor__area .instructor__content h4, .instructor__area .instructor__content p {
    margin: 0
}

.instructor__area .instructor__pre-title {
    font-size: 16px;
    font-weight: 600;
    color: #2a6df5
}

.instructor__area .instructor__content {
    display: flex;
    flex-direction: column;
    padding-top: 34px;
    padding-bottom: 34px
}

.instructor__area .instructor__content-1 img {
    background-size: cover;
    border: 5px solid #fff;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    box-shadow: 0 10px 30px rgba(15, 18, 22, .1)
}

.instructor__area .instructor__content-2 {
    margin-top: 15px
}

.instructor__area .instructor__content-2 h4 a {
    font-size: 20px;
    font-weight: 600;
    color: #0F1216
}

.instructor__area .instructor__content-2 h4 a:hover {
    color: #2a6df5;
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

.instructor__area .instructor__content-2 p {
    margin-top: 0;
    font-size: 16px;
    font-weight: 400;
    color: #5B5F64;
    line-height: 20px
}

.instructor__area .instructor__content-3 {
    margin-top: 5px
}

.instructor__area .instructor__content-3 ul li {
    display: inline-block;
    margin: 0 7px
}

.instructor__area .instructor__content-3 ul li:not(:last-child) {
    margin-right: 7px
}

.instructor__area .instructor__content-3 ul li a {
    font-size: 14px;
    font-weight: 500;
    color: #58606c;
    display: inline-block;
    text-align: center
}

.instructor__area .instructor__content-3 ul li a:hover {
    color: #2a6df5;
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

.instructor__area .instructor__content:hover {
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 30px 40px 0 rgba(1, 11, 60, .06);
    width: 100%;
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

.instructors___page .instructor__content {
    margin-bottom: 30px
}

.instructors___page .instructor__content .instructor__image {
    position: relative
}

.instructors___page .instructor__content .instructor__image img {
    border-radius: 4px;
    width: 100%
}

.instructors___page .instructor__content .instructor__image .content__hover {
    position: absolute;
    bottom: -50px;
    right: 0;
    left: 0;
    padding: 25px;
    opacity: 0;
    transition: all .5s ease
}

.instructors___page .instructor__content .instructor__image .content__hover p {
    margin: 0;
    color: #fff;
    font-size: 16px;
    line-height: 20px
}

.instructors___page .instructor__content .instructor__image .content__hover ul {
    display: flex;
    justify-content: start;
    margin-left: -5px;
    margin-top: 20px
}

.instructors___page .instructor__content .instructor__image .content__hover ul li {
    margin: 0 4px
}

.instructors___page .instructor__content .instructor__image .content__hover ul li a {
    border: 2px solid rgba(255, 255, 255, .1);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    line-height: 37px;
    text-align: center;
    display: inline-block;
    color: #fff
}

.instructors___page .instructor__content .instructor__image .content__hover ul li a span {
    font-size: 14px
}

.instructors___page .instructor__content .instructor__image .content__hover ul li a:hover {
    background: #3270FC;
    border-color: #3270FC
}

.instructors___page .instructor__content .instructor__image:before {
    content: "";
    background: linear-gradient(180deg, rgba(15, 18, 22, 0) 46.67%, rgba(15, 18, 22, .9) 91%);
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    opacity: 0;
    transition: all .5s ease
}

.instructors___page .instructor__content .bottom-content {
    text-align: center;
    margin-top: 20px;
    transition: all .5s ease
}

.instructors___page .instructor__content .bottom-content h4 {
    margin: 0;
    font-size: 20px;
    font-weight: 700
}

.instructors___page .instructor__content .bottom-content h4 a {
    color: #0F1216
}

.instructors___page .instructor__content .bottom-content h4 a:hover {
    color: #3270fc
}

.instructors___page .instructor__content .bottom-content p {
    margin: 0;
    font-size: 16px;
    color: #5B5F64
}

.instructors___page .instructor__content:hover .instructor__image:before {
    opacity: 1
}

.instructors___page .instructor__content:hover .content__hover {
    opacity: 1;
    bottom: 0
}

.instructors___page .instructor__content:hover .bottom-content {
    opacity: 0
}

.profile-page .profile-top {
    background: #fff
}

.profile-page .profile-top .follows {
    background: #3270FC;
    border-radius: 6px;
    color: #fff;
    padding: 8px 25px;
    text-align: center;
    display: block;
    max-width: 356px;
    font-weight: 600;
    font-size: 16px;
    margin-top: 10px
}

.profile-page .profile-top .follows svg {
    width: 18px
}

.profile-page .profile-top .follows:hover {
    opacity: .9
}

.profile-page .profile-top .teacher__course {
    padding-top: 0;
    margin-top: 50px;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 40px;
    line-height: 30px
}

.profile-page .profile-top .follow {
    border: 2px solid #e8eaf0;
    padding: 7px 24px;
    color: #00306e;
    font-size: 15px;
    font-weight: 600;
    border-radius: 5px
}

.profile-page .profile-top .follow:hover {
    background: #3270fc;
    border-color: #3270fc;
    color: #fff
}

.profile-page .profile-top .user-section {
    display: flex;
    align-items: center;
    padding-bottom: 30px;
    border-bottom: 1px solid #e8eaf0;
    margin-bottom: 30px
}

.profile-page .profile-top .user-section li {
    padding-right: 60px;
    line-height: 1.5;
    font-size: 16px;
    color: #0F1216;
    font-weight: 600
}

.profile-page .profile-top .user-section li em {
    display: block;
    font-style: normal;
    color: #5B5F64;
    font-weight: 400
}

.profile-page .profile-top .user-section li .back-ratings i {
    font-size: 14px;
    color: #ff9415
}

.profile-page .profile-top .user-section li.social a {
    color: #898a93;
    margin: 0 6px 0 0;
    font-size: 13px
}

.profile-page .profile-top .user-section li.social a:hover {
    color: #3270fc
}

.profile-page .profile-top h3 {
    font-size: 26px;
    font-weight: 600;
    color: #0F1216
}

.profile-page .react-course-filter.related__course {
    padding-top: 20px
}

.profile-page .count__area2 .count__content {
    display: flex;
    align-items: center;
    position: relative;
    border: 1px solid rgba(0, 48, 110, .1);
    border-radius: 6px;
    padding: 30px 25px
}

.profile-page .count__area2 .count__content .icon {
    margin-left: 15px
}

.profile-page .count__area2 .count__content .icon img {
    position: relative;
    top: -2px
}

.profile-page .count__area2 .count__content .text {
    flex: 1
}

.profile-page .count__area2 .count__content .text span {
    margin: 0 0 5px;
    font-weight: 400;
    font-size: 40px;
    line-height: 50px;
    color: #0F1216
}

.profile-page .count__area2 .count__content .text em {
    margin: 0 0 5px;
    font-weight: 400;
    font-size: 40px;
    line-height: 50px;
    color: #0F1216;
    font-style: normal
}

.profile-page .count__area2 .count__content .text p {
    margin: 0;
    font-size: 16px;
    line-height: 18px;
    color: #5B5F64
}

.react-clients {
    background: url(../Images/testimonial/bg.jpg) center top no-repeat;
    background-size: cover
}

.react-clients .react__title__section h2 {
    font-size: 44px;
    font-weight: 700;
    color: #0F1216;
    margin: 0 0 10px;
    line-height: 54px
}

.react-clients .react__title__section h6 {
    font-size: 16px;
    line-height: 20px;
    color: #D2093C;
    font-weight: 600;
    margin: 0
}

.react-clients .client-slider {
    margin: 0 auto;
    padding: 0;
    list-style: none;
    max-width: 1100px;
    position: relative
}

.react-clients .client-slider .single-client {
    position: relative;
    background: #fff;
    padding: 50px 60px;
    border-radius: 10px;
    transition: all .5s ease 0s;
    overflow: hidden;
    border: 2px solid;
    border-color: rgba(2, 3, 52, .05);
    display: flex;
    align-items: center;
    direction: rtl
}

.react-clients .client-slider .single-client .client-title {
    color: #0F1216;
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 15px;
    display: block
}

.react-clients .client-slider .single-client .client-title em {
    color: #5B5F64;
    font-size: 16px;
    font-weight: 400;
    display: block;
    font-style: normal
}

.react-clients .client-slider .single-client .client-content {
    position: relative;
    padding-left: 40px
}

.react-clients .client-slider .single-client .client-content p {
    font-size: 20px;
    color: #5B5F64;
    line-height: 32px
}

.react-clients .client-slider .single-client .client-content .testimonial__ratings {
    font-size: 16px;
    color: #5B5F64;
    font-weight: 600
}

.react-clients .client-slider .single-client .client-content .testimonial__ratings em {
    color: #FFA439
}

.react-clients .client-slider .single-client .client-content .testimonial__ratings span em {
    color: #0F1216;
    font-weight: 600;
    font-style: normal
}

.react-clients .client-slider .single-client .client-content .comma {
    width: auto;
    position: absolute;
    left: 25px;
    bottom: -31%;
    z-index: 11
}

.react-clients .client-slider .single-client .client-author {
    padding: 0;
    width: 230px;
    display: block;
    margin-left: 70px
}

.react-clients .client-slider .single-client .client-author img {
    border-radius: 6px
}

.react-clients .client-slider.owl-carousel .owl-controls {
    position: unset;
    transform: unset;
    width: auto
}

.react-clients .client-slider.owl-carousel .owl-nav {
    position: absolute;
    display: block;
    top: 40%;
    right: unset;
    width: 100%;
    opacity: 1;
    transition: all .5s ease 0s
}

.react-clients .client-slider.owl-carousel .owl-nav [class*=owl-] {
    position: absolute;
    left: 20px;
    display: inline-block;
    font-size: 0;
    width: 70px;
    height: 70px;
    line-height: 67px;
    color: #00306e;
    background: #fff;
    border-radius: 100%;
    transition: all .5s ease 0s;
    border: 4px solid #F2F4F9;
    box-shadow: -6px 20px 30px rgba(15, 18, 22, .06)
}

.react-clients .client-slider.owl-carousel .owl-nav [class*=owl-]:before {
    content: "\35";
    font-family: ElegantIcons;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 26px;
    font-weight: 700;
    line-height: 60px
}

.react-clients .client-slider.owl-carousel .owl-nav [class*=owl-].owl-next {
    right: auto;
    left: -35px;
    rotate: 180deg
}

.react-clients .client-slider.owl-carousel .owl-nav [class*=owl-].owl-prev {
    right: -35px;
    left: auto;
    rotate: 180deg
}

.react-clients .client-slider.owl-carousel .owl-nav [class*=owl-].owl-prev:before {
    content: '\34'
}

.react-clients .client-slider.owl-carousel .owl-nav [class*=owl-]:hover {
    color: #fff;
    background: #3270FC;
    border-color: #3270FC
}

.react-clients.react-clientso {
    background: url(../Images/testimonial/bg3.png) center top no-repeat;
    background-size: cover
}

.react-clients.home-testimonial {
    background: #fff !important
}

.react-clients.home-testimonial .react__title__section h2 {
    color: #00306e
}

.react-clients.home-testimonial .client-slider.owl-carousel .owl-nav [class*=owl-]:hover {
    background: #d2093c;
    border-color: #d2093c
}

.react-blog__area.graybg-home {
    background: #F5F5F5
}

.react-blog__area.graybg-home .react__title__section h2 {
    color: #00306e
}

.react-blog__area.graybg-home .b_date {
    background: #D2093C
}

.react-blog__area.graybg-home .blog__card--title a {
    color: #00306e
}

.react-blog__area.graybg-home .blog__card--title a:hover {
    color: #D2093C
}

.react-blog__area .blog__subtitle {
    font-size: 16px;
    font-weight: 500;
    color: #2a6df5
}

.react-blog__area .react__title__section h2 {
    font-size: 44px;
    font-weight: 700;
    color: #0F1216;
    margin: 0 0 10px;
    line-height: 54px
}

.react-blog__area .react__title__section h6 {
    font-size: 16px;
    line-height: 20px;
    color: #D2093C;
    font-weight: 600;
    margin: 0
}

.react-blog__area .blog__card {
    border-radius: 6px;
    box-shadow: 0 30px 60px 0 rgba(0, 15, 56, .1);
    margin: 0 3px
}

.react-blog__area .blog__thumb {
    position: relative;
    overflow: hidden
}

.react-blog__area .blog__thumb--image img {
    border-radius: 6px 6px 0 0;
    transition: all .5s ease 0s
}

.react-blog__area .blog__thumb--image img:hover {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1)
}

.react-blog__area .b_date {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    padding: 4px 12px 5px;
    color: #fff;
    background-color: #3270FC;
    border-radius: 4px;
    position: absolute;
    z-index: 1;
    left: 6%;
    top: 8%;
    font-style: normal
}

.react-blog__area .blog__card--content {
    padding: 28px 36px 32px 37px
}

.react-blog__area .blog__card--date {
    font-size: 16px;
    font-weight: 400;
    color: #D2093C
}

.react-blog__area .blog__card--title {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    padding: 5px 0 0;
    margin: 0 0 16px
}

.react-blog__area .blog__card--title a {
    color: #0F1216
}

.react-blog__area .blog__card--title:hover a {
    color: #3270FC
}

.react-blog__area .blog__card--link {
    font-size: 15px;
    font-weight: 600;
    line-height: 28px;
    color: #00306e
}

.react-blog__area .blog__card--link:hover {
    color: #3270fc
}

.react-blog__area .blog__card--link-icon {
    vertical-align: middle;
    padding-left: 5px
}

.react-blog__area .blog__card--icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative
}

.react-blog__area .blog__card--icon-1 svg {
    height: 17px;
    line-height: 10px;
    vertical-align: sub;
    color: #5B5F64
}

.react-blog__area .blog__card--icon-1 span {
    font-size: 16px;
    font-weight: 400;
    color: #5B5F64;
    padding-left: 2px;
    line-height: 10px;
    vertical-align: middle
}

.react-blog__area .blog__card--icon-2 {
    display: flex
}

.react-blog__area .blog__card--icon-2-first {
    margin-right: 17px
}

.react-blog__area .blog__card--icon-2-first svg {
    height: 17px
}

.react-blog__area .blog__card--icon-2-first span {
    vertical-align: middle
}

.react-blog__area .blog__card--icon-2-second svg {
    height: 17px
}

.react-blog__area .blog__card--icon-2-second span {
    vertical-align: middle
}

.campus_sec {
    background: url(../Images/campus/cam.png) center top no-repeat;
    background-size: cover
}

.campus_sec .react__title__section img {
    margin-left: 120px
}

.campus_sec .about__image {
    position: relative
}

.campus_sec .about__image .shape-1 {
    position: absolute;
    bottom: 90px;
    left: 5px
}

.campus_sec .about__content {
    background: #fff;
    padding: 50px 60px;
    margin-left: 15px
}

.campus_sec .about__content ul {
    margin: 0;
    padding: 0
}

.campus_sec .about__content ul li {
    display: flex;
    align-items: center;
    border-top: 1px solid #EDEDED;
    padding: 30px 0
}

.campus_sec .about__content ul li:first-child {
    border-top: none
}

.campus_sec .about__content ul li .icon {
    margin-left: 15px
}

.campus_sec .about__content ul li .icon img {
    margin-top: -50px
}

.campus_sec .about__content ul li .text {
    flex: 1
}

.campus_sec .about__content ul li .text h4 {
    margin: 0 0 14px;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px
}

.campus_sec .about__content ul li .text p {
    margin: 0;
    font-size: 16px;
    line-height: 22px;
    color: #4D5765
}

.campus_sec .about__content ul li a {
    border-radius: 50%;
    width: 44px;
    height: 44px;
    border: 2px solid #EDEDED;
    text-align: center;
    line-height: 36px
}

.campus_sec .about__content ul li a svg {
    width: 18px;
    color: #00306e;
    rotate: 180deg
}

.campus_sec .about__content ul li a:hover {
    background: #00306e;
    color: #fff
}

.campus_sec .about__content ul li a:hover svg {
    color: #fff
}

.campus_sec .more-about {
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
    border: 2px solid rgba(0, 48, 110, .1);
    padding: 12px 35px;
    color: #00306E;
    display: inline-block;
    margin-top: 60px;
    line-height: 20px
}

.campus_sec .more-about svg {
    width: 20px;
    rotate: 180deg
}

.campus_sec .more-about:hover {
    background: #D2093C;
    color: #fff;
    border-color: #D2093C
}

.popular__course__area {
    background: #fff;
    position: relative
}

.popular__course__area .course__item {
    box-shadow: 0 20px 60px 0 rgba(0, 15, 56, .1)
}

.popular__course__area .course__item .course__inner {
    padding: 25px 0 17px
}

.popular__course__area .course__item .course__inner ul {
    display: flex;
    justify-content: start;
    padding-left: 25px;
    padding-right: 25px
}

.popular__course__area .course__item .course__inner ul li {
    font-size: 16px;
    line-height: 22px;
    color: #00306E;
    position: relative;
    padding-right: 16px;
    margin-left: 30px
}

.popular__course__area .course__item .course__inner ul li:before {
    content: "";
    position: absolute;
    right: 0;
    background: #00306e;
    width: 6px;
    height: 6px;
    top: 9px;
    border-radius: 50%
}

.popular__course__area .course__item .course__inner ul li:first-child {
    color: #D2093C
}

.popular__course__area .course__item .course__inner ul li:first-child:before {
    background: #D2093C
}

.popular__course__area .course__item .course__inner ul li:last-child {
    margin-right: 0
}

.popular__course__area .course__item .react-course-title {
    font-size: 20px;
    line-height: 25px;
    margin: 14px 0 30px;
    font-weight: 600;
    padding-left: 25px;
    padding-right: 25px
}

.popular__course__area .course__item .react-course-title a {
    color: #00306e
}

.popular__course__area .course__item .react-course-title a:hover {
    color: #D2093C
}

.popular__course__area .course__item .course__card-icon {
    justify-content: space-between;
    /* border-top: 1px solid #EDEDED; */
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 15px
}

.popular__course__area .course__item .course__card-icon .course__card-icon--1 svg {
    color: #00306e;
    width: 18px
}

.popular__course__area .course__item .course__card-icon .course__card-icon--1 span {
    font-weight: 400;
    font-size: 16px;
    color: #4D5765;
    padding-right: 7px
}

.popular__course__area .course__item .course__card-icon .react__user {
    font-size: 20px;
    color: #D2093C;
    font-weight: 700
}

.popular__course__area .view-courses {
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
    border: 2px solid rgba(0, 48, 110, .1);
    padding: 12px 35px;
    color: #00306E;
    display: inline-block;
    margin-top: 60px;
    line-height: 20px
}

.popular__course__area .view-courses svg {
    width: 20px;
    rotate: 180deg
}

.popular__course__area .view-courses:hover {
    background: #D2093C;
    color: #fff;
    border-color: #D2093C
}

.count__area .count__width {
    background: #D2093C;
    padding-top: 50px;
    padding-bottom: 50px
}

.count__area .count__width .col-lg-3 {
    border-left: 1px solid rgba(255, 255, 255, .2)
}

.count__area .count__width .col-lg-3:last-child {
    border-left: none
}

.count__area .count__width .count__content {
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px
}

.count__area .count__width .count__content .count__content--paragraph {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    margin: 0;
    color: #fff
}

.count__area .count__width .count__content .count__content--title-1 {
    font-size: 60px;
    line-height: 75px;
    font-weight: 700;
    margin: 8px 0 5px;
    color: #fff
}

.count__area .count__width .count__content .count__content--paragraph2 {
    font-size: 12px;
    line-height: 15px;
    font-weight: 600;
    margin: 0;
    letter-spacing: 3px;
    color: #fff;
    text-transform: uppercase
}

.react-upcoming__event {
    background: #F5F5F5;
    padding-top: 98px;
    padding-bottom: 106px
}

.react-upcoming__event .event__card {
    background: #fff;
    padding: 25px 32px 42px;
    direction: rtl
}

.react-upcoming__event .event__card .event__card--date {
    font-size: 16px;
    line-height: 20px;
    color: #4D5765
}

.react-upcoming__event .event__card .event__card--date em {
    font-size: 50px;
    line-height: 62px;
    font-weight: 700;
    font-style: normal;
    display: block;
    color: #D2093C
}

.react-upcoming__event .event__card .event_time {
    font-size: 16px;
    color: #D2093C;
    line-height: 22px;
    margin: 28px 0 4px;
    direction: ltr;
    text-align: end
}

.react-upcoming__event .event__card .event__card--title {
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
    margin: 0
}

.react-upcoming__event .event__card .event__card--title a {
    color: #00306e
}

.react-upcoming__event .event__card .event__card--title a:hover {
    color: #3270fc
}

.react-upcoming__event .event__card .event_location {
    font-size: 16px;
    color: #4D5765;
    margin: 24px 0
}

.react-upcoming__event .event__card .event_location svg {
    width: 18px;
    color: #00306e;
    position: relative;
    top: -1px;
    margin-left: 2px
}

.react-upcoming__event .event__card .event__card--link {
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
    border: 2px solid rgba(0, 48, 110, .1);
    padding: 8px 35px 7px;
    color: #00306E;
    display: inline-block;
    line-height: 20px;
    width: 100%;
    text-align: center
}

.react-upcoming__event .event__card .event__card--link svg {
    width: 20px;
    rotate: 180deg
}

.react-upcoming__event .event__card .event__card--link:hover {
    background: #D2093C;
    color: #fff;
    border-color: #D2093C
}

.react-upcoming__event .owl-dots {
    text-align: center;
    margin-top: 50px
}

.react-upcoming__event .owl-dots .owl-dot span {
    width: 12px;
    height: 12px;
    background: #C4C4C4;
    display: inline-block;
    border-radius: 50%;
    margin: 0 5px
}

.react-upcoming__event .owl-dots .owl-dot.active span {
    background: #D2093C
}

.back__course__page_grid .shorting__course {
    background: #fff;
    box-shadow: 0 1px 2px rgba(15, 18, 22, .1);
    border-radius: 6px;
    padding: 15px 20px;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 50px
}

.back__course__page_grid .shorting__course .all__icons {
    display: flex;
    align-items: center;
    flex-wrap: wrap
}

.back__course__page_grid .shorting__course .all__icons svg {
    cursor: pointer;
    transform: rotate(90deg);
    position: relative;
    width: 18px;
    color: #3270FC;
    top: -2px
}

.back__course__page_grid .shorting__course .all__icons svg.feather-list {
    color: #5c5c68;
    width: 28px;
    height: 28px
}

.back__course__page_grid .shorting__course .all__icons .result-count {
    padding-left: 10px;
    color: #0F1216;
    font-size: 16px;
    font-weight: 400
}

.back__course__page_grid .shorting__course .from-control {
    background: #F2F4F9;
    border: none;
    padding: 12px 15px;
    border-radius: 6px;
    color: #5c5c68;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    border-left: 15px solid #F2F4F9;
    margin-right: 15px
}

.back__course__page_grid .shorting__course2 {
    background: 0 0;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 20px
}

.back__course__page_grid .shorting__course2 .col-md-6 {
    padding: 0
}

.back__course__page_grid .shorting__course2 .all__icons .result-count {
    padding-left: 10px;
    color: #5B5F64;
    font-size: 16px;
    font-weight: 400
}

.back__course__page_grid .shorting__course2 .from-control {
    background: 0 0;
    border: none;
    padding: 12px 15px;
    border-radius: 6px;
    color: #5B5F64;
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
    margin-left: 15px
}

.back__course__page_grid .shorting__courses3 {
    margin-left: 0;
    margin-right: 0;
    padding: 10px 0;
    border-radius: 6px;
    margin-bottom: 30px
}

.back__course__page_grid .shorting__courses3 .col-md-6 {
    padding: 0
}

.back__course__page_grid .shorting__courses3 .all__icons {
    display: flex;
    align-items: center;
    flex-wrap: wrap
}

.back__course__page_grid .shorting__courses3 .all__icons svg {
    cursor: pointer;
    position: relative;
    top: -2px
}

.back__course__page_grid .shorting__courses3 .all__icons .grid__icons {
    display: flex;
    align-items: center;
    border: 2px solid rgba(91, 95, 100, .1);
    border-radius: 6px;
    padding: 0 10px 0 0
}

.back__course__page_grid .shorting__courses3 .all__icons .grid__icons .grid__icons_inner {
    width: 22px;
    margin-left: 3px
}

.back__course__page_grid .shorting__courses3 .all__icons .grid__icons span {
    background: #3270FC;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin: 1px;
    float: left
}

.back__course__page_grid .shorting__courses3 .all__icons .list__icons {
    border-right: 2px solid #EFEFF0;
    margin-right: 5px;
    padding: 4px 10px
}

.back__course__page_grid .shorting__courses3 .all__icons .result-count {
    padding-right: 40px;
    color: #5c5c68;
    font-size: 15px;
    font-weight: 600
}

.back__course__page_grid .shorting__courses3 .from-control {
    background: #F2F4F9;
    border: none;
    padding: 10px 15px;
    border-radius: 6px;
    color: #5c5c68;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    border-left: 15px solid #F2F4F9
}

.back__course__page_grid.back__course__page_grid_left {
    background: 0 0
}

.back__course__page_grid.back__course__page_grid_left .single-studies .inner-course {
    box-shadow: 0 1px 2px rgba(15, 18, 22, .1);
    border-radius: 6px
}

.back__course__page_grid.back__course__page_grid_left .back-pagination {
    justify-content: start
}

.back__course__page_grid.back__course__page_grid_left .back-pagination li a {
    border: 2px solid rgba(15, 18, 22, .1);
    box-shadow: none;
    line-height: 34px
}

.back__course__page_grid.back__course__page_grid_left .back-pagination li a:hover {
    border-color: #3270FC
}

.back__course__page_grid.back__course__page_grid_left .react-list .single-studies .inner-course {
    display: flex;
    background: #fff;
    box-shadow: 0 30px 30px rgba(15, 18, 22, .1);
    border-radius: 6px
}

.back__course__page_grid.back__course__page_grid_left .react-list .single-studies .inner-course .case-img {
    flex: 0 0 56%
}

.back__course__page_grid.back__course__page_grid_left .react-list .single-studies .inner-course .case-img img {
    border-radius: 0
}

.back__course__page_grid.back__course__page_grid_left .react-list .single-studies .inner-course .case-content {
    flex: 0 0 44%
}

.react-upcoming__event_page {
    background: #F2F4F9
}

.react-upcoming__event_page .event__card {
    margin-bottom: 25px
}

.react-upcoming__event_page .shorting__course3 {
    margin-left: 0;
    margin-right: 0;
    padding: 10px 0;
    border-radius: 6px;
    margin-bottom: 20px
}

.react-upcoming__event_page .shorting__course3 .col-md-6 {
    padding: 0
}

.react-upcoming__event_page .shorting__course3 .all__icons {
    display: flex;
    align-items: center;
    flex-wrap: wrap
}

.react-upcoming__event_page .shorting__course3 .all__icons .result-count {
    padding-left: 0;
    color: #5c5c68;
    font-size: 15px;
    font-weight: 600
}

.react-upcoming__event_page .shorting__course3 .from-control {
    background: #fff;
    border: none;
    padding: 13px 15px;
    border-radius: 6px;
    color: #5c5c68;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    border-left: 15px solid #fff;
    box-shadow: 0 1px 2px rgba(15, 18, 22, .1);
    width: 100%;
    max-width: 300px
}

.react-upcoming__event_list {
    background: #fff
}

.react-upcoming__event_list .event__card {
    margin-bottom: 25px;
    padding: 0;
    background: #fff;
    box-shadow: 0 1px 2px rgba(15, 18, 22, .1);
    border-radius: 6px
}

.react-upcoming__event_list .event__card .event__card--content {
    padding: 25px 25px 42px
}

.react-upcoming__event_list .event__card .event_location {
    margin-bottom: 0;
    margin-top: 16px
}

.react-upcoming__event_list .event__card .event__card--date {
    color: #3270fc;
    margin-bottom: 8px
}

.react-upcoming__event_list .shorting__course3 {
    margin-left: 0;
    margin-right: 0;
    padding: 10px 0;
    border-radius: 6px;
    margin-bottom: 20px
}

.react-upcoming__event_list .shorting__course3 .col-md-6 {
    padding: 0
}

.react-upcoming__event_list .shorting__course3 .all__icons {
    display: flex;
    align-items: center;
    flex-wrap: wrap
}

.react-upcoming__event_list .shorting__course3 .all__icons .result-count {
    padding-left: 0;
    color: #5c5c68;
    font-size: 15px;
    font-weight: 600
}

.react-upcoming__event_list .shorting__course3 .from-control {
    background: #fff;
    border: none;
    padding: 13px 15px;
    border-radius: 6px;
    color: #5c5c68;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    border-right: 15px solid #fff;
    box-shadow: 0 1px 2px rgba(15, 18, 22, .1);
    width: 100%;
    max-width: 300px
}

.react-upcoming__event_list ul.related-courses {
    margin: 0;
    padding: 0;
    list-style: none
}

.react-upcoming__event_list ul.related-courses li {
    display: flex;
    align-items: center;
    padding-bottom: 1.2em
}

.react-upcoming__event_list ul.related-courses li .back-ratings i {
    font-size: 10px;
    color: #ff9415
}

.react-upcoming__event_list ul.related-courses li .post-images {
    width: 87px;
    display: block
}

.react-upcoming__event_list ul.related-courses li .post-images img {
    display: block;
    width: 127px;
    border-radius: 6px
}

.react-upcoming__event_list ul.related-courses li .titles {
    display: block;
    padding-left: 20px
}

.react-upcoming__event_list ul.related-courses li .titles h4 {
    margin: 0 0 6px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px
}

.react-upcoming__event_list ul.related-courses li .titles h4 a {
    color: #0F1216
}

.react-upcoming__event_list ul.related-courses li .titles h4:hover a {
    color: #3270FC
}

.react-upcoming__event_list ul.related-courses li .titles span {
    font-weight: 600;
    color: #0F1216
}

.react-upcoming__event_list ul.related-courses li:last-child {
    padding-bottom: 0
}

.react-upcoming__event_list .react-sidebar-event {
    border: none;
    box-shadow: none
}

.react-upcoming__event_list .widget {
    background: #fff;
    box-shadow: 0 20px 40px rgba(15, 18, 22, .08);
    border-radius: 6px
}

.react-upcoming__event_list .widget .event-input, .react-upcoming__event_list .widget .location-input {
    position: relative
}

.react-upcoming__event_list .widget .event-input svg, .react-upcoming__event_list .widget .location-input svg {
    position: absolute;
    top: 11px;
    left: 23px;
    color: #6F7985;
    width: 18px
}

.react-upcoming__event_list .blog-form {
    border: none;
    margin-bottom: 10px
}

.react-upcoming__event_list .blog-form form .search-form {
    position: relative
}

.react-upcoming__event_list .blog-form form .search-form button {
    background: 0 0 !important;
    padding: 2px 24px;
    margin: 0;
    left: -80%
}

.react-upcoming__event_list .blog-form form input {
    width: 100%;
    height: 48px;
    padding: 10px 30px 10px 20px;
    background: #F2F4F9;
    outline: 0;
    border-radius: 6px;
    margin-bottom: 12px;
    border: 2px solid #F2F4F9
}

.react-upcoming__event_list .blog-form form input:focus {
    border: 2px solid #3270fc;
    background: 0 0
}

.react-upcoming__event_list .blog-form form select.from-control {
    background: #F2F4F9;
    border: none;
    padding: 14px 15px;
    border-radius: 6px;
    color: #5c5c68;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    border-left: 24px solid #F2F4F9;
    width: 100%;
    margin-bottom: 12px
}

.react-upcoming__event_list .blog-form form button {
    background: #3270FC;
    font-size: 16px;
    color: #fff;
    border-radius: 6px;
    padding: 14px 30px;
    display: inline-block;
    font-weight: 600;
    transition: all .5s ease 0s;
    border: none;
    outline: 0;
    box-shadow: none;
    cursor: pointer;
    margin-top: 25px
}

.react-upcoming__event_list .blog-form form button svg {
    width: 18px;
    color: #6F7985;
    transform: rotate(180deg)
}

.react-upcoming__event_list .blog-form form button:hover {
    color: #fff;
    background: #00306e
}

.react-upcoming__event_list .blog-form form ::-webkit-input-placeholder {
    color: #7b7d82;
    opacity: 1;
    font-size: 16px
}

.react-upcoming__event_list .blog-form form ::-moz-placeholder {
    color: #7b7d82;
    opacity: 1;
    font-size: 16px
}

.react-upcoming__event_list .blog-form form :-ms-input-placeholder {
    color: #7b7d82;
    opacity: 1;
    font-size: 16px
}

.react-upcoming__event_list .blog-form form :-moz-placeholder {
    color: #7b7d82;
    opacity: 1;
    font-size: 16px
}

body.courses-grid-page .react-course-filter .single-studies .inner-course .case-content ul li svg {
    width: 16px;
    margin-left: 6px
}

body.courses-grid-page .react-course-filter .single-studies .inner-course .case-content ul li svg.feather-clock {
    font-size: 16px
}

body.courses-grid-page .react-course-filter .single-studies .inner-course .case-content ul li {
    font-size: 14px;
    color: #777
}

body.courses-grid-page .react-course-filter .single-studies .inner-course .case-content .case-title {
    margin: 6px 0 15px;
    font-size: 18px;
    line-height: 26px;
    font-weight: 700
}

body.courses-grid-page .react-course-filter .single-studies .inner-course .case-content .case-title a {
    color: #00306e
}

body.courses-grid-page .react-course-filter .single-studies .inner-course .case-content .case-title a:hover {
    color: #3270fc
}

body.courses-grid-page .react-course-filter .single-studies .inner-course .case-img a {
    left: 22px;
    right: auto;
    background: #d2093c
}

body.courses-grid-page .react-course-filter .single-studies .inner-course .case-content .react-ratings {
    margin-top: 16px;
    padding-top: 12px
}

body.courses-grid-page .react-course-filter .single-studies .inner-course .case-content .react-ratings li.react-book {
    font-size: 14px;
    color: #777
}

body.courses-grid-page .react-course-filter .single-studies .inner-course .case-content .react-ratings li.react-book em {
    font-size: 14px;
    font-weight: 700;
    color: #e59818;
    font-style: normal
}

body.courses-grid-page .react-course-filter .single-studies .inner-course .case-content .react-ratings li.react-book span {
    color: #e59818;
    margin: 0 2px
}

body.courses-grid-page .react-course-filter .single-studies .inner-course .case-content .react-ratings li.price {
    color: #00306e;
    font-size: 14px;
    font-weight: 700
}

body.courses-grid-page .react-course-filter .single-studies .inner-course .case-img a {
    font-size: 12px;
    line-height: 14px;
    font-weight: 500
}

body.courses-grid-page .react-course-filter .single-studies .inner-course .case-img a img {
    position: relative;
    top: -2px
}

.react-blog-page .single-blog {
    transition: all .5s ease 0s;
    background: #fff;
    box-shadow: 0 1px 2px rgba(15, 18, 22, .14);
    border-radius: 6px;
    margin-bottom: 60px
}

.react-blog-page .single-blog.no-thum-img {
    box-shadow: 0 2px 6px rgba(15, 18, 22, .14)
}

.react-blog-page .single-blog.blog__last {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none
}

.react-blog-page .single-blog .blog-img {
    position: relative
}

.react-blog-page .single-blog .blog-img .cate {
    background: #3270fc;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    border-radius: 4px;
    padding: 5px 10px;
    line-height: 17px;
    position: absolute;
    right: 24px;
    top: 20px
}

.react-blog-page .single-blog .blog-img img {
    border-radius: 6px 6px 0 0
}

.react-blog-page .single-blog .blog-content {
    padding: 40px
}

.react-blog-page .single-blog .blog-content .top-part {
    display: flex;
    align-items: center;
    padding-bottom: 12px
}

.react-blog-page .single-blog .blog-content .top-part li {
    font-size: 15px;
    color: #737383;
    padding-left: 25px;
    font-weight: 500
}

.react-blog-page .single-blog .blog-content .top-part li svg {
    width: 18px;
    height: 18px;
    position: relative;
    top: -2px;
    margin-right: 4px
}

.react-blog-page .single-blog .blog-content .top-part li:first-child img {
    margin-left: 8px
}

.react-blog-page .single-blog .blog-content .blog-title {
    font-size: 34px;
    line-height: 40px;
    font-weight: 600;
    margin-bottom: 25px
}

.react-blog-page .single-blog .blog-content .blog-title a {
    color: #0F1216
}

.react-blog-page .single-blog .blog-content .blog-title a:hover {
    color: #3270FC
}

.react-blog-page .single-blog .blog-content .blog-desc {
    color: #55575c;
    margin-bottom: 30px
}

.react-blog-page .single-blog .blog-content .button__sec {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.react-blog-page .single-blog .blog-content .button__sec .blog-btn {
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
    border: 2px solid rgba(0, 48, 110, .1);
    padding: 12px 35px;
    color: #0F1216;
    display: inline-block;
    line-height: 20px
}

.react-blog-page .single-blog .blog-content .button__sec .blog-btn svg {
    width: 20px
}

.react-blog-page .single-blog .blog-content .button__sec .blog-btn:hover {
    background: #3270FC;
    color: #fff;
    border-color: #3270FC
}

.react-blog-page .single-blog .blog-content .button__sec .share-course {
    color: #0F1216;
    font-size: 16px;
    font-weight: 600;
    display: block;
    text-align: center;
    position: relative
}

.react-blog-page .single-blog .blog-content .button__sec .share-course em {
    width: 45px;
    color: #3270FC;
    border: 2px solid rgba(15, 18, 22, .1);
    border-radius: 50%;
    padding: 1px 13px;
    display: inline-block;
    height: 45px;
    line-height: 37px;
    margin-right: 8px
}

.react-blog-page .single-blog .blog-content .button__sec .share-course em svg {
    width: 18px;
    position: relative;
    right: 2px
}

.react-blog-page .single-blog .blog-content .button__sec .share-course span {
    position: absolute;
    background: #000;
    padding: 7px 18px;
    border-radius: 4px;
    width: 133px;
    left: 40px;
    top: 0;
    opacity: 0;
    transition: all .5s ease
}

.react-blog-page .single-blog .blog-content .button__sec .share-course span:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #000;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%)
}

.react-blog-page .single-blog .blog-content .button__sec .share-course span a {
    padding: 0 7px;
    color: #fff;
    opacity: .6
}

.react-blog-page .single-blog .blog-content .button__sec .share-course span a:hover {
    opacity: 1
}

.react-blog-page .single-blog .blog-content .button__sec .share-course:hover em {
    background: #3270FC;
    color: #fff
}

.react-blog-page .single-blog .blog-content .button__sec .share-course:hover span {
    opacity: 1;
    top: -55px
}

.react-blog-page blockquote {
    position: relative;
    margin: 40px 0 60px;
    padding: 50px 40px;
    color: #0F1216;
    box-shadow: 3px 0 0 #3270fc, 0 20px 40px rgba(15, 18, 22, .1);
    border-radius: 6px;
    font-size: 24px;
    overflow: hidden;
    line-height: 34px;
    font-weight: 400;
    z-index: 1;
    border-right: 1px solid #3270FC
}

.react-blog-page blockquote:before {
    content: "";
    font-size: 120px;
    position: absolute;
    background: url(../Images/blog-grid/qu.png) right bottom no-repeat;
    bottom: 0;
    left: 47px;
    width: 107px;
    height: 107px;
    z-index: 0
}

.react-blog-page blockquote.block__link_q {
    padding: 30px 50px 35px 95px
}

.react-blog-page blockquote.block__link_q:before {
    content: "";
    font-size: 120px;
    position: absolute;
    background: url(../Images/blog-grid/link.png) right bottom no-repeat;
    top: 50%;
    left: 40px;
    width: 30px;
    height: 30px;
    z-index: -1;
    transform: translateY(-50%)
}

.react-blog-page blockquote em {
    display: block;
    font-style: normal;
    color: #5B5F64;
    font-size: 16px;
    margin-top: 16px;
    font-weight: 400;
    position: relative;
    padding-right: 22px
}

.react-blog-page blockquote em:before {
    content: "";
    position: absolute;
    bottom: 16px;
    right: 0;
    width: 15px;
    height: 2px;
    z-index: -1;
    background: #5B5F64
}

.blog-post-page .react-sidebar ul.related-courses li .titles span {
    color: #5B5F64;
    font-size: 15px;
    font-weight: 400
}

.blog-post-page .react-sidebar ul.related-courses li .titles span svg {
    width: 16px;
    margin-right: 2px;
    position: relative;
    top: -2px
}

.blog-post-page .back-pagination {
    justify-content: start
}

.blog-post-page .back-pagination li a {
    border: 2px solid rgba(15, 18, 22, .1);
    box-shadow: none;
    line-height: 33px
}

.blog-post-page .react-sidebar .widget.react-categories-course {
    box-shadow: none
}

.blog-post-page .react-sidebar .widget.react-categories-course .widget-title {
    margin-top: -5px
}

.blog-post-page .react-sidebar .widget.react-categories-course .recent-category li a em {
    display: block;
    float: right;
    clear: right;
    font-style: normal
}

.blog-post-page .widget-tags .tags li {
    display: inline-block;
    margin: 0 5px 13px 0
}

.blog-post-page .widget-tags .tags li a {
    padding: 8px 17px;
    background: #F2F4F9;
    font-size: 13px;
    color: #5c5c68;
    font-weight: 600;
    border-radius: 4px
}

.blog-post-page .widget-tags .tags li a:hover {
    background: #3270FC;
    color: #fff
}

.blog-post-single-page .react-course-filter .single-studies .inner-course .case-content {
    padding: 30px 38px 30px 25px
}

.blog-post-single-page .react-course-filter .single-studies .inner-course .case-content em {
    display: block;
    font-size: 16px;
    color: #D2093C;
    font-style: normal
}

.blog-post-single-page .share-course {
    color: #0F1216;
    font-size: 16px;
    font-weight: 600;
    float: right;
    clear: right;
    text-align: right;
    position: relative
}

.blog-post-single-page .share-course em {
    width: 45px;
    color: #fff;
    background: #3270FC;
    border-radius: 50%;
    padding: 1px 13px;
    display: inline-block;
    height: 45px;
    line-height: 40px;
    margin-right: 8px
}

.blog-post-single-page .share-course em svg {
    width: 18px;
    position: relative;
    right: 2px
}

.blog-post-single-page .share-course span {
    position: absolute;
    background: #000;
    padding: 7px 18px;
    border-radius: 4px;
    width: 133px;
    left: 75px;
    top: 0;
    opacity: 0;
    transition: all .5s ease
}

.blog-post-single-page .share-course span:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #000;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%)
}

.blog-post-single-page .share-course span a {
    padding: 0 7px;
    color: #fff;
    opacity: .6
}

.blog-post-single-page .share-course span a:hover {
    opacity: 1
}

.blog-post-single-page .share-course:hover em {
    opacity: .9
}

.blog-post-single-page .share-course:hover span {
    opacity: 1;
    top: -55px
}

.blog-post-single-page .blog-single-inner .blog-image {
    margin-bottom: 42px
}

.blog-post-single-page .blog-single-inner .blog-content {
    color: #3e3e51
}

.blog-post-single-page .blog-single-inner .blog-content p:first-child {
    margin: -8px 0 0;
    overflow: hidden
}

.blog-post-single-page .blog-single-inner .blog-content p {
    margin-bottom: 40px;
    line-height: 28px;
    font-size: 18px;
    font-weight: 400;
    color: #5B5F64
}

.blog-post-single-page .blog-single-inner .blog-content .back-order-list h3, .blog-post-single-page .blog-single-inner .blog-content h3 {
    color: #0F1216;
    font-weight: 600;
    font-size: 34px;
    line-height: 43px
}

.blog-post-single-page .blog-single-inner .blog-content .back-order-list ul li {
    margin: 20px 0;
    font-size: 16px;
    font-weight: 400;
    color: #0F1216
}

.blog-post-single-page .blog-single-inner .blog-content .back-order-list ul li i {
    background: rgba(50, 112, 252, .08);
    padding: 6px;
    border-radius: 50%;
    margin-left: 12px;
    float: right
}

.blog-post-single-page .blog-single-inner .blog-content blockquote {
    position: relative;
    margin: 40px 0 60px;
    padding: 38px 40px 40px;
    color: #0F1216;
    box-shadow: 3px 0 0 #3270fc, 0 20px 40px rgba(15, 18, 22, .1);
    border-radius: 6px;
    font-size: 24px;
    overflow: hidden;
    line-height: 34px;
    font-weight: 400;
    z-index: 1;
    border-right: 1px solid #3270FC
}

.blog-post-single-page .blog-single-inner .blog-content blockquote:before {
    content: "";
    font-size: 120px;
    position: absolute;
    background: url(../Images/blog-grid/qu.png) right bottom no-repeat;
    bottom: 0;
    left: 47px;
    width: 107px;
    height: 107px;
    z-index: 0
}

.blog-post-single-page .blog-single-inner .blog-content blockquote.block__link_q:before {
    content: "";
    font-size: 120px;
    position: absolute;
    background: url(../Images/blog-grid/link.png) right bottom no-repeat;
    bottom: -6px;
    right: 47px;
    width: 180px;
    height: 180px;
    z-index: -1
}

.blog-post-single-page .blog-single-inner .blog-content blockquote em {
    display: block;
    font-style: normal;
    color: #5B5F64;
    font-size: 16px;
    margin-top: 16px;
    font-weight: 400;
    position: relative;
    padding-right: 22px
}

.blog-post-single-page .blog-single-inner .blog-content blockquote em:before {
    content: "";
    position: absolute;
    bottom: 16px;
    right: 0;
    width: 15px;
    height: 2px;
    z-index: -1;
    background: #5B5F64
}

.blog-post-single-page .blog-single-inner .blog-content .blog-tags {
    margin-top: 40px;
    display: block;
    padding: 30px 0 25px;
    border-top: 1px solid #ecedf3
}

.blog-post-single-page .blog-single-inner .blog-content .blog-tags ul.mata-tags li {
    display: inline-block;
    margin-right: 5px
}

.blog-post-single-page .blog-single-inner .blog-content .blog-tags ul.mata-tags li a {
    padding: 3px 20px;
    background: #F2F4F9;
    font-size: 13px;
    color: #4D5765;
    font-weight: 600;
    display: block;
    border-radius: 6px
}

.blog-post-single-page .blog-single-inner .blog-content .blog-tags ul.mata-tags li a:hover {
    background: #3270FC;
    color: #fff
}

.blog-post-single-page .blog-single-inner .blog-content .blog-tags ul.mata-tags li:last-child {
    margin: 0
}

.blog-post-single-page .blog-single-inner .blog-content .blog-tags ul.mata-tags li.tags {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    margin-left: 20px
}

.blog-post-single-page .blog-single-inner .blog-content .post-author {
    display: flex;
    align-items: center;
    padding: 40px;
    overflow: hidden;
    margin-top: 30px;
    background: #fff;
    box-shadow: 0 20px 40px rgba(15, 18, 22, .1);
    border-radius: 6px
}

.blog-post-single-page .blog-single-inner .blog-content .post-author .avatar {
    margin-left: 20px
}

.blog-post-single-page .blog-single-inner .blog-content .post-author .avatar img {
    border-radius: 50%;
    width: 122px
}

.blog-post-single-page .blog-single-inner .blog-content .post-author .info .name {
    margin: 0;
    color: #0F1216;
    transition: all .5s ease 0s;
    font-size: 18px;
    font-weight: 700
}

.blog-post-single-page .blog-single-inner .blog-content .post-author .info .designation a {
    font-size: 14px;
    color: #4D5765;
    margin-right: 8px
}

.blog-post-single-page .blog-single-inner .blog-content .post-author .info .designation a:hover {
    color: #3270FC
}

.blog-post-single-page .blog-single-inner .blog-content .post-author p {
    font-size: 16px;
    color: #5B5F64;
    margin: 9px 0 12px;
    line-height: 24px
}

.blog-post-single-page .blog-single-inner .blog-content .author-comment {
    margin-bottom: 0;
    margin-top: 0
}

.blog-post-single-page .blog-single-inner .blog-content .author-comment h4 {
    margin: 36px 0 20px;
    font-size: 20px;
    font-weight: 700;
    color: #0F1216
}

.blog-post-single-page .blog-single-inner .blog-content .author-comment span {
    font-size: 15px;
    color: #7f7d86
}

.blog-post-single-page .blog-single-inner .blog-content .author-comment span i:before {
    font-size: 13px;
    padding-right: 3px;
    position: relative;
    top: -1px
}

.blog-post-single-page .blog-single-inner .blog-content .author-comment h6 {
    margin: 0 0 3px;
    font-size: 16px;
    color: #0F1216;
    font-weight: 600
}

.blog-post-single-page .blog-single-inner .blog-content .author-comment ul {
    padding: 0;
    margin: 0
}

.blog-post-single-page .blog-single-inner .blog-content .author-comment ul li {
    margin: 0 0 35px;
    padding: 30px 57px 32px 35px;
    border: 1px solid rgba(0, 48, 110, .1);
    border-radius: 6px
}

.blog-post-single-page .blog-single-inner .blog-content .author-comment ul li .col-sm-2 {
    text-align: left;
    padding-right: 6px
}

.blog-post-single-page .blog-single-inner .blog-content .author-comment ul li:nth-child(2) {
    margin-left: 120px;
    padding: 35px 52px 32px 35px
}

.blog-post-single-page .blog-single-inner .blog-content .author-comment ul li:nth-child(2) .col-sm-2 {
    padding-right: 6px
}

.blog-post-single-page .blog-single-inner .blog-content .author-comment ul li:nth-child(2) .image-comments img {
    width: 55px
}

.blog-post-single-page .blog-single-inner .blog-content .author-comment ul li:nth-child(3) {
    margin-left: 120px;
    padding: 35px 52px 38px 35px
}

.blog-post-single-page .blog-single-inner .blog-content .author-comment ul li:nth-child(3) .col-sm-2 {
    padding-right: 6px
}

.blog-post-single-page .blog-single-inner .blog-content .author-comment ul li:nth-child(3) .image-comments img {
    width: 55px
}

.blog-post-single-page .blog-single-inner .blog-content .author-comment ul li .image-comments {
    margin-top: 0
}

.blog-post-single-page .blog-single-inner .blog-content .author-comment ul li .image-comments img {
    width: 55px;
    border-radius: 100%
}

.blog-post-single-page .blog-single-inner .blog-content .author-comment ul li .reply {
    font-size: 14px;
    color: #5B5F64;
    font-weight: 400
}

.blog-post-single-page .blog-single-inner .blog-content .author-comment ul li .reply a, .blog-post-single-page .blog-single-inner .blog-content .author-comment ul li .reply a:hover {
    color: #3270fc
}

.blog-post-single-page .blog-single-inner .blog-content .author-comment ul li .dsc-comments h4 {
    margin: 0 0 12px
}

.blog-post-single-page .blog-single-inner .blog-content .author-comment ul li .dsc-comments a {
    padding: 4px 0;
    font-size: 14px;
    color: #0F1216;
    font-weight: 600;
    border-radius: 6px
}

.blog-post-single-page .blog-single-inner .blog-content .author-comment ul li .dsc-comments a svg {
    width: 16px;
    position: relative;
    top: 0;
    margin-right: 2px
}

.blog-post-single-page .blog-single-inner .blog-content .author-comment ul li .dsc-comments a:hover {
    color: #3270FC
}

.blog-post-single-page .blog-single-inner .blog-content .author-comment ul li .dsc-comments p {
    margin: 12px 0 15px;
    color: #55575c;
    font-size: 16px;
    line-height: 24px
}

.blog-post-single-page .blog-single-inner .blog-content .blog-form {
    margin-top: 98px;
    z-index: 9;
    position: relative
}

.blog-post-single-page .blog-single-inner .blog-content .blog-form .back-input {
    position: relative
}

.blog-post-single-page .blog-single-inner .blog-content .blog-form .back-input img {
    position: absolute;
    bottom: 55px;
    left: 30px
}

.blog-post-single-page .blog-single-inner .blog-content .blog-form .back-textarea img {
    position: absolute;
    top: 57px;
    left: 47px;
    z-index: 1
}

.blog-post-single-page .blog-single-inner .blog-content .blog-form h3 {
    font-size: 20px;
    color: #0F1216;
    font-weight: 700;
    margin-bottom: 0
}

.blog-post-single-page .blog-single-inner .blog-content .blog-form form p {
    color: #5B5F64;
    font-size: 16px;
    font-weight: 400
}

.blog-post-single-page .blog-single-inner .blog-content .blog-form form input {
    width: 100%;
    height: 57px;
    padding: 10px 30px;
    background: #F2F4F9;
    outline: 0;
    border-radius: 10px;
    margin-bottom: 25px;
    border: 2px solid #F2F4F9
}

.blog-post-single-page .blog-single-inner .blog-content .blog-form form input:focus {
    border: 2px solid #3270FC;
    background: 0 0
}

.blog-post-single-page .blog-single-inner .blog-content .blog-form form textarea {
    width: 100%;
    height: 160px;
    padding: 22px 30px;
    background: #F2F4F9;
    outline: 0;
    border-radius: 10px;
    margin-bottom: 25px;
    position: relative;
    border: 2px solid #F2F4F9
}

.blog-post-single-page .blog-single-inner .blog-content .blog-form form textarea:focus {
    border: 2px solid #3270FC;
    background: 0 0
}

.blog-post-single-page .blog-single-inner .blog-content .blog-form form button {
    background: #3270FC;
    font-size: 16px;
    color: #fff;
    border-radius: 10px;
    padding: 10px 15px;
    display: inline-block;
    font-weight: 600;
    transition: all .5s ease 0s;
    border: none;
    outline: 0;
    box-shadow: none;
    cursor: pointer;
    width: 100%;
    max-width: 210px
}

.blog-post-single-page .blog-single-inner .blog-content .blog-form form button svg {
    width: 18px;
    position: relative;
    top: -1px;
    margin-left: 2px;
    transform: rotate(180deg)
}

.blog-post-single-page .blog-single-inner .blog-content .blog-form form button:hover {
    color: #fff;
    opacity: .8
}

.blog-post-single-page .blog-single-inner .blog-content .blog-form form .back-check-box {
    align-items: center;
    margin-top: -6px;
    margin-bottom: 30px;
    color: #55575c;
    font-size: 16px
}

.blog-post-single-page .blog-single-inner .blog-content .blog-form form .back-check-box a {
    color: #1a152e
}

.blog-post-single-page .blog-single-inner .blog-content .blog-form form .back-check-box a:hover {
    color: #74727d
}

.blog-post-single-page .blog-single-inner .blog-content .blog-form form .back-check-box input {
    margin: 0 8px 0 0;
    height: 16px;
    width: 16px
}

.blog-post-single-page .blog-single-inner .blog-content .blog-form form ::-webkit-input-placeholder {
    color: #7b7d82;
    opacity: 1;
    font-size: 16px
}

.blog-post-single-page .blog-single-inner .blog-content .blog-form form ::-moz-placeholder {
    color: #7b7d82;
    opacity: 1;
    font-size: 16px
}

.blog-post-single-page .blog-single-inner .blog-content .blog-form form :-ms-input-placeholder {
    color: #7b7d82;
    opacity: 1;
    font-size: 16px
}

.blog-post-single-page .blog-single-inner .blog-content .blog-form form :-moz-placeholder {
    color: #7b7d82;
    opacity: 1;
    font-size: 16px
}

.blog-post-single-page .single-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 80px;
    position: relative;
    border-top: 1px solid #E8ECF0;
    border-bottom: 1px solid #E8ECF0;
    padding: 20px 0 30px
}

.blog-post-single-page .single-nav .back-next {
    text-align: right
}

.blog-post-single-page .single-nav .back-next a, .blog-post-single-page .single-nav .back-prev a {
    color: #4D5765;
    font-size: 12px;
    font-weight: 600
}

.blog-post-single-page .single-nav .back-next a em, .blog-post-single-page .single-nav .back-prev a em {
    display: block;
    font-style: normal;
    font-weight: 600;
    color: #0F1216;
    font-size: 18px;
    line-height: 18px
}

.blog-post-single-page .single-nav .back-next a:hover, .blog-post-single-page .single-nav .back-next a:hover em, .blog-post-single-page .single-nav .back-prev a:hover, .blog-post-single-page .single-nav .back-prev a:hover em {
    color: #3270FC
}

.blog-post-single-page .single-nav .back-prev {
    position: relative;
    padding-left: 55px;
    transition: all 500ms ease
}

.blog-post-single-page .single-nav .back-prev i:before {
    font-size: 23px;
    margin: 0;
    font-weight: 600;
    position: absolute;
    top: 8px;
    left: 0;
    transition: all 500ms ease;
    border: 2px solid rgba(15, 18, 22, .08);
    padding: 6px;
    border-radius: 50%
}

.blog-post-single-page .single-nav .back-prev:hover i:before {
    left: -6px
}

.blog-post-single-page .single-nav .back-next {
    position: relative;
    padding-right: 55px;
    transition: all 500ms ease
}

.blog-post-single-page .single-nav .back-next i:before {
    font-size: 23px;
    margin: 0;
    font-weight: 600;
    position: absolute;
    top: 8px;
    right: 0;
    transition: all 500ms ease;
    border: 2px solid rgba(15, 18, 22, .08);
    padding: 6px;
    border-radius: 50%
}

.blog-post-single-page .single-nav .back-next:hover i:before {
    right: -6px
}

.blog-post-single-page .single-nav .social-links svg {
    position: relative;
    top: 6px
}

.react-contact-page h2.contact-title {
    font-size: 34px;
    color: #0F1216;
    font-weight: 700;
    line-height: 42px;
    text-align: left
}

.react-contact-page .react-blog-form {
    padding-left: 70px
}

.react-contact-page .blog-form {
    z-index: 9;
    position: relative;
    padding-top: 15px
}

.react-contact-page .blog-form .back-input {
    position: relative
}

.react-contact-page .blog-form .back-input img {
    position: absolute;
    bottom: 55px;
    left: 30px
}

.react-contact-page .blog-form .react-textarea img {
    position: absolute;
    top: 57px;
    left: 47px;
    z-index: 1
}

.react-contact-page .blog-form form label {
    color: #1a152e;
    font-size: 14px;
    font-weight: 500
}

.react-contact-page .blog-form form input {
    width: 100%;
    height: 57px;
    padding: 10px 30px;
    background: #F5F8FD;
    outline: 0;
    border-radius: 6px;
    margin-bottom: 20px;
    border: 2px solid #F5F8FD
}

.react-contact-page .blog-form form input:focus {
    border: 2px solid #3270FC;
    background: 0 0
}

.react-contact-page .blog-form form textarea {
    width: 100%;
    height: 160px;
    padding: 22px 30px;
    background: #F5F8FD;
    outline: 0;
    border-radius: 6px;
    margin-bottom: 20px;
    position: relative;
    border: 2px solid #F5F8FD
}

.react-contact-page .blog-form form textarea:focus {
    border: 2px solid #3270FC;
    background: 0 0
}

.react-contact-page .blog-form form button {
    background: #3270FC;
    font-size: 16px;
    color: #fff;
    border-radius: 6px;
    padding: 14px 38px;
    display: block;
    font-weight: 600;
    transition: all .5s ease 0s;
    border: none;
    outline: 0;
    box-shadow: none;
    cursor: pointer
}

.react-contact-page .blog-form form button svg {
    width: 18px;
    transform: rotate(180deg)
}

.react-contact-page .blog-form form button:hover {
    color: #fff;
    opacity: .9
}

.react-contact-page .blog-form form .react-check-box {
    align-items: center;
    margin-top: 10px;
    margin-bottom: 20px;
    color: #55575c;
    font-size: 16px
}

.react-contact-page .blog-form form .react-check-box a {
    color: #1a152e
}

.react-contact-page .blog-form form .react-check-box a:hover {
    color: #74727d
}

.react-contact-page .blog-form form .react-check-box input {
    margin: 0 8px 0 0;
    height: 16px;
    width: 16px
}

.react-contact-page .blog-form form ::-webkit-input-placeholder {
    color: #7b7d82;
    opacity: 1;
    font-size: 16px
}

.react-contact-page .blog-form form ::-moz-placeholder {
    color: #7b7d82;
    opacity: 1;
    font-size: 16px
}

.react-contact-page .blog-form form :-ms-input-placeholder {
    color: #7b7d82;
    opacity: 1;
    font-size: 16px
}

.react-contact-page .blog-form form :-moz-placeholder {
    color: #7b7d82;
    opacity: 1;
    font-size: 16px
}

.react-contact-page .address-sec {
    padding: 35px;
    background: linear-gradient(138.95deg, #f2f4f9 0, rgba(242, 244, 249, 0) 63.81%);
    border-radius: 10px
}

.react-contact-page .address-sec li {
    display: flex;
    padding-bottom: 35px
}

.react-contact-page .address-sec li .icon {
    margin-left: 25px
}

.react-contact-page .address-sec li .text {
    font-size: 16px;
    line-height: 24px;
    color: #4D5765
}

.react-contact-page .address-sec li .text em {
    color: #0F1216;
    font-weight: 600;
    font-size: 20px;
    display: block;
    font-style: normal;
    margin: 0 0 8px
}

.react-contact-page .address-sec li .text a {
    display: block;
    color: #0F1216
}

.react-contact-page .address-sec li .text a:hover {
    color: #3270FC
}

.react-contact-page .react-contacts .back-title-sec h2 {
    font-weight: 600;
    font-size: 40px
}

.react-contact-page .react-contacts .react-image-maping {
    position: relative;
    padding-top: 15px
}

.react-contact-page .react-contacts .react-image-maping .react-tooltip1 {
    left: 50%;
    top: 50%;
    display: block
}

.react-contact-page .react-contacts .react-image-maping .react-ripple {
    background-color: #f94392;
    border-radius: 50%;
    animation: pi-ripple .7s linear infinite;
    position: absolute;
    cursor: pointer
}

.react-contact-page .react-contacts .react-image-maping .react-ripple .box {
    position: relative;
    width: 16px;
    height: 16px
}

.react-contact-page .react-contacts .react-image-maping .react-ripple .box span {
    font-size: 16px;
    color: #1a152e;
    font-weight: 600
}

@keyframes pi-ripple {
    0% {
        box-shadow: 0 0 0 0 rgba(249, 67, 146, .05), 0 0 0 16px rgba(249, 67, 146, .05), 0 0 0 20px rgba(249, 67, 146, .05), 0 0 0 24px rgba(249, 67, 146, .05)
    }

    100% {
        box-shadow: 0 0 0 16px rgba(249, 67, 146, .05), 0 0 0 20px rgba(249, 67, 146, .05), 0 0 0 24px rgba(249, 67, 146, .05), 0 0 0 38px rgba(249, 67, 146, 0)
    }
}

.react-contact-page .react-contacts .react-ripple:hover span {
    opacity: 1;
    filter: alpha(opacity=100);
    top: -60px;
    left: -18px;
    z-index: 99;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease
}

.react-contact-page .react-contacts .react-ripple span {
    background: #fff;
    font-weight: 400;
    padding: 8px 0;
    width: 140px;
    top: -20px;
    left: -18px;
    margin-left: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    position: absolute;
    text-align: center;
    z-index: 2;
    text-transform: none;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease;
    transition: all .3s ease-in-out;
    border-radius: 4px
}

.react-contact-page .react-contacts .react-ripple span:after {
    border-color: #fff rgba(0, 0, 0, 0);
    border-style: solid;
    border-width: 10px 8px 0;
    bottom: -10px;
    content: "";
    display: block;
    left: 18px;
    position: absolute;
    width: 0
}

body.course-single .react-breadcrumbs .breadcrumbs-wrap .breadcrumbs-inner .breadcrumbs-text {
    text-align: left
}

body.course-single .react-breadcrumbs .breadcrumbs-wrap .breadcrumbs-inner .breadcrumbs-text .cate {
    background: #3270fc;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    border-radius: 4px;
    padding: 2px 10px 5px;
    line-height: 17px;
    margin-bottom: 12px
}

body.course-single .user-section {
    display: flex;
    align-items: center;
    padding-bottom: 50px
}

body.course-single .user-section li {
    padding-left: 40px;
    font-size: 16px;
    color: #fff;
    font-weight: 400
}

body.course-single .user-section li svg {
    width: 18px;
    position: relative;
    top: -2px;
    margin-left: 4px
}

body.course-single .user-section li.user {
    display: flex;
    align-items: center
}

body.course-single .user-section li.user span {
    padding-right: 12px
}

body.course-single .user-section li.user span img {
    width: 36px;
    border-radius: 50%
}

.react-courses__single-page .course-single-tab .nav.nav-tabs {
    padding: 0 0 1px;
    margin-top: 0;
    background: #F2F4F9;
    border-radius: 6px;
    margin-bottom: 50px;
    border: none
}

.react-courses__single-page .course-single-tab .nav.nav-tabs li a {
    border: none;
    color: #5B5F64;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    padding: 19px 25px;
    text-align: center;
    display: inline-block;
    margin: 10px 8px
}

.react-courses__single-page .course-single-tab .nav.nav-tabs li a.active, .react-courses__single-page .course-single-tab .nav.nav-tabs li a:hover {
    background: #fff;
    box-shadow: 0 6px 10px rgba(15, 18, 22, .1);
    border-radius: 6px;
    color: #0F1216
}

.react-courses__single-page .course-single-tab #back-tab-content h3 {
    font-size: 26px;
    color: #0F1216;
    font-weight: 600;
    margin: 0 0 20px
}

.react-courses__single-page .course-single-tab #back-tab-content p {
    font-size: 16px;
    color: #4b4b57
}

.react-courses__single-page .course-single-tab #back-tab-content .back-tag a {
    font-size: 16px;
    font-weight: 500;
    color: #737383
}

.react-courses__single-page .course-single-tab #back-tab-content .back-tag a:hover {
    color: #3270fc
}

.react-courses__single-page .course-single-tab #back-tab-content .back-tag svg {
    width: 18px;
    height: 18px;
    margin-right: 4px
}

.react-courses__single-page .course-single-tab #back-tab-content .back-objectives {
    background: #f7f7f8;
    overflow: hidden;
    padding: 40px;
    border-radius: 2px;
    margin-top: 50px;
    margin-bottom: 40px
}

.react-courses__single-page .course-single-tab #back-tab-content .back-objectives h3 {
    font-size: 20px;
    color: #00306e;
    font-weight: 800;
    margin: 0 0 20px
}

.react-courses__single-page .course-single-tab #back-tab-content .back-objectives li {
    float: left;
    width: 50%;
    font-size: 15px;
    font-weight: 600;
    color: #00306e;
    position: relative;
    padding-left: 42px;
    margin: 10px 0 17px;
    line-height: 1.3
}

.react-courses__single-page .course-single-tab #back-tab-content .back-objectives li:first-child {
    float: none;
    width: 100%;
    padding: 0
}

.react-courses__single-page .course-single-tab #back-tab-content .back-objectives li i {
    background: #3270fc;
    padding: 6px;
    color: #fff;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    left: 0
}

.react-courses__single-page .course-single-tab #back-tab-content .single-week {
    background: #f7f7f8;
    padding: 40px 40px 30px;
    border-radius: 6px;
    margin-bottom: 20px
}

.react-courses__single-page .course-single-tab #back-tab-content .single-week .week__top {
    display: flex;
    border-bottom: 1px solid rgba(2, 3, 52, .078);
    justify-content: space-between;
    font-size: 20px;
    font-weight: 800;
    padding-bottom: 10px;
    margin-bottom: 28px;
    color: #00306e
}

.react-courses__single-page .course-single-tab #back-tab-content .single-week .week__top li:last-child {
    color: #828287;
    font-weight: 700
}

.react-courses__single-page .course-single-tab #back-tab-content .single-week .course__title li {
    border-bottom: 1px solid rgba(2, 3, 52, .078);
    color: #4b4c57;
    padding: 12px 0;
    overflow: hidden
}

.react-courses__single-page .course-single-tab #back-tab-content .single-week .course__title li svg {
    width: 20px;
    height: 20px;
    margin-right: 7px;
    position: relative;
    top: -2px
}

.react-courses__single-page .course-single-tab #back-tab-content .single-week .course__title li em {
    font-style: normal;
    float: right
}

.react-courses__single-page .course-single-tab #back-tab-content .single-week .course__title li em svg {
    width: 17px;
    height: 17px;
    margin-right: 3px;
    position: relative;
    top: -2px
}

.react-courses__single-page .course-single-tab #back-tab-content .single-week .course__title li em.questions {
    background-color: rgba(248, 69, 45, .102);
    color: #f8452d;
    padding: 6px 10px;
    line-height: 1;
    border-radius: 6px;
    margin-left: 20px;
    font-weight: 500
}

.react-courses__single-page .course-single-tab #back-tab-content .single-week .course__title li b {
    font-weight: 700;
    color: #00306e
}

.react-courses__single-page .course-single-tab #back-tab-content .single-week .course__title li:last-child {
    border: none
}

.react-courses__single-page .course-single-tab #back-tab-content .skillbar-style2 {
    border: 1px solid rgba(0, 48, 110, .1);
    border-radius: 6px;
    padding: 40px
}

.react-courses__single-page .course-single-tab #back-tab-content .skillbar-style2 .skill_inners {
    display: flex;
    align-items: center;
    margin-bottom: 15px
}

.react-courses__single-page .course-single-tab #back-tab-content .skillbar-style2 .skill_inners span.skillbar-title {
    flex: 0 0 15%;
    color: #5B5F64;
    font-size: 14px
}

.react-courses__single-page .course-single-tab #back-tab-content .skillbar-style2 .skill_inners span {
    color: #5B5F64
}

.react-courses__single-page .course-single-tab #back-tab-content .skillbar-style2 .skillbars {
    position: relative;
    display: block;
    background: #E6E9F1;
    height: 10px;
    width: 100%;
    clear: both;
    margin: 0 20px 0 0;
    border-radius: 0
}

.react-courses__single-page .course-single-tab #back-tab-content .skillbar-style2 .skillbars .skillbar-bar {
    height: 10px;
    width: 0;
    background: #3270fc;
    display: block;
    margin: 0;
    position: relative;
    border-radius: 0
}

.react-courses__single-page .course-single-tab #back-tab-content .five__number {
    border: 1px solid rgba(0, 48, 110, .1);
    text-align: center;
    padding: 75px 50px;
    border-radius: 6px
}

.react-courses__single-page .course-single-tab #back-tab-content .five__number em {
    font-size: 50px;
    color: #0F1216;
    font-style: normal;
    line-height: 1.2;
    margin-bottom: 12px;
    font-weight: 700;
    display: block
}

.react-courses__single-page .course-single-tab #back-tab-content .five__number i {
    color: #ff9415;
    font-size: 14px
}

.react-courses__single-page .course-single-tab #back-tab-content .five__number h6 {
    color: #0F1216
}

.react-courses__single-page .course-single-tab #back-tab-content .post-author {
    display: flex;
    align-items: center;
    padding: 25px 40px 30px;
    overflow: hidden;
    margin-bottom: 20px;
    border: 1px solid rgba(0, 48, 110, .1);
    border-radius: 6px
}

.react-courses__single-page .course-single-tab #back-tab-content .post-author .avatar {
    margin-left: 20px
}

.react-courses__single-page .course-single-tab #back-tab-content .post-author .avatar img {
    border-radius: 50%;
    width: 80px
}

.react-courses__single-page .course-single-tab #back-tab-content .post-author .info .back-ratings i {
    color: #ff9415;
    font-size: 14px
}

.react-courses__single-page .course-single-tab #back-tab-content .post-author .info .name {
    margin: 0;
    color: #0F1216;
    transition: all .5s ease 0s;
    font-size: 20px;
    font-weight: 600
}

.react-courses__single-page .course-single-tab #back-tab-content .post-author .info .name .designation {
    font-size: 16px;
    color: #5B5F64;
    margin: 0;
    font-weight: 400
}

.react-courses__single-page .course-single-tab #back-tab-content .post-author p {
    font-size: 16px;
    color: #4b4b57;
    margin: 0;
    line-height: 24px
}

.react-courses__single-page .course-single-tab #back-tab-content .post-author:hover .info .name {
    color: #3270fc
}

.react-courses__single-page .course-single-tab #back-tab-content .blog-form p {
    margin-top: -15px
}

.react-courses__single-page .course-single-tab #back-tab-content .blog-form form .back-ratings {
    padding-bottom: 25px;
    padding-top: 6px;
    line-height: 1
}

.react-courses__single-page .course-single-tab #back-tab-content .blog-form form .back-ratings i {
    font-size: 14px;
    color: #b8b9bf
}

.react-courses__single-page .course-single-tab #back-tab-content .blog-form form .back-ratings i:nth-child(1), .react-courses__single-page .course-single-tab #back-tab-content .blog-form form .back-ratings i:nth-child(2) {
    color: #ff9415
}

.react-courses__single-page .course-single-tab #back-tab-content .blog-form form input {
    width: 100%;
    height: 57px;
    padding: 10px 30px;
    background: #F2F4F9;
    outline: 0;
    border-radius: 6px;
    margin-bottom: 25px;
    border: 2px solid #F2F4F9
}

.react-courses__single-page .course-single-tab #back-tab-content .blog-form form input:focus {
    border: 2px solid #3270fc;
    background: 0 0
}

.react-courses__single-page .course-single-tab #back-tab-content .blog-form form textarea {
    width: 100%;
    height: 160px;
    padding: 22px 30px;
    background: #F2F4F9;
    outline: 0;
    border-radius: 6px;
    margin-bottom: 5px;
    position: relative;
    border: 2px solid #F2F4F9
}

.react-courses__single-page .course-single-tab #back-tab-content .blog-form form textarea:focus {
    border: 2px solid #3270fc;
    background: 0 0
}

.react-courses__single-page .course-single-tab #back-tab-content .blog-form form button {
    background: #3270FC;
    font-size: 16px;
    color: #fff;
    border-radius: 6px;
    padding: 14px 30px;
    display: inline-block;
    font-weight: 600;
    transition: all .5s ease 0s;
    border: none;
    outline: 0;
    box-shadow: none;
    cursor: pointer;
    margin-top: 25px
}

.react-courses__single-page .course-single-tab #back-tab-content .blog-form form button svg {
    width: 18px
}

.react-courses__single-page .course-single-tab #back-tab-content .blog-form form button:hover {
    color: #fff;
    background: #00306e
}

.react-courses__single-page .course-single-tab #back-tab-content .blog-form form .back-check-box {
    align-items: center;
    margin-top: 10px;
    margin-bottom: 20px;
    color: #55575c;
    font-size: 16px
}

.react-courses__single-page .course-single-tab #back-tab-content .blog-form form .back-check-box a {
    color: #1a152e
}

.react-courses__single-page .course-single-tab #back-tab-content .blog-form form .back-check-box a:hover {
    color: #74727d
}

.react-courses__single-page .course-single-tab #back-tab-content .blog-form form .back-check-box input {
    margin: 0 8px 0 0;
    height: 16px;
    width: 16px
}

.react-courses__single-page .course-single-tab #back-tab-content .blog-form form ::-webkit-input-placeholder {
    color: #7b7d82;
    opacity: 1;
    font-size: 16px
}

.react-courses__single-page .course-single-tab #back-tab-content .blog-form form ::-moz-placeholder {
    color: #7b7d82;
    opacity: 1;
    font-size: 16px
}

.react-courses__single-page .course-single-tab #back-tab-content .blog-form form :-ms-input-placeholder {
    color: #7b7d82;
    opacity: 1;
    font-size: 16px
}

.react-courses__single-page .course-single-tab #back-tab-content .blog-form form :-moz-placeholder {
    color: #7b7d82;
    opacity: 1;
    font-size: 16px
}

.react-courses__single-page .course-single-tab #back-tab-content .social-links h4 {
    font-size: 16px;
    font-weight: 800;
    margin: 0 0 26px
}

.react-courses__single-page .course-single-tab #back-tab-content .social-links li a {
    border-radius: 6px
}

.react-courses__single-page .course-single-tab #back-tab-content .social-links li:first-child {
    float: none;
    width: 100%;
    padding: 0;
    display: block
}

.back-pagination {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center
}

.back-pagination li a {
    width: 40px;
    height: 40px;
    background: #FFF;
    box-shadow: 0 1px 2px rgba(15, 18, 22, .1);
    display: inline-block;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    border-radius: 50%;
    color: #5B5F64;
    margin: 0 5px
}

.back-pagination li a:hover {
    background: #3270FC;
    color: #fff
}

.back-pagination li a svg {
    width: 18px;
    position: relative;
    transition: all .5s ease 0s;
    top: -1px;
    transform: rotate(180deg)
}

.react-sidebar {
    background: #fff;
    box-shadow: 0 20px 40px rgba(15, 18, 22, .08);
    border-radius: 6px
}

.react-sidebar.react-back-course2 {
    box-shadow: none
}

.react-sidebar .widget {
    padding: 35px;
    border-bottom: 1px solid #E8ECF0
}

.react-sidebar .widget.back-post .widget-title {
    margin-top: -10px
}

.react-sidebar .widget.back-category {
    padding: 30px 35px
}

.react-sidebar .widget.related__courses {
    background: #fff;
    box-shadow: 0 30px 50px 0 rgba(1, 11, 60, .1);
    margin-top: 22px
}

.react-sidebar .widget.react-categories-course {
    box-shadow: 0 30px 50px 0 rgba(1, 11, 60, .1);
    background: #fff;
    border-radius: 6px
}

.react-sidebar .widget.react-categories-course ul li a {
    font-size: 16px;
    color: #5B5F64;
    font-weight: 400;
    line-height: 32px;
    display: block
}

.react-sidebar .widget.react-categories-course ul li a:hover {
    color: #3270FC
}

.react-sidebar .widget.get-back-course {
    box-shadow: 0 30px 50px 0 rgba(1, 11, 60, .1);
    background: #fff;
    margin-bottom: 10px;
    border-radius: 6px;
    padding: 0
}

.react-sidebar .widget.get-back-course .price {
    display: flex;
    align-items: center;
    padding: 25px 40px;
    /* border-bottom: 1px solid #E8ECF0 */
}

.react-sidebar .widget.get-back-course .price li {
    font-size: 30px;
    font-weight: 700;
    color: #3270FC;
    line-height: 1
}

.react-sidebar .widget.get-back-course .price li:nth-child(2) {
    font-size: 16px;
    font-weight: 600;
    text-decoration: line-through;
    margin-left: 9px;
    color: #9DA1A6
}

.react-sidebar .widget.get-back-course .price li:nth-child(3) {
    font-size: 13px;
    color: #D2093C;
    background: rgba(210, 9, 60, .06);
    border-radius: 4px;
    margin-left: 10px;
    padding: 5px 6px;
    font-weight: 600
}

.react-sidebar .widget.get-back-course .start-btn {
    border-radius: 6px;
    width: auto;
    text-align: center;
    background: #3270FC;
    color: #fff;
    padding: 11px 25px;
    display: block;
    font-size: 16px;
    font-weight: 600;
    margin: 30px 35px 25px
}

.react-sidebar .widget.get-back-course .start-btn svg {
    width: 18px;
    transform: rotate(180deg)
}

.react-sidebar .widget.get-back-course .start-btn:hover {
    opacity: .9
}

.react-sidebar .widget.get-back-course .share-course {
    color: #0F1216;
    font-size: 16px;
    font-weight: 600;
    display: block;
    text-align: center;
    padding-bottom: 30px;
    position: relative
}

.react-sidebar .widget.get-back-course .share-course em {
    width: 45px;
    color: #3270FC;
    border: 2px solid rgba(15, 18, 22, .1);
    border-radius: 50%;
    padding: 1px 13px;
    display: inline-block;
    height: 45px;
    line-height: 37px;
    margin-right: 8px
}

.react-sidebar .widget.get-back-course .share-course em svg {
    width: 18px;
    position: relative;
    right: 2px
}

.react-sidebar .widget.get-back-course .share-course span {
    position: absolute;
    background: #000;
    padding: 7px 18px;
    border-radius: 4px;
    width: 133px;
    left: 190px;
    top: 0;
    opacity: 0;
    transition: all .5s ease
}

.react-sidebar .widget.get-back-course .share-course span:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #000;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%)
}

.react-sidebar .widget.get-back-course .share-course span a {
    padding: 0 7px;
    color: #fff;
    opacity: .6
}

.react-sidebar .widget.get-back-course .share-course span a:hover {
    opacity: 1
}

.react-sidebar .widget.get-back-course .share-course:hover em {
    background: #3270FC;
    color: #fff
}

.react-sidebar .widget.get-back-course .share-course:hover span {
    opacity: 1;
    top: -55px
}

.react-sidebar .widget.get-back-course .price__course {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 20px
}

.react-sidebar .widget.get-back-course .price__course li {
    padding: 10px 0;
    box-shadow: 0 1px 0 #E8ECF0;
    color: #0F1216
}

.react-sidebar .widget.get-back-course .price__course li i {
    position: relative;
    color: #0F1216;
    display: inline-block;
    margin-left: 10px;
    font-size: 14px
}

.react-sidebar .widget.get-back-course .price__course li b {
    font-size: 16px;
    font-weight: 400;
    color: #5B5F64;
    float: right !important;
    clear: right
}

.react-sidebar .widget.get-back-course .price__course li b.prs {
    font-size: 30px;
    color: #3270FC;
    font-weight: 700;
    line-height: 26px
}

.react-sidebar .widget.get-back-course .price__course li:last-child {
    border: none
}

.react-sidebar .widget.back-rating ul.recent-category input[type=checkbox]+label::before {
    border-radius: 50%;
    margin-right: 10px;
    top: 0
}

.react-sidebar .widget.back-rating em {
    font-style: normal;
    display: inline-block;
    margin-left: 6px
}

.react-sidebar .widget.back-rating i {
    color: #FFA439;
    font-size: 14px
}

.react-sidebar .widget.back-price ul.recent-category input[type=checkbox]+label::before {
    border-radius: 50%;
    margin-right: 10px;
    top: 0
}

.react-sidebar .widget.react-date-sec {
    box-shadow: 0 30px 50px 0 rgba(1, 11, 60, .1);
    background: #fff;
    margin-bottom: 10px;
    border-radius: 6px
}

.react-sidebar .widget.react-date-sec ul li {
    font-size: 16px;
    color: #0F1216;
    font-weight: 600;
    margin-bottom: 20px
}

.react-sidebar .widget.react-date-sec ul li b {
    color: #5B5F64;
    font-weight: 400;
    font-size: 16px;
    display: block
}

.react-sidebar .widget.react-date-sec ul li:last-child {
    margin-bottom: 0
}

.react-sidebar ul.recent-category {
    margin: -8px 0 0;
    padding: 0;
    list-style: none
}

.react-sidebar ul.recent-category li {
    margin: 8px 0
}

.react-sidebar ul.recent-category input[type=checkbox]+label {
    cursor: pointer
}

.react-sidebar ul.recent-category input[type=checkbox] {
    display: none
}

.react-sidebar ul.recent-category label {
    color: #5B5F64;
    font-size: 16px;
    font-weight: 400
}

.react-sidebar ul.recent-category input[type=checkbox]+label:before {
    content: "\2714";
    border: .1em solid #d9d8d8;
    border-radius: 4px;
    display: inline-block;
    width: 23px;
    height: 23px;
    line-height: 11px;
    margin-left: 12px;
    vertical-align: middle;
    color: transparent;
    transition: .2s;
    background: #fff;
    padding: 5px 6px;
    font-size: 13px;
    position: relative;
    top: -2px
}

.react-sidebar ul.recent-category input[type=checkbox]+label:active:before {
    transform: scale(0)
}

.react-sidebar ul.recent-category input[type=checkbox]:checked+label:before {
    background-color: #3270FC;
    border-color: #3270FC;
    color: #fff
}

.react-sidebar ul.recent-category input[type=checkbox]:disabled+label:before {
    transform: scale(1);
    border-color: #eee
}

.react-sidebar ul.related-courses {
    margin: 0;
    padding: 0;
    list-style: none
}

.react-sidebar ul.related-courses li {
    display: flex;
    align-items: center;
    padding-bottom: 1.2em
}

.react-sidebar ul.related-courses li .back-ratings i {
    font-size: 10px;
    color: #ff9415
}

.react-sidebar ul.related-courses li .post-images {
    width: 87px;
    display: block
}

.react-sidebar ul.related-courses li .post-images img {
    display: block;
    width: 127px;
    border-radius: 6px
}

.react-sidebar ul.related-courses li .titles {
    display: block;
    padding-right: 20px
}

.react-sidebar ul.related-courses li .titles h4 {
    margin: 0 0 6px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px
}

.react-sidebar ul.related-courses li .titles h4 a {
    color: #0F1216
}

.react-sidebar ul.related-courses li .titles h4:hover a {
    color: #3270FC
}

.react-sidebar ul.related-courses li .titles span {
    font-weight: 600;
    color: #0F1216
}

.react-sidebar ul.related-courses li:last-child {
    padding-bottom: 0
}

.react-sidebar ul.recent-posts {
    margin: 0;
    padding: 0;
    list-style: none
}

.react-sidebar ul.recent-posts li {
    display: flex;
    align-items: center;
    padding-bottom: 1.2em
}

.react-sidebar ul.recent-posts li .back-ratings i {
    font-size: 10px;
    color: #ff9415
}

.react-sidebar ul.recent-posts li .post-images {
    width: 87px;
    display: block
}

.react-sidebar ul.recent-posts li .post-images img {
    display: block;
    width: 127px;
    border-radius: 6px
}

.react-sidebar ul.recent-posts li .titles {
    display: block;
    padding-left: 20px
}

.react-sidebar ul.recent-posts li .titles h4 {
    margin: 0 0 6px;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px
}

.react-sidebar ul.recent-posts li .titles h4 a {
    color: #00306e
}

.react-sidebar ul.recent-posts li .titles h4:hover a {
    color: #3270fc
}

.react-sidebar ul.recent-posts li .titles span {
    font-weight: 600;
    color: #737383
}

.react-sidebar ul.recent-posts li:last-child {
    padding-bottom: 0
}

.react-sidebar h3.widget-title {
    margin: 0;
    font-size: 20px;
    padding-bottom: 1.1em;
    color: #0F1216;
    font-weight: 600;
    line-height: 1.2
}

.react-sidebar form {
    position: relative
}

.react-sidebar form input {
    width: 100%;
    padding: 12px 80px 12px 28px;
    background: #eef1f5;
    outline: 0;
    border-radius: 6px;
    border: none
}

.react-sidebar form button {
    background: 0 0;
    color: #fff;
    display: inline-block;
    padding: 4px 23px;
    border-radius: 0 3px 3px 0;
    font-size: 16px;
    border: none;
    outline: 0;
    cursor: pointer;
    margin-top: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: all .5s ease
}

.react-sidebar form button svg {
    color: #333;
    position: relative;
    top: 6px
}

.react-sidebar form button svg:hover {
    color: #3270fc
}

.react-sidebar form button:hover {
    opacity: .9
}

.events-details .videos {
    padding-bottom: 30px
}

.events-details h3 {
    font-size: 26px;
    font-weight: 600;
    color: #0F1216
}

.events-details p {
    font-size: 18px;
    line-height: 28px;
    color: #5B5F64
}

.events-details ul.mata-tags {
    border-top: 1px solid #E8ECF0;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-top: 40px
}

.events-details ul.mata-tags li {
    display: inline-block;
    margin-right: 5px
}

.events-details ul.mata-tags li a {
    padding: 3px 20px;
    background: #F2F4F9;
    font-size: 13px;
    color: #4D5765;
    font-weight: 600;
    display: block;
    border-radius: 4px
}

.events-details ul.mata-tags li a:hover {
    background: #3270FC;
    color: #fff
}

.events-details ul.mata-tags li:last-child {
    margin: 0
}

.events-details ul.mata-tags li.tags {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    margin-left: 20px
}

.events-details .others-instructors {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    padding-top: 60px
}

.events-details .others-instructors li {
    text-align: center;
    margin-right: 32px
}

.events-details .others-instructors li span {
    display: block;
    font-size: 20px;
    font-weight: 700;
    color: #0F1216;
    line-height: 25px
}

.events-details .others-instructors li span em {
    display: block;
    color: #5B5F64;
    font-weight: 400;
    font-style: normal
}

.events-details .others-instructors li:first-child {
    flex: 0 0 100%;
    text-align: right;
    margin-bottom: 16px
}

.events-details .others-instructors li:first-child h3 {
    margin: 0
}

.events-details .others-instructors li:last-child {
    margin-right: 0
}

body.event-page .react-upcoming__event .event__card .event__card--link:hover {
    background: #3270fc;
    border-color: #3270fc
}

body.event-page .react-upcoming__event .event__card .event__card--date em, body.event-page .react-upcoming__event .event__card .event_time {
    color: #3270fc
}

.student_satisfaction-section {
    background: url(../Images/testimonial/bg.jpg) center top no-repeat;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 100px
}

.student_satisfaction-section .event__card .event__card--content {
    background: #fff;
    padding: 40px 44px 42px;
    border-radius: 10px;
    position: relative;
    text-align: end
}

.student_satisfaction-section .event__card .event__card--content .testimonial__ratings {
    font-size: 14px;
    color: #797E84
}

.student_satisfaction-section .event__card .event__card--content .testimonial__ratings em {
    color: #FFA439
}

.student_satisfaction-section .event__card .event__card--content .parag {
    color: #5B5F64;
    line-height: 30px;
    margin: 6px 0 0;
    font-size: 18px
}

.student_satisfaction-section .poly {
    position: absolute;
    bottom: -11px;
    width: 24px !important;
    z-index: 11111;
    right: 40px;
    transform: rotate(-53deg);
    height: 24px;
    background: #fff
}

.student_satisfaction-section .author-sec {
    display: flex;
    align-items: center;
    padding: 30px 28px;
    direction: rtl
}

.student_satisfaction-section .author-sec .icon {
    margin-left: 15px
}

.student_satisfaction-section .author-sec .text {
    flex: 1
}

.student_satisfaction-section .author-sec .text h4 {
    margin: 0 0 5px;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: #0F1216
}

.student_satisfaction-section .author-sec .text p {
    margin: 0;
    font-size: 14px;
    line-height: 17px;
    color: #5B5F64
}

.student_satisfaction-section .author-sec a {
    border-radius: 50%;
    width: 44px;
    height: 44px;
    border: 2px solid #EDEDED;
    text-align: center;
    line-height: 36px
}

.student_satisfaction-section .author-sec a svg {
    width: 18px;
    color: #00306E
}

.student_satisfaction-section .author-sec a:hover {
    background: #3270fc;
    color: #fff
}

.student_satisfaction-section .author-sec a:hover svg {
    color: #fff
}

.student_satisfaction-section .owl-carousel .owl-controls {
    position: unset;
    transform: unset;
    width: auto
}

.student_satisfaction-section .owl-carousel .owl-nav {
    position: absolute;
    display: block;
    top: 29%;
    right: unset;
    width: 100%;
    opacity: 1;
    transition: all .5s ease 0s
}

.student_satisfaction-section .owl-carousel .owl-nav [class*=owl-] {
    position: absolute;
    left: 20px;
    display: inline-block;
    font-size: 0;
    width: 50px;
    height: 50px;
    line-height: 48px;
    color: #00306e;
    background: #fff;
    border-radius: 100%;
    transition: all .5s ease 0s;
    box-shadow: -6px 20px 30px rgba(15, 18, 22, .06)
}

.student_satisfaction-section .owl-carousel .owl-nav [class*=owl-]:before {
    content: "\35";
    font-family: ElegantIcons;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 26px;
    font-weight: 700
}

.student_satisfaction-section .owl-carousel .owl-nav [class*=owl-].owl-next {
    right: auto;
    left: -35px;
    rotate: 180deg
}

.student_satisfaction-section .owl-carousel .owl-nav [class*=owl-].owl-prev {
    right: -35px;
    left: auto;
    rotate: 180deg
}

.student_satisfaction-section .owl-carousel .owl-nav [class*=owl-].owl-prev:before {
    content: '\34'
}

.student_satisfaction-section .owl-carousel .owl-nav [class*=owl-]:hover {
    color: #fff;
    background: #3270FC;
    border-color: #3270FC
}

.student_satisfaction-section .count__area2 {
    padding-top: 90px
}

.student_satisfaction-section .count__area2 .container {
    border-top: 1px solid rgba(15, 18, 22, .08);
    border-bottom: 1px solid rgba(15, 18, 22, .08);
    max-width: 1280px
}

.student_satisfaction-section .count__area2 .container ul li {
    border-left: 1px solid rgba(15, 18, 22, .08);
    padding-top: 25px;
    padding-bottom: 25px;
    padding-right: 70px
}

.student_satisfaction-section .count__area2 .container ul li:last-child {
    border-left: none
}

.student_satisfaction-section .count__area2 .container ul li:first-child {
    padding-left: 0
}

.student_satisfaction-section .count__area2 .count__content {
    display: flex;
    align-items: center;
    position: relative
}

.student_satisfaction-section .count__area2 .count__content .icon {
    margin-left: 15px
}

.student_satisfaction-section .count__area2 .count__content .icon img {
    position: relative;
    top: -2px
}

.student_satisfaction-section .count__area2 .count__content .text {
    flex: 1
}

.student_satisfaction-section .count__area2 .count__content .text span {
    margin: 0 0 5px;
    font-weight: 600;
    font-size: 50px;
    line-height: 60px;
    color: #0F1216
}

.student_satisfaction-section .count__area2 .count__content .text em {
    margin: 0 0 5px;
    font-weight: 600;
    font-size: 50px;
    line-height: 60px;
    color: #0F1216;
    font-style: normal
}

.student_satisfaction-section .count__area2 .count__content .text p {
    margin: 0;
    font-size: 15px;
    line-height: 18px;
    color: #5B5F64;
    text-transform: uppercase
}

.student_satisfaction-section .count__area2 .count__content:last-child {
    border-right: 0
}

.high_quality-section .react-tab-gird {
    position: relative
}

.high_quality-section .react-tab-gird .shape__1 {
    position: absolute;
    top: 40%;
    right: 4.5%;
    -webkit-animation: hero-leaf-2 3s linear infinite alternate;
    -moz-animation: hero-leaf-2 3s linear infinite alternate;
    -o-animation: hero-leaf-2 3s linear infinite alternate;
    animation: hero-leaf-2 3s linear infinite alternate
}

.high_quality-section .react-tab-gird .shape__2 {
    position: absolute;
    top: 54%;
    right: 7.5%;
    z-index: -1
}

.high_quality-section .react-tab-gird .shape__3 {
    position: absolute;
    top: -15%;
    left: 2%;
    z-index: -1;
    -webkit-animation: hero-leaf-2 3s linear infinite alternate;
    -moz-animation: hero-leaf-2 3s linear infinite alternate;
    -o-animation: hero-leaf-2 3s linear infinite alternate;
    animation: hero-leaf-2 3s linear infinite alternate
}

.high_quality-section .react-tab-gird .nav-tabs {
    display: flex;
    border: none;
    justify-content: center
}

.high_quality-section .react-tab-gird .nav-tabs li a {
    display: flex;
    background: #fff;
    align-items: center;
    color: #0F1216;
    padding: 16px 32px 15px 25px;
    margin: 0 12px;
    font-size: 18px;
    line-height: 1;
    font-weight: 600;
    cursor: pointer;
    position: relative;
    vertical-align: middle;
    transition: 1s all cubic-bezier(.075, .82, .165, 1);
    box-shadow: 0 30px 50px rgba(0, 14, 32, .1);
    border-radius: 10px;
    border: none
}

.high_quality-section .react-tab-gird .nav-tabs li a em {
    font-style: normal
}

.high_quality-section .react-tab-gird .nav-tabs li a em.icon {
    margin-left: 14px
}

.high_quality-section .react-tab-gird .nav-tabs li a.active, .high_quality-section .react-tab-gird .nav-tabs li a:hover {
    opacity: .9;
    color: #0F1216
}

.high_quality-section .react-tab-gird .tab-pane {
    border: 6px solid #FFF;
    box-shadow: 0 30px 50px rgba(15, 18, 22, .14);
    border-radius: 10px;
    max-width: 985px;
    margin: 30px auto 40px;
    line-height: 0
}

.react-footer {
    position: relative;
    background-color: #0F1216;
    color: #fff
}

.react-footer .footer-top-cta {
    background: url(../Images/cta.jpg) center top no-repeat;
    background-size: cover;
    border-radius: 10px;
    padding: 50px 55px;
    position: absolute;
    width: 100%;
    top: -17%;
    max-width: 1300px;
    z-index: 1
}

.react-footer .footer-top-cta .row {
    align-items: center
}

.react-footer .footer-top-cta h4 {
    font-size: 16px;
    color: #fff;
    margin: 0 0 8px;
    line-height: 20px;
    font-weight: 600
}

.react-footer .footer-top-cta h3 {
    font-size: 44px;
    color: #fff;
    margin: 0;
    line-height: 50px;
    font-weight: 700
}

.react-footer .footer-top-cta a {
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
    border: 2px solid rgba(255, 255, 255, .2);
    padding: 15px 35px 12px;
    color: #fff;
    display: inline-block;
    margin-top: 0;
    line-height: 20px
}

.react-footer .footer-top-cta a svg {
    width: 18px;
    rotate: 180deg
}

.react-footer .footer-top-cta a:hover {
    background: #fff;
    color: #0F1216
}

.react-footer .footer-top {
    padding: 100px 0 93px
}

.react-footer .footer-top .footer-menu li {
    margin-bottom: 2px
}

.react-footer .footer-top .footer-menu li a {
    color: #B7B7B9;
    position: relative;
    display: inline-block;
    transition: all .5s ease 0s;
    font-size: 16px;
    font-weight: 600;
    line-height: 30px
}

.react-footer .footer-top .footer-menu li a:after {
    content: "";
    display: block;
    position: relative;
    z-index: 1;
    top: auto;
    bottom: 0;
    left: 0;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    width: 0;
    height: 1px;
    -webkit-transition: all .3s ease;
    -ms-transition: all .3s ease;
    transition: all .3s ease;
    background-color: #3270FC
}

.react-footer .footer-top .footer-menu li a:hover {
    color: #3270FC;
    margin-left: 5px
}

.react-footer .footer-top .footer-menu li a:hover:after {
    width: 100%
}

.react-footer .footer-top .footer-menu li:last-child {
    margin-bottom: 0
}

.react-footer .footer-top .footer-desc p {
    margin-bottom: 0
}

.react-footer .footer-top .footer3__form {
    margin: 40px 0 30px
}

.react-footer .footer-top .footer3__form form {
    position: relative
}

.react-footer .footer-top .footer3__form p {
    font-size: 16px;
    line-height: 24px
}

.react-footer .footer-top .footer3__form input {
    padding: 15px 30px 16px 80px;
    border: none;
    outline: 0;
    background: #fff;
    border-radius: 4px;
    width: 100%
}

.react-footer .footer-top .footer3__form input::placeholder {
    font-size: 15px;
    font-weight: 400;
    color: #4b4b57
}

.react-footer .footer-top .footer3__form input:focus {
    -webkit-box-shadow: 0 20px 30px 0 rgba(8, 0, 42, .14);
    -moz-box-shadow: 0 20px 30px 0 rgba(8, 0, 42, .14);
    box-shadow: 0 20px 30px 0 rgba(8, 0, 42, .14);
    z-index: 1
}

.react-footer .footer-top .footer3__form-1 {
    position: absolute;
    width: 60px;
    bottom: 0;
    border-radius: 4px 0 0 4px;
    background-color: #3270FC;
    color: #fff;
    top: 0;
    left: 0;
    border: none
}

.react-footer .footer-top .footer3__form-1:hover {
    opacity: .9
}

/* .react-footer .footer-top .footer3__form-1 i {
    position: absolute;
    font-size: 22px;
    top: 19px;
    right: 17px;
    rotate: 180deg
} */

.react-footer .footer-top .footer-subtitle {
    color: #fff;
    margin-top: 25px;
    line-height: 24px
}

.react-footer .footer-top .footer-widget-1 {
    margin-top: -10px
}

.react-footer .footer-top .footer-widget-3 {
    margin-right: 50px
}

.react-footer .footer-top .footer-widget.footer-widget-2 {
    padding-right: 100px
}
html[lang="en"] .react-footer .footer-top .footer-widget.footer-widget-2 {
    padding-left: 100px
}

@media (max-width:991px) {
    .react-footer .footer-top .footer-widget.footer-widget-2 {
        padding-right: 0
    }
}

.react-footer .footer-top .footer-widget .footer-title {
    margin-bottom: 30px;
    position: relative;
    color: #fff;
    font-size: 22px;
    font-weight: 700;
    line-height: 24px
}

.react-footer .footer-top .footer-widget .footer-address {
    margin-bottom: 30px
}

.react-footer .footer-top .footer-widget .footer-address li {
    width: 100%;
    font-size: 15px;
    margin-bottom: 10px;
    display: inline-flex;
    color: #B7B7B9;
    font-weight: 600
}

.react-footer .footer-top .footer-widget .footer-address li a {
    color: #B7B7B9;
    margin-right: 10px;
    line-height: 24px;
    font-size: 18px
}

.react-footer .footer-top .footer-widget .footer-address li a:hover {
    color: #3270FC
}

.react-footer .footer-top .footer-widget .footer-address li svg {
    position: relative;
    top: 0;
    color: #fff;
    width: 21px
}

.react-footer .footer-top .footer-widget .footer-address li:last-child {
    margin-bottom: 0
}

.react-footer .footer-top .footer-widget .footer-address li.react-address svg {
    margin-right: 10px;
    width: 22px;
    position: relative;
    top: 1px
}

.react-footer.react-footer-two .footer-top .footer3__form-1 {
    background: #3270FC
}

.react-footer.react-footer-two .footer-top .footer3__form-1:hover {
    opacity: .9
}

.react-footer.react-footer-two .copyright .react-copy-left a, .react-footer.react-footer-two .footer-top .footer-menu li a:hover, .react-footer.react-footer-two .footer-top .footer-widget .footer-address li a:hover {
    color: #3270FC
}

.react-footer.react-footer-two .footer-top .footer-menu li a::after {
    background: #3270FC
}

.react-footer.react-footer-two .social-links li a:hover {
    background: #3270FC;
    border-color: #3270FC
}

.react-footer .copyright {
    text-align: center
}

.react-footer .copyright .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid rgba(255, 255, 255, .1);
    padding-top: 20px;
    padding-bottom: 20px
}

.react-footer .copyright .follow {
    font-size: 16px;
    line-height: 20px;
    color: #B7B7B9;
    font-weight: 700;
    margin-right: 20px
}

.react-footer .copyright .react-copy-left {
    font-size: 14px;
    margin-bottom: 0;
    color: #A6A6A8;
    font-weight: 600;
    line-height: 22px
}

.react-footer .copyright .react-copy-left a {
    color: #3270FC;
    transition: all .7s ease
}

.react-footer .copyright .react-copy-left a:hover {
    color: #3270fc
}

.react-footer .copyright .react-copy-right ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center
}

.react-footer .copyright .react-copy-right ul li {
    padding-left: 6px
}

.react-footer .copyright .react-copy-right ul li a {
    color: #fff;
    font-size: 13px;
    font-weight: 500
}

.react-footer .copyright .react-copy-right ul li a:hover {
    color: #fff
}

.react-footer.home-main .copyright .react-copy-left a, .react-footer.home-main .footer-top .footer-menu li a:hover, .react-footer.home-main .footer-top .footer-widget .footer-address li a:hover {
    color: #d2093c
}

.react-footer.home-main .footer-top .footer-menu li a:after {
    background: #d2093c
}

.react-footer.home-main .social-links li a:hover {
    background: #d2093c;
    border-color: #d2093c
}

.react-footer.home-four .footer-top .footer3__form-1 {
    background-color: #3270fc
}

.react-footer-two .footer-top .footer3__form-1 {
    background: #3270fc
}

.react-footer-two .footer-top .footer3__form-1:hover {
    opacity: .9
}

.react-footer-two .copyright .react-copy-left a, .react-footer-two .footer-top .footer-menu li a:hover, .react-footer-two .footer-top .footer-widget .footer-address li a:hover {
    color: #3270FC
}

.react-footer-two .footer-top .footer-menu li a::after {
    background: #3270FC
}

.react-footer-two .social-links li a:hover {
    background: #3270fc;
    border-color: #3270fc
}

.social-links li {
    display: inline-block;
    margin-right: 6px
}

.social-links li a {
    border: 2px solid rgba(255, 255, 255, .1);
    color: #fff;
    background: 0 0;
    display: block;
    width: 44px;
    height: 44px;
    line-height: 43px;
    text-align: center;
    transition: all .7s ease 0s;
    font-size: 13px;
    border-radius: 50%
}

.social-links li a:hover {
    color: #fff;
    background: #3270FC;
    border-color: #3270FC
}

.social-links li:last-child {
    margin-right: 0
}

#react__preloader {
    -webkit-box-pack: center;
    -webkit-box-align: center;
    display: -webkit- flex;
    display: -ms- flex;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100vh;
    background: #fff;
    position: fixed;
    z-index: 999999;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0
}

.react__loader_logo {
    position: absolute
}

.react__loader_logo img {
    width: 40px
}

#react__circle_loader {
    width: 100px;
    height: 100px;
    border-style: solid;
    border-width: 5px;
    border-color: #465fe9 #465fe9 #d5eaff #d5eaff;
    border-radius: 50%;
    -webkit-animation: spinLoader 2s linear infinite;
    animation: spinLoader 2s linear infinite
}

@-webkit-keyframes spinLoader {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes spinLoader {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

#backscrollUp {
    position: fixed;
    bottom: 40px;
    right: 15px;
    transition: all .5s ease;
    z-index: 99999;
    cursor: pointer;
    display: none;
    background: #3270FC;
    border-radius: 3px
}

#backscrollUp.home {
    background: #d2093c
}

#backscrollUp span {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 3px;
    font-size: 22px;
    color: #fff;
    text-align: center;
    display: none;
    transition: all .5s ease
}

#backscrollUp span:before {
    transition: all .5s ease;
    display: block;
    margin: 0;
    font-size: 22px
}

#backscrollUp span:hover {
    opacity: .9
}

#backscrollUp.react__up___scroll span {
    display: block
}

/* .react-login-page, .react-login-page.react-signup-page {
    background: url(../Images/login/1.jpg) center top no-repeat;
    background-size: cover
} */


.react-login-page .login-left-content h1 {
    font-size: 50px;
    font-weight: 700;
    color: #0F1216;
    margin: 0 0 18px
}

.react-login-page .login-left-content p {
    margin: 0 0 50px;
    font-size: 17px;
    color: #55545b
}

.react-login-page .login-left-content p a {
    font-weight: 600;
    color: #3270fc
}

.react-login-page .login-left-content p a:hover {
    color: #55545b
}

.react-login-page .login-right-form {
    background: #fff;
    padding: 50px;
    max-width: 470px;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 0 40px 60px rgba(15, 18, 22, .08)
}

.react-login-page .login-right-form form .login-top {
    text-align: center;
    padding-bottom: 35px
}

.react-login-page .login-right-form form .login-top h3 {
    margin: 0 0 8px;
    color: #0F1216;
    font-size: 26px;
    font-weight: 700
}

.react-login-page .login-right-form form .login-top p {
    margin: 0;
    font-size: 16px;
    color: #4D5765
}

.react-login-page .login-right-form form .social-links {
    text-align: center
}

.react-login-page .login-right-form form .social-links li {
    margin-left: 6px;
    margin-right: 0
}

.react-login-page .login-right-form form .social-links li a {
    border-radius: 50%;
    background: #3270FC;
    color: #fff;
    width: 44px;
    height: 44px;
    line-height: 44px;
    border: none
}

.react-login-page .login-right-form form .social-links li a:hover {
    opacity: .9
}

.react-login-page .login-right-form form .social-links li:last-child a {
    border: 2px solid #E8ECF0;
    font-size: 16px;
    background: 0 0;
    color: #9EA9B6;
    position: relative;
    top: 3px
}

.react-login-page .login-right-form form .social-links li:last-child a:hover {
    background: #3270FC;
    border-color: #3270FC;
    color: #fff
}

.react-login-page .login-right-form form span.back-or {
    display: block;
    font-size: 14px;
    color: #7a797f;
    text-align: center;
    font-weight: 500;
    margin: 20px 0 16px
}

.react-login-page .login-right-form form p label {
    display: block;
    color: #0F1216;
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 8px
}

.react-login-page .login-right-form form input {
    border: none;
    background: #F2F4F9;
    width: 100%;
    padding: 12px 20px;
    border-radius: 6px
}

.react-login-page .login-right-form form [type=submit] {
    background: #3270FC;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    border-radius: 5px;
    display: inline-block;
    padding: 12px 38px;
    transition: all .5s ease 0s;
    border: none;
    width: 100%
}

.react-login-page .login-right-form form [type=submit]:hover {
    opacity: .9
}

.react-login-page .login-right-form form [type=submit] svg {
    width: 18px;
    transform: rotate(180deg)
}

.react-login-page .login-right-form form .signup {
    font-weight: 500;
    font-size: 14px;
    color: #7a797f;
    font-style: normal;
    text-align: center;
    display: block;
    padding-top: 20px
}

.react-login-page .login-right-form form .signup a {
    color: #3270fc
}

.react-login-page .login-right-form form .signup a:hover {
    color: #7a797f
}

.react-login-page .login-right-form form .back-check-box {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #7a797f;
    margin-top: -16px;
    margin-bottom: 35px
}

.react-login-page .login-right-form form .back-check-box input {
    width: auto;
    margin-left: 5px
}

.react-login-page .login-right-form form .back-check-box p {
    margin: 0;
    flex: 1;
    text-align: right
}

.react-login-page .login-right-form form .back-check-box em {
    font-weight: 600;
    font-style: normal;
    color: #00306e
}

.react-login-page .container {
    max-width: 1050px
}

.react-login-page .row.align-center {
    align-items: center
}